/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageHeader, PageContainer } from 'components'
import { MeterReadReplay } from "types"
import { formatLocaleDateTime, humanize } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [meterReadReplays, actions] = useResource<MeterReadReplay>("meterReadReplays")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const meterReadReplay: Partial<MeterReadReplay> = meterReadReplays.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Meter Read Replay`} />
      <Table>
        <TableBody>
        <TableRow>
            <TableCell variant='head' css={{ width: 120 }}>Created At</TableCell>
            <TableCell>{formatLocaleDateTime(meterReadReplay.createdAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>From</TableCell>
            <TableCell>{formatLocaleDateTime(meterReadReplay.from)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>To</TableCell>
            <TableCell>{formatLocaleDateTime(meterReadReplay.to)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>{humanize(meterReadReplay.status)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Output</TableCell>
            <TableCell><pre>{meterReadReplay?.output}</pre></TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Meter Serials</TableCell>
            <TableCell><ul>{meterReadReplay?.meterSerials?.map((serial,i) => <li key={i}>{serial}</li>)}</ul></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show