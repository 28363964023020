/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { usePlot, useDependency } from 'hooks'
import { ControlledForm, LabeledSelect, PageContainer, PageHeader } from 'components'
import { TimeSeriesPlot }  from 'components/charts'
import { StylesObject } from "types"
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import moment from 'moment'

const SERIES = {
  frequency: 'Frequency',
  power: 'Power',
}

const SERIES_DETAILS = {
  frequency: {label: 'Frequency (Hz)', unit: 'Hz'},
  power: {label: 'Power (MW)', unit: 'MW'},
}

const Plot = ({meterTestResultUploadId}) => {
  
  const [plotState, plotActions] = usePlot("meterTestResults")
  const plot = plotState.plot
  const [filter, setFilter] = useState({series: 'frequency'})
  const series = filter.series
  const [dygraphOptions, setDygraphOptions] = useState({})

  const [loading] = useDependency( async () => {
    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      await plotActions.plot({ timeSeries })
    }
  }, [JSON.stringify(filter)])

  const currentTimeSeries = () => {
    return [{
      series,
      label: series,
      filter: {...filter, meterTestResultUploadId},
    }]
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}> 
        <LabeledSelect
          css={styles.filter}
          name="series"
          fullWidth
          options={SERIES}
        />
        <div style={{flex: 1}}/>
        <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
      </div>
    </ControlledForm>

  return (
    <PageContainer css={styles.container}>
      <PageHeader title="Plot"/>
      {renderFilter()}

      <TimeSeriesPlot
        plot={plot}
        unit={SERIES_DETAILS[series]?.unit}
        ylabel={SERIES_DETAILS[series]?.label}
        maxGranularity={Dygraph.Granularity.MINUTELY}
        dygraphOptions={dygraphOptions}
        timeFormatter={v => moment(v).format('DD/MM/YYYY HH:mm:ss.SSS')}
        loading={loading}
      />
    </PageContainer>
  )
}

const styles = {
  container: {
    marginTop: 20,
    marginBottom: 20,
  },
  filterRow: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
} as StylesObject

export default Plot