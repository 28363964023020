import { uuid } from 'utils'

class MicrosoftService {
  authPopup: any
  callbacks: any

  constructor() {
    this.authPopup = null
    this.callbacks = new Set()
  }

  handleAuthResponse = (event) => {
    if(event.source !== this.authPopup) {
      return
    }

    let { state } = event.data
    let nonce = global.localStorage.microsoftOAuthNonce

    if(nonce !== state) {
      this.replyToListeners({ error: 'state_mismmatch', errorDescription: 'Potential CSRF attack' })
      return
    }

    let authHash = this.authHashFromReply(event.data)
    this.replyToListeners(authHash)
    this.authPopup.close()
  }

  authHashFromReply = (data) => {
    let { error, error_description, code } = data
    if(error) {
      return { error, errorDescription: error_description }
  } else {
      return { authCode: code, redirectUri: this.redirectUri('/microsoft/callback') }
    }
  }

  replyToListeners = (payload) => {
    this.callbacks.forEach((callback) => { callback(payload) })
    this.callbacks.clear()
    window.removeEventListener('message', this.handleAuthResponse)
  }

  authorize = () => {
    const nonce = uuid()
    global.localStorage.microsoftOAuthNonce = nonce
    window.location.href = this.authUrl(nonce)
  }

  logout = () => {
    let logoutUrl = window.env.AZURE_B2C_LOGOUT_URL
    window.location.href = `${logoutUrl}?&redirect_uri=${encodeURIComponent(this.redirectUri(''))}`
  }

  authUrl = (state) => {
    let authorizationUrl = window.env.AZURE_B2C_AUTHORIZATION_URL
    let clientId = window.env.AZURE_B2C_CLIENT_ID
    let scope = clientId
    return `${authorizationUrl}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(this.redirectUri('/microsoft/callback'))}&state=${state}&scope=${scope}`
  }

  redirectUri = (path) => {
    let url = new URL(window.location.href)
    return `${url.origin}${path}`
  }
}

const MicrosoftServiceInstance = new MicrosoftService()
export { MicrosoftServiceInstance as MicrosoftService }