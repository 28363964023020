import { FormControlLabel, Switch } from "@mui/material"
import { SwitchProps } from "@mui/material/Switch"

type LabeledSwitchProps = {
  label?: string
} & SwitchProps

const LabeledSwitch = ({ label, ...switchProps }: LabeledSwitchProps) => {
  return <FormControlLabel control={<Switch {...switchProps} />} label={label} />
}

LabeledSwitch.valueProp = 'checked'

export default LabeledSwitch