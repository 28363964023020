import React from 'react'
import {useHistory} from 'react-router-dom'
import StatusPage from 'containers/status/StatusPage'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useSnackbar} from "contexts/SnackbarContext";
import {useTokens} from "contexts/TokensContext";

const Inactive = () => {
  const [tokens, actions] = useTokens()
  const snackbar = useSnackbar()

  const history = useHistory()

  const continueSession = () => {
    actions.refresh(false).then(() => {
      const location = (
        tokens.savedLocation &&
        !tokens.savedLocation.match(/^\/?(?:tokens)|(?:inactive)\/?/)
      ) ?
        tokens.savedLocation :
        '/'
      history.push(location)
    }).catch(() => {
      actions.destroy(true)
      snackbar.show('Session expired')
    })
  }

  return (
    <StatusPage>
      <Typography variant='h6'>User session inactive</Typography>
      <Typography variant='body1'>Your current session has expired.</Typography>
      <Button fullWidth variant='contained' color='secondary' onClick={continueSession}>Continue</Button>
      <br/>
      <br/>
      <Button fullWidth variant='contained' color='primary' onClick={() => actions.destroy(true)}>Sign Out</Button>
    </StatusPage>
  )
}

export default Inactive