import useResource, {Actions, replace, ResourceState, SaveOptions} from "../useResource";
import {HotWaterEntry, HotWaterUpload} from "types";
import useRequest from "../useRequest";

export type HotWaterUploadResource = [
  ResourceState<HotWaterUpload>,
    Actions<HotWaterUpload> & {
    confirm: (id: HotWaterUpload['id'], options?: SaveOptions) => Promise<HotWaterEntry>;
  }
]

const useHotWaterUpload = (): HotWaterUploadResource => {
  const [state, actions] = useResource<HotWaterUpload>("hotWaterUploads")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    confirm: async (id, options = {}) => {
      const { data: selected } = await performRequest(actions.setState, "hotWaterUploads", 'confirm', { id, options })
      actions.setState(prevState => ({ ...prevState, selected, list: replace(prevState.list, selected) }))
      return selected
    },
  }
  return [state, newActions]
}

export default useHotWaterUpload