import { InputAdornment, TextField, TextFieldProps } from "@mui/material"
import { decimalAsPercentage, percentageAsDecimal } from "utils"

const PercentageField = ({ value, onChange, ...props }: TextFieldProps) => {
  const handleChange = (event: React.ChangeEvent<any>) => {
    if (!onChange) { return }
    const valueAsDecimal = percentageAsDecimal(event.target.value)
    if (isNaN(valueAsDecimal)) {
      return onChange(event) // Return non-numeric string as is
    } else {
      return onChange({...event, target: {...event.target, value: valueAsDecimal}}) // Return as a decimal string
    }
  }

  let displayValue = decimalAsPercentage(value as string, { rawIfNaN: true })
  return (
    <TextField fullWidth name='currentValue' type="number" {...props}
               value={displayValue}
               onChange={handleChange}
               InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}} />
  )
}
export default PercentageField