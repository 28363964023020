import { useMemo } from 'react'
import { useTokens } from "contexts/TokensContext";

const useAuthorization = () => {
  const [tokens,] = useTokens()

  const user = tokens.currentUser

  return useMemo(() => ({
    user,
    userId: user?.id,
    admin: user?.role === 'admin',
    internal: user?.role === 'internal',
    external: user?.role === 'external',
    csc: user?.role === 'csc',
    internalOrAdmin: user?.role === 'admin' || user?.role === 'internal',
  }), [user])
}

export default useAuthorization