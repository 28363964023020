/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useDependency, useResource, useDefaultSave, useQueryString } from 'hooks'
import { ControlledForm, DatePicker, ErrorBanner, LabeledSelect, LabeledSwitch, PageContainer, PageHeader, PercentageField, PromiseButton } from 'components'
import { formErrors, apiErrorStringsFor, currentISODateString } from 'utils'
import { MeterDetail } from "types"
import { useRouteMatch } from 'react-router-dom'

const Form = () => {
  const match = useRouteMatch<{ id?: string }>()
  const id = match.params.id
  const resourceAction = match.path.match(/\/new(?:\/:[^/]+)?$/) ? 'create' : 'update'
  const [{ installedMeterId }] = useQueryString()

  const [meterDetails, actions] = useResource<MeterDetail>("meterDetails")
  const [formData, setFormData] = useState<Partial<MeterDetail>>({
    installedMeterId,
    loadType: 'reserve',
    isSensitiveLoad: false,
    requiresManualRearm: false,
    startDate: currentISODateString(),
    endDate: undefined,
    endPreviousRecord: true,
  })
  const handleSave = useDefaultSave(formData, actions)

  useDependency(async () => {
    if (id) {
      const result = await actions.show(id!)
      if (resourceAction === 'create') {
        setFormData({ ...result, startDate: currentISODateString(), endDate: undefined, endPreviousRecord: true })
      } else {
        setFormData(result)
      }
    }
  }, [id])

  return (
    <PageContainer>
      <PageHeader title={`${resourceAction === 'update' ? `Edit Meter Detail` : 'Create a new Meter Detail'}`} />
      <ControlledForm data={formData} errors={formErrors(meterDetails, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(meterDetails, 'create', 'update')}</ErrorBanner>
        <div css={{ display: 'flex' }}>
          <DatePicker name='startDate' css={{ flex: 1 }} />
          <DatePicker name='endDate' css={{ flex: 1 }} />
        </div>
        <LabeledSelect fullWidth name='loadType' options={loadTypes} />
        <LabeledSelect fullWidth name='forecastMethod' options={forecastMethods} />
        <TextField fullWidth name='persistenceForecastPeriodCount' type="number" />
        <TextField fullWidth name='readRejectionThresholdkW' label='Read Rejection Threshold kW' type="number" />
        <TextField fullWidth name='forecastAccuracyWarningThreshold' type="number" />
        <TextField fullWidth name='forecastAccuracyExclusionThreshold' type="number" />
        <PercentageField name='firFactor' label='FIR Factor' fullWidth />
        <PercentageField name='sirFactor' label='SIR Factor' fullWidth />
        <PercentageField name='revenueSplitFactor' fullWidth />
        <TextField fullWidth name='rearmAfterMinutes' label='Rearm After (minutes)' type="number" inputProps={{step: 1}} />
        <TextField fullWidth name='notifyArmedLoadBelowkW' label='Notify Armed Load Below kW' type="number" inputProps={{step: 1}} />
        <LabeledSwitch name='isSensitiveLoad' />
        <LabeledSwitch name='requiresManualRearm' />
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const forecastMethods = { none: 'None', prophet: 'Prophet', viotas: 'Viotas' }
const loadTypes = { reserve: 'Reserve' }

export default Form