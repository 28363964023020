/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave, useAuthorization } from 'hooks'
import { AutocompleteSearch, ControlledForm, DatePicker, ErrorBanner, LabeledSelect, LabeledSwitch, PageContainer, PromiseButton, PageHeader } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { AdhocAvailability, Customer, Site } from "types"

const Form = () => {
  const { editMode, id, resourceAction } = useCommonRouteParams()
  const [adhocAvailabilities, actions] = useResource<AdhocAvailability>("adhocAvailabilities")
  const [formData, setFormData] = useState<Partial<AdhocAvailability>>({ status: 'disarmed' })
  const [filterData, setFilterData] = useState<{ customer?: Customer, site?: Site }>()
  const handleSave = useDefaultSave(formData, actions, { linked: ['installedMeter'] })
  const authorization = useAuthorization()

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!, { include: 'installedMeter,installedMeter.site,installedMeter.site.customer' })
      setFormData(result)
      setFilterData({ customer: result?.installedMeter?.site?.customer, site: result?.installedMeter?.site })
    }
  }, [editMode, id])

  const inlineStyle = { width: '50%', '@media (max-width: 800px)': { width: '100%' } }

  const customerId = filterData?.customer?.id
  const siteId = filterData?.site?.id
  const siteFilter = useMemo(() => ({ customerId }), [customerId])
  const meterFilter = useMemo(() => ({ customerId, siteId }), [customerId, siteId])

  const statuses: { [key: string]: string } = formData?.installedMeter?.canBeSwitchedOff ? { armed: 'Yes', disarmed: 'No', off: 'Off' } : { armed: 'Yes', disarmed: 'No' }
  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Adhoc Availability` : 'Create a new Adhoc Availability'} />
      <ControlledForm data={filterData} onChange={setFilterData}>
        {!authorization.external && <AutocompleteSearch fullWidth name='customer' searchableField="name" />}
        <AutocompleteSearch key={`sites-for-customer-${customerId}`} fullWidth name='site' searchableField="name" filter={siteFilter} />
      </ControlledForm>
      <ControlledForm data={formData} errors={formErrors(adhocAvailabilities, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(adhocAvailabilities, 'create', 'update')}</ErrorBanner>
        <AutocompleteSearch
          key={`meters-for-customer-${customerId ?? 'all'}-site-${siteId ?? 'all'}`}
          label="Meter"
          fullWidth
          name='installedMeter'
          searchableField="meterSerial"
          filter={meterFilter}
          fields="id,meterSerial,canBeSwitchedOff"
        />
        <LabeledSelect fullWidth options={statuses} name='status' label='Available' />
        <div>
          <DatePicker css={inlineStyle} name='fromDate' />
          <LabeledSelect css={inlineStyle} name='fromPeriod' fullWidth options={periods} />
        </div>
        <div>
          <DatePicker css={inlineStyle} name='toDate' />
          <LabeledSelect css={inlineStyle} name='toPeriod' fullWidth options={periods} />
        </div>
        <PromiseButton css={{ marginTop: 32 }} color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const periods = Array.from({ length: 50 }, (v, i) => (i + 1).toString())

export default Form