/** @jsxImportSource @emotion/react */

import { Accordion, AccordionDetails, AccordionSummary, ListItemIcon } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SubMenu = ({ title, icon, children }) => {

  return (
    <Accordion disableGutters={true} css={{boxShadow: 'initial', '&:before': {display: 'none'} }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <ListItemIcon>{icon}</ListItemIcon>{title}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default SubMenu;