/** @jsxImportSource @emotion/react */
import React  from 'react'
import { PageHeader, Pagination, ErrorBanner, PageContainer } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import {apiErrorStringsFor, formatLocaleDateTime} from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { UnderFrequencyEvent } from "types"

const List = () => {
  const [underFrequencyEvents, actions] = useResource<UnderFrequencyEvent>("underFrequencyEvents")

  const history = useHistory()
  const [page, setPage] = usePagination("underFrequencyEvents")

  useDependency(() => (
    actions.index({
      page: page,
      fields: {underFrequencyEvents: 'startedAt'},
      sort: '-startedAt'
    })
  ), [page])

  const showUnderFrequencyEvent = ({id}) => () => {
    history.push(`/under_frequency_events/${id}`)
  }

  const renderUnderFrequencyEventListItem = (underFrequencyEvent) => {
    const { id, startedAt } = underFrequencyEvent
    return (
      <ListItem button onClick={showUnderFrequencyEvent(underFrequencyEvent)} key={id}>
        <ListItemIcon>
          <ElectricBoltIcon />
        </ListItemIcon>
        <ListItemText primary={`${formatLocaleDateTime(startedAt, {includeSeconds: true})}`}/>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Under Frequency Events`} />
      <ErrorBanner>
        {apiErrorStringsFor(underFrequencyEvents, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={underFrequencyEvents.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {underFrequencyEvents.list.map(renderUnderFrequencyEventListItem)}
      </MuiList>
      <Pagination totalPages={underFrequencyEvents.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List