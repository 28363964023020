/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment';
import { StylesObject } from 'types'

export type FilteredSearchInputProps = {
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenFilters: (event: React.MouseEvent) => void;
  onClearFilter?: () => void;
  hasFiltersApplied?: boolean;
  value?: string;
}
export class FilteredSearchInput extends Component<FilteredSearchInputProps> {

  static defaultProps = {
    className: '',
    hasFiltersApplied: false
  }

  render = () => {
    const { className, onChange, onOpenFilters, hasFiltersApplied, onClearFilter, value, ...textFieldProps } = this.props
    return (
      <TextField
        css={styles.searchInput}
        value={value}
        fullWidth
        placeholder="Search"
        onChange={onChange}
        InputProps={{
          disableUnderline: true,
          startAdornment:
            <InputAdornment position="start">
              {(hasFiltersApplied && onClearFilter) ? <Tooltip title="Clear Filters"><IconButton
                css={{ color: 'red', margin: -12, padding: 12 }}
                onClick={onClearFilter}
                size="large">
                <ClearIcon />
              </IconButton>
              </Tooltip> : <SearchIcon />}
            </InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title='Advanced Filters'>
                <IconButton
                  css={{ color: hasFiltersApplied ? 'red' : undefined, margin: -12, padding: 12 }}
                  onClick={onOpenFilters}
                  size="large">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        {...textFieldProps}
      />
    );
  }
}

const styles = {
  searchInput: {
    border: '1px solid silver',
    padding: '5px 10px',
    width: 280,
    marginRight: 10,
    marginBottom: 0,
    flexDirection: 'row',
    '@media print': {
      display: 'none',
    },
  }
} as StylesObject

export default FilteredSearchInput
