import React from 'react'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import FaceIcon from '@mui/icons-material/Face'
import PhoneIcon from '@mui/icons-material/Phone';
import NotificationsIcon from '@mui/icons-material/Notifications'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {PageContainer} from 'components'
import {useDependency, useAuthorization, useResource} from 'hooks'
import {useHistory} from 'react-router-dom'

const NOTIFICATION_FLAGS = {
  receiveInAppNotifications: 'In App', 
  receiveEmailNotifications: 'Email',
  receiveTextMessageNotifications: 'Text Message'
}

const Profile = () => {
  const [users, actions] = useResource("users")
  const {userId} = useAuthorization();

  const history = useHistory()

  useDependency(() => {
    return actions.show(userId!)
  }, [userId])

  const currentUser = users.selected ?? {}

  const email = currentUser.unconfirmedEmail ? `${currentUser.unconfirmedEmail} (unconfirmed)` : currentUser.email

  const enabledNotifications = Object.keys(NOTIFICATION_FLAGS)
                                     .filter(flag => currentUser[flag])
                                     .map(flag => NOTIFICATION_FLAGS[flag])

  return (
    <PageContainer>
      <Typography variant='h6'>Profile</Typography>
      <List>
        <ListItem>
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => history.push(`/users/${currentUser.id}/edit`)}
              size="large">
              <EditIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon><PersonIcon/></ListItemIcon>
          <ListItemText primary={currentUser.name} secondary='Name'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><EmailIcon/></ListItemIcon>
          <ListItemText primary={email} secondary='Email'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><PhoneIcon/></ListItemIcon>
          <ListItemText primary={currentUser.mobilePhone} secondary='Mobile Phone'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><FaceIcon/></ListItemIcon>
          <ListItemText primary={currentUser.role} secondary='Role'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><NotificationsIcon/></ListItemIcon>
          <ListItemText primary={enabledNotifications.join(', ')} secondary='Notifications'/>
        </ListItem>
      </List>
    </PageContainer>
  );
}

export default Profile