/** @jsxImportSource @emotion/react */
import React from 'react'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import {PageContainer, PageHeader} from 'components'
import {useDependency, useCommonRouteParams, useResource, useAuthorization} from 'hooks'

const NOTIFICATION_FLAGS = {
  receiveInAppNotifications: 'In App', 
  receiveEmailNotifications: 'Email',
  receiveTextMessageNotifications: 'Text Message'
}

const Show = () => {
  const {id} = useCommonRouteParams()
  const [users, actions] = useResource("users")
  const authorization = useAuthorization()

  useDependency(() => {
    return actions.show(id!, {include: 'customer'})
  }, [id])

  const user = users.selected ?? {}

  const customerField = () => {
    if (user.role == "external") {
      return (
        <TableRow>
          <TableCell variant='head'>Customer</TableCell>
          <TableCell>{String(user.customer.name || '')}</TableCell>
        </TableRow>
      )
    } else {
      return (<></>)
    }
  }

  const enabledNotifications = Object.keys(NOTIFICATION_FLAGS)
                                     .filter(flag => user[flag])
                                     .map(flag => NOTIFICATION_FLAGS[flag])

  return (
    <PageContainer>
      <PageHeader title={`User - ${user.name}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
            {
              authorization.admin && 
              <Link to={`/users/${user.id}/edit`}>
                <IconButton size="large">
                  <EditIcon/>
                </IconButton>
              </Link>
            }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(user.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Email</TableCell>
            <TableCell>{String(user.email || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Role</TableCell>
            <TableCell>{String(user.role || '')}</TableCell>
          </TableRow>
          {customerField()}
          <TableRow>
            <TableCell variant='head'>Notifications</TableCell>
            <TableCell>{enabledNotifications.join(', ')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </PageContainer>
  );
}

export default Show