/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { Typography, TextField } from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import { AutocompleteSearch, ControlledForm, ErrorBanner, PageContainer, PromiseButton, LabeledSelect, PageHeader } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { BulkReserveSource } from "types"

const Form = () => {
  const {editMode, id, resourceAction} = useCommonRouteParams()
  const [bulkReserveSources, actions] = useResource<BulkReserveSource>("bulkReserveSources")
  const [formData, setFormData] = useState<Partial<BulkReserveSource>>({})
  const handleSave = useDefaultSave(formData, actions, {linked: ['customer']})

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!, {include: 'customer'})
      setFormData(result)
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Bulk Reserve Source - ${formData.name}` : 'Create a new Bulk Reserve Source'} />
      <ControlledForm data={formData} errors={formErrors(bulkReserveSources, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(bulkReserveSources, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='name'/>
        <LabeledSelect name="sourceType" fullWidth options={['hot_water', 'battery', 'revenue_split']} />
        <TextField fullWidth name='gxp' label="GXP" />
        <AutocompleteSearch fullWidth name='customer' />
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form