/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import {Table, TableBody, TableCell, TableRow, Typography, IconButton, Button} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {LinkButton, PageContainer, PageHeader} from 'components'
import { BulkOfferBatch } from "types"
import {formatLocaleDateTime, history} from "../../utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [bulkOfferBatches, actions] = useResource<BulkOfferBatch>("bulkOfferBatches")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'bulkReserveSource'})
    }
  }, [id])

  const bulkOfferBatch: Partial<BulkOfferBatch> = bulkOfferBatches.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Bulk Offer Batch - ${bulkOfferBatch.bulkReserveSource?.name} ${formatLocaleDateTime(bulkOfferBatch.createdAt || '')}`} actions={
        <Button variant="contained"
                onClick={() => history.push(`/bulk_offers?bulkOffers.filter.bulkOfferBatch.id=${id}&bulkOffers.filter.bulkOfferBatch.createdAt=${encodeURIComponent(bulkOfferBatch.createdAt || '')}`)}>
          Bulk Offers
        </Button>
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>ID</TableCell>
            <TableCell>{bulkOfferBatch.id || ''}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>Created at</TableCell>
              <TableCell>{formatLocaleDateTime(bulkOfferBatch.createdAt || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Bulk reserve source</TableCell>
              <TableCell>
                <LinkButton onClick={() => history.push(`/bulk_reserve_sources/${bulkOfferBatch.bulkReserveSource?.id}`)}>
                  {String(bulkOfferBatch.bulkReserveSource?.name || '')}
                </LinkButton>
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show