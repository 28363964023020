import { ResourceState } from "./useResource";
import * as API from "api"
import { useState } from "react";

export type Version = string;
export type VersionResourceState = ResourceState<never> & { version: Version | null }
export type FetchVersion = () => Promise<Version>;

const useVersion = (): [Version | null, FetchVersion] => {
  const [version, setVersion] = useState(null)

  const fetchVersion = async () => {
    const { data: { tag } } = await (API as any).API.tag()
    setVersion(tag)
    return tag
  }

  return [version, fetchVersion]
}

export default useVersion