/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, ErrorBanner, PageContainer } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import ForecastRunLogIcon from '@mui/icons-material/FormatListBulleted'
import { apiErrorStringsFor, formatLocaleDateTime } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { ForecastRunLog } from "types"

const List = ({ forecastRunId }) => {
  const [forecastRunLogs, actions] = useResource<ForecastRunLog>("forecastRunLogs")
  const history = useHistory()

  const [page, setPage] = usePagination("forecastRunLogs")

  useDependency(() => (
    actions.index({
      page: page,
      fields: { forecastRunLogs: 'createdAt,message' },
      filter: forecastRunId ? { forecastRunId } : {},
    })
  ), [page])

  const showForecastRunLog = ({ id }) => () => {
    history.push(`/forecast_run_logs/${id}`)
  }

  const renderForecastRunLogListItem = (forecastRunLog) => {
    const { id, message, createdAt } = forecastRunLog
    return (
      <ListItem button onClick={showForecastRunLog(forecastRunLog)} key={id}>
        <ListItemIcon>
          <ForecastRunLogIcon />
        </ListItemIcon>
        <ListItemText primary={message} secondary={formatLocaleDateTime(createdAt)} />
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Forecast Run Logs</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(forecastRunLogs, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={forecastRunLogs.totalPages} page={page} onPageSelected={setPage} />
      {forecastRunLogs.list.length > 0 ?
        <MuiList dense>
          {forecastRunLogs.list.map(renderForecastRunLogListItem)}
        </MuiList> :
        <Typography variant="subtitle2">No log records found</Typography>
      }
      <Pagination totalPages={forecastRunLogs.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List