/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, AutocompleteSearch, LabeledSelect, DatePicker } from 'components'
import {
  IconButton,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, formatLocaleDate, siteAndMeterFilters, statusToAvailability } from 'utils'
import { usePagination, useDependency, useResource, useFilter, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { AdhocAvailability } from "types"

const List = () => {
  const [adhocAvailabilities, actions] = useResource<AdhocAvailability>("adhocAvailabilities")

  const snackbar = useSnackbar()
  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("adhocAvailabilities")
  const [filter, setFilter] = useFilter(setPage, 'adhocAvailabilities')

  const [, , reloadAdhocAvailabilities] = useDependency(() => (
    actions.index({
      page: page,
      fields: { adhocAvailabilities: 'fromDate,fromPeriod,toDate,toPeriod,status', installedMeters: 'meterSerial' },
      include: 'installedMeter,installedMeter.site.customer',
      sort: '-toDate,-id',
      filter
    })
  ), [page, filter])

  const showAdhocAvailability = ({ id }) => () => {
    history.push(`/adhoc_availabilities/${id}`)
  }

  const editAdhocAvailability = ({ id }) => event => {
    history.push(`/adhoc_availabilities/${id}/edit`)
    event.stopPropagation()
  }

  const deleteAdhocAvailability = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadAdhocAvailabilities)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderAdhocAvailabilityListItem = (adhocAvailability) => {
    const { id, installedMeter, fromDate, fromPeriod, toDate, toPeriod, status } = adhocAvailability
    return (
      <TableRow key={id} css={{ '&:hover': { background: '#EEE', cursor: 'pointer' } }} onClick={showAdhocAvailability(adhocAvailability)}>
        <TableCell>{installedMeter?.site?.customer?.name}</TableCell>
        <TableCell>{installedMeter?.meterSerial}</TableCell>
        <TableCell>{formatLocaleDate(fromDate)}</TableCell>
        <TableCell>{fromPeriod}</TableCell>
        <TableCell>{formatLocaleDate(toDate)}</TableCell>
        <TableCell>{toPeriod}</TableCell>
        <TableCell>{statusToAvailability(status)}</TableCell>
        <TableCell onClick={e => e.stopPropagation()}>
          <IconButton onClick={editAdhocAvailability(adhocAvailability)} size="large"><EditIcon /></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteAdhocAvailability(adhocAvailability)} entityName="Adhoc Availability" />
        </TableCell>
      </TableRow>
    )
  }

  const autoClears = { customer: { fields: ['site', 'installedMeter'], value: null }, site: { fields: ['installedMeter'], value: null } }
  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Adhoc Availabilities</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(adhocAvailabilities, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination
        totalPages={adhocAvailabilities.totalPages}
        page={page}
        onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter} autoClears={autoClears}>
            {(state) => {
              const { sitesKey, sitesFilter, metersKey, metersFilter } = siteAndMeterFilters(state)
              return <>
                {(authorization.admin || authorization.internal || authorization.csc) &&
                  <AutocompleteSearch fullWidth name='customer' searchableField="name" />
                }
                <AutocompleteSearch fullWidth name='site' key={sitesKey} filter={sitesFilter} searchableField="displayName" />
                <AutocompleteSearch fullWidth name="installedMeter" key={metersKey} filter={metersFilter} searchableField="meterSerial" label="Meter" />
                <DatePicker name="date" fullWidth />
                <LabeledSelect name="status" label='Available' fullWidth options={{ armed: "Yes", disarmed: "No", off: "Off" }} />
              </>
            }}
          </SearchCombo >
        )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Meter</TableCell>
            <TableCell>From Date</TableCell>
            <TableCell>From Period</TableCell>
            <TableCell>To Date</TableCell>
            <TableCell>To Period</TableCell>
            <TableCell>Available</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adhocAvailabilities.list.map(renderAdhocAvailabilityListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={adhocAvailabilities.totalPages} page={page} onPageSelected={setPage} />
      <FABFixed color='secondary' onClick={() => history.push('/adhoc_availabilities/new')}>
        <AddIcon />
      </FABFixed>
    </PageContainer>
  )
}

export default List