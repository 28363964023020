/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams } from 'hooks'
import {Table, TableBody, TableCell, TableRow} from '@mui/material'
import {PageContainer, PageHeader, PromiseButton} from 'components'
import { Invoice } from "types"
import {formatLocaleDate, humanize} from "utils";
import useInvoices from "hooks/resources/useInvoices";
import {useSnackbar} from "contexts/SnackbarContext";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [invoices, actions] = useInvoices()

  const [, , reloadInvoice] = useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'customer,invoiceRun'})
    }
  }, [id])

  const invoice: Partial<Invoice> = invoices.selected ?? {}
  const snackbar = useSnackbar()

  const handleSend = async () => {
    try {
      await actions.sendBCTI({id})
      snackbar.show("BCTI submitted")
      await reloadInvoice()
    } catch (e) {
      console.error("Failed to submit BCTI", e)
      snackbar.show("Failed to submit BCTI")
    }
  }

  return (
    <PageContainer>
      <PageHeader title={`Invoice - ${invoice.customer?.name} - ${formatLocaleDate(invoice.dateFrom)} - ${formatLocaleDate(invoice.dateTo)}`} actions={
        <>
          {(!invoice.bctiStatus || invoice.bctiStatus === "failed") && invoice.invoiceRun?.approvedAt &&
            <PromiseButton variant="contained" onClick={handleSend}>Send BCTI</PromiseButton>
          }
        </>
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Customer</TableCell>
            <TableCell>{String(invoice.customer?.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Date From</TableCell>
            <TableCell>{formatLocaleDate(invoice.dateFrom || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Date To</TableCell>
            <TableCell>{formatLocaleDate(invoice.dateTo || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Settled Revenue</TableCell>
            <TableCell>${invoice.settledRevenue ? parseFloat(invoice.settledRevenue).toFixed(2) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Actual Revenue</TableCell>
            <TableCell>${invoice.settledRevenue ? parseFloat(invoice.actualRevenue ?? invoice.settledRevenue).toFixed(2) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>BCTI Status</TableCell>
            <TableCell>{invoice.bctiStatus ? humanize(invoice.bctiStatus || '') : 'Not sent'}</TableCell>
          </TableRow>
          {invoice.bctiError &&
            <TableRow>
              <TableCell variant='head'>BCTI Error</TableCell>
              <TableCell>{String(invoice.bctiError || '')}</TableCell>
            </TableRow>
          }
          {invoice.bctiId &&
            <TableRow>
              <TableCell variant='head'>BCTI ID</TableCell>
              <TableCell>{String(invoice.bctiId || '')}</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show