import { Button, ButtonProps } from "@mui/material"
import useRequest from "hooks/useRequest"
import qs from 'qs'

type ExportButtonProps = {
  label?: string,
  filter?: any,
  sort?: string,
  resource: string,
  format?: string,
  urlPath?: string,
  params?: any,
  qsOptions?: any,
} & ButtonProps

const ExportButton = ({ label = 'Export csv', resource, format = "csv", urlPath = undefined, filter, sort, params={}, qsOptions={}, ...rest }: ExportButtonProps) => {
  const performRequest = useRequest()
  const download = async () => {
    const { data: { auth } } = await performRequest(() => { }, "tokens", 'signingToken')
    const queryParams = { ...params, filter, sort, Authorization: auth }
    const path = urlPath ?
      `${window.env.API_BASE}/${urlPath}?${qs.stringify(queryParams, qsOptions)}` :
      `${window.env.API_BASE}/${resource}.${format}?${qs.stringify(queryParams, qsOptions)}`
    window.open(path)
  }

  return (
    <Button onClick={download} variant="contained" color="secondary" {...rest}>
      {label}
    </Button>
  )
}

export default ExportButton