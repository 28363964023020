/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader, LinkButton } from 'components'
import { OfferRunLog } from "types"
import { history } from "utils"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [offerRunLogs, actions] = useResource<OfferRunLog>("offerRunLogs")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: "installedMeter"})
    }
  }, [id])

  const offerRunLog: Partial<OfferRunLog> = offerRunLogs.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Offer Run Log: ${offerRunLog.message}`}/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Severity</TableCell>
            <TableCell>{String(offerRunLog.severity || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Code</TableCell>
            <TableCell>{String(offerRunLog.code || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Message</TableCell>
            <TableCell>{String(offerRunLog.message || '')}</TableCell>
          </TableRow>
          {offerRunLog.tradingDate &&
            <TableRow>
              <TableCell variant='head'>Trading date</TableCell>
              <TableCell>{String(offerRunLog.tradingDate || '')}</TableCell>
            </TableRow>
          }
          {offerRunLog.tradingPeriod &&
            <TableRow>
              <TableCell variant='head'>Trading period</TableCell>
              <TableCell>{String(offerRunLog.tradingPeriod || '')}</TableCell>
            </TableRow>
          }
          {offerRunLog.installedMeter &&
            <TableRow>
              <TableCell variant='head'>Installed meter</TableCell>
              <TableCell><LinkButton onClick={() => history.push(`/installed_meters/${offerRunLog.installedMeter?.id}`)}>{offerRunLog.installedMeter?.meterSerial}</LinkButton></TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show