/** @jsxImportSource @emotion/react */
import React from 'react'
import {useDependency, useCommonRouteParams, useInvoiceRuns} from 'hooks'
import {Table, TableBody, TableCell, TableRow, Button} from '@mui/material'
import {PageContainer, PageHeader, PromiseButton} from 'components'
import { InvoiceRun } from "types"
import {formatLocaleDate, formatLocaleDateTime, history, humanize} from "utils";
import {useSnackbar} from "contexts/SnackbarContext";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [invoiceRuns, actions] = useInvoiceRuns()
  const snackbar = useSnackbar()

  const [, , reloadInvoiceRun] = useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const invoiceRun: Partial<InvoiceRun> = invoiceRuns.selected ?? {}
  const handleApprove = async () => {
    try {
      await actions.approve({id})
      snackbar.show("Invoice Run approved")
      await reloadInvoiceRun()
    } catch (e) {
      console.error("Failed to approve Invoice Run", e)
      snackbar.show("Failed to approve Invoice Run")
    }
  }

  const retrySendBcti = async () => {
    await actions.retrySendBCTI({id})
    await reloadInvoiceRun()
  }

  return (
    <PageContainer>
      <PageHeader title={`Invoice Run - ${formatLocaleDateTime(invoiceRun.startedAt)}`} actions={
        <>
          {!invoiceRun.approvedAt &&
            <Button color="secondary" variant="contained" onClick={() => history.push(`/invoice_runs/${invoiceRun.id}/override`)}>Override Revenue</Button>
          }
          {!invoiceRun.approvedAt &&
            <Button variant="contained" onClick={handleApprove}>Approve</Button>
          }
          {(invoiceRun.submissionStatus === "failed") &&
            <PromiseButton variant="contained" onClick={retrySendBcti}>Retry Send BCTI</PromiseButton>
          }
          <Button variant="contained" onClick={() => history.push(`/invoices?invoices.filter.invoiceRun.id=${invoiceRun.id}`)}>Invoices</Button>
        </>
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Period</TableCell>
            <TableCell>{formatLocaleDate(invoiceRun.dateFrom || '')} - {formatLocaleDate(invoiceRun.dateTo || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>{humanize(invoiceRun.status || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Total Revenue</TableCell>
            <TableCell>{invoiceRun.totalRevenue ? `$${parseFloat(invoiceRun.totalRevenue).toFixed(2)}` : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Cleared Revenue</TableCell>
            <TableCell>{invoiceRun.clearedRevenue ? `$${parseFloat(invoiceRun.clearedRevenue).toFixed(2)}` : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Deliver BCTIs on approval?</TableCell>
            <TableCell>{invoiceRun.deliverBctis ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>BCTI Submission Status</TableCell>
            <TableCell>{humanize(invoiceRun.submissionStatus ?? 'Not Submitted')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Started At</TableCell>
            <TableCell>{formatLocaleDateTime(invoiceRun.startedAt || '', {includeSeconds: true})}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Completed At</TableCell>
            <TableCell>{invoiceRun.approvedAt ? formatLocaleDateTime(invoiceRun.completedAt || '', {includeSeconds: true}) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Approved At</TableCell>
            <TableCell>{invoiceRun.approvedAt ? formatLocaleDateTime(invoiceRun.approvedAt || '', {includeSeconds: true}) : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show