import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

export type ResourceInfo = ResourceEditPage | ResourceNewPage

export type ResourceEditPage = {
  editMode: true;
  type?: string;
  id: string;
  action: 'edit';
  resourceAction: 'update';
}

export type ResourceNewPage = {
  editMode: false;
  type?: string;
  id?: string;
  action: 'new';
  resourceAction: 'create';
}

const useCommonRouteParams = (): ResourceInfo => {
  const match = useRouteMatch<{ id?: string }>()

  const type = match.path.split('/')[1]
  const id = match.params.id
  const action = match.path.match(/\/new(?:\/:[^/]+)?$/) ? 'new' : 'edit'

  return useMemo(() => {
    if (action === "edit") {
      return { ...(type && { type }), id: id!, action, editMode: true, resourceAction: 'update' };
    } else {
      return { ...(type && { type }), ...(action && { action }), editMode: false, resourceAction: 'create' };
    }
  }, [action, id, type])
}

export default useCommonRouteParams
