/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CustomerIcon from '@mui/icons-material/Factory'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError } from 'utils'
import { usePagination, useDependency, useResource, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { Customer } from "types"

const List = () => {
  const [customers, actions] = useResource<Customer>("customers")

  const snackbar = useSnackbar()
  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("customers")

  const [, , reloadCustomers] = useDependency(() => (
    actions.index({
      page: page,
      fields: {customers: 'name'},
      sort: 'name'
    })
  ), [page])

  const showCustomer = ({id}) => () => {
    history.push(`/customers/${id}`)
  }

  const editCustomer = ({id}) => event => {
    history.push(`/customers/${id}/edit`)
    event.stopPropagation()
  }

  const deleteCustomer = ({id}) => () => {
    actions.destroy({id})
           .then(reloadCustomers)
           .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderCustomerListItem = (customer) => {
    const { id, name } = customer
    return (
      <ListItem button onClick={showCustomer(customer)} key={id}>
        <ListItemIcon>
          <CustomerIcon />
        </ListItemIcon>
        <ListItemText primary={name}/>
        {authorization.internalOrAdmin && <ListItemSecondaryAction>
          <IconButton onClick={editCustomer(customer)} size="large"><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteCustomer(customer)} title={`Confirm Customer Removal`}>
            <div>
              <p>Are you sure you want to remove this customer?</p>
              <p>Name: <b>{name}</b></p>
              <p css={{color: 'red'}}>WARNING: this will remove all related users, sites and meters.</p>
            </div>
          </DeleteConfirmationDialog>
        </ListItemSecondaryAction>}
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Customers</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(customers, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={customers.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {customers.list.map(renderCustomerListItem)}
      </MuiList>
      <Pagination totalPages={customers.totalPages} page={page} onPageSelected={setPage}/>
      {authorization.internalOrAdmin &&
        <FABFixed color='secondary' onClick={() => history.push('/customers/new')}>
          <AddIcon />
        </FABFixed>}
    </PageContainer>
  )
}

export default List