/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, PageHeader } from 'components'
import { ConfigOption } from "types"
import { decimalAsPercentage } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [configOptions, actions] = useResource<ConfigOption>("configOptions")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const configOption: Partial<ConfigOption> = configOptions.selected ?? {}
  const displayValue = configOption.dataType === "percentage" ? `${decimalAsPercentage(configOption.currentValue)}%` : configOption.currentValue

  return (
    <PageContainer>
      <PageHeader title={`Config Option - ${configOption.name}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/config_options/${configOption.id}/edit`}>
                <IconButton size="large">
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(configOption.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Data type</TableCell>
              <TableCell>{String(configOption.dataType || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Description</TableCell>
              <TableCell>{String(configOption.description || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Current Value</TableCell>
              <TableCell>{String(displayValue ?? '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show