/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { useFilter, useQueryString, usePlot, useDependency } from 'hooks'
import { ControlledForm, DateTimePicker, LabeledSelect, ExportButton } from 'components'
import { TimeSeriesPlot }  from 'components/charts'
import { StylesObject } from "types"
import { formatPickerDateTime, barChartPlotter, legendFormatter } from 'utils'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const SERIES = {
  fir: 'FIR',
  sir: 'SIR',
}

const DEFAULT_AGGREGATE_GROUP = {
  type: 'aggregate',
  function: 'sum',
  interval: 'trading_period',
}

const HotWater = () => {

  const [urlState, setUrlState] = useQueryString()
  const [filter, setFilter] = useFilter(setUrlState, 'offers')
  const [plotState, plotActions] = usePlot("offers")
  const plot = plotState.plot
  const series = filter.series || ''

  const breakdown = (plot?.timeSeries || []).find(s => s.breakdown)
  const breakdownOpts = (breakdown?.breakdownGroups || []).reduce((acc, curr) => ({...acc, [`${breakdown.label} ${curr}`]: { plotter: barChartPlotter}}), {})
  const defaultOpts = (plot?.timeSeries || []).filter(series => !series.breakdown).reduce((acc, curr) => ({...acc, [curr.label]: { plotter: barChartPlotter}}), {})
  const seriesOpts = {...breakdownOpts, ...defaultOpts}
  const [dygraphOptions, setDygraphOptions] = useState({})

  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0){
      setFilter({
        from: formatPickerDateTime(moment().startOf('day')),
        to: formatPickerDateTime(moment().add(1, 'day').endOf('day')),
        group: DEFAULT_AGGREGATE_GROUP,
        series: 'fir',
      })
    }
  }, [])

  const [loading] = useDependency( async () => {
    if (!filter.from || !filter.to || !moment(filter.from).isValid() || !moment(filter.to).isValid()) {
      return;
    }

    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      await plotActions.plot({ timeSeries })
    }
  }, [JSON.stringify(filter)])

  const currentTimeSeries = () => {
    return [
      {
        series: `offered_bulk_offer_${series}`,
        label: `${SERIES[series]} Hot Water`,
        filter: {
          tradingDatetimeFrom: filter.from,
          tradingDatetimeTo: filter.to,
        },
        group: filter.group,
      },
      {
        series: `offered_forecast_${series}`,
        label: SERIES[series],
        filter: {
          tradingDatetimeFrom: filter.from,
          tradingDatetimeTo: filter.to,
        },
        group: filter.group,
        breakdown: true
      }
    ]
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}>
        <div css={styles.leftFilter}>
          <LabeledSelect
            css={styles.filter}
            name="series"
            fullWidth
            options={SERIES}
          />
        </div>
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.groupOptions}>
        <ExportButton
          css={styles.exportButton}
          variant='text'
          resource="offers/plot"
          params={plotState.params}
          disabled={!plot?.values?.length}
          qsOptions={{ arrayFormat: 'brackets'}}
        />
        <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}

      <TimeSeriesPlot
        plot={plot}
        unit="MW"
        ylabel={`${SERIES[series]} (MW)`}
        maxGranularity={Dygraph.Granularity.THIRTY_MINUTELY}
        loading={loading}
        dygraphOptions={{
          stackedGraph: true,
          series: seriesOpts,
          legendFormatter: legendFormatter({showTotal: true, unit: 'MW'}),
          ...dygraphOptions
        }}
        colorOverrides={[ "#e6194b", "#0082c8", "#3cb44b"]}
      />
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
  },
  groupOptions: {
    marginTop: 30,
    width: '100%',
    textAlign: 'right',
  },
  multiSelectLabel: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  }
} as StylesObject

export default HotWater