import {
  Tokens as TokensBase,
  HotWaterUploads as HotWaterUploadsBase,
  MeterReadUploads as MeterReadUploadsBase,
  MeterTestResultUploads as MeterTestResultUploadsBase,
  AvailabilityUploads as AvailabilityUploadsBase,
} from './generated'

export const Tokens = TokensBase.extend(
  '',
  {
    refresh: endpoint => endpoint.method('put').path('/tokens').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
  }
)
export const HotWaterUploads = HotWaterUploadsBase.extend(
  '',
  {
    create: endpoint => endpoint.method('post').useFormData(true).path(() => {
      return '/hot_water_uploads'
    }),
  }
)

export const MeterReadUploads = MeterReadUploadsBase.extend(
  '',
  {
    create: endpoint => endpoint.method('post').useFormData(true).path(() => {
      return '/meter_read_uploads'
    }),
  }
)

export const MeterTestResultUploads = MeterTestResultUploadsBase.extend(
  '',
  {
    create: endpoint => endpoint.method('post').useFormData(true).path(() => {
      return '/meter_test_result_uploads'
    }),
  }
)

export const AvailabilityUploads = AvailabilityUploadsBase.extend(
  '',
  {
    create: endpoint => endpoint.method('post').useFormData(true).path(() => {
      return '/availability_uploads'
    }),
  }
)

export * from './generated'