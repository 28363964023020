import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTokens } from "contexts/TokensContext";

const useRedirect = () => {
  const history = useHistory()
  const [{ savedLocation }, actions] = useTokens()

  // Go to the previous page by default
  // or use `redirectTo` for next url
  const redirect = useCallback((redirectTo?: string) => {
    if (redirectTo) {
      history.push(redirectTo)
    } else {
      if (window.location.pathname !== savedLocation) {
        history.goBack()
      } else {
        history.push('/')
        actions.clearSavedWindowLocation()
      }
    }
  }, [actions, history, savedLocation])

  return redirect
}

export default useRedirect