/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { TextField, Typography } from '@mui/material'
import { useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import { PageHeader, ControlledForm, ErrorBanner, PageContainer, PromiseButton, DateTimePicker, ExportButton } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { MeterReadReplay } from "types"

const Form = () => {
  const { resourceAction } = useCommonRouteParams()
  const [meterReadReplays, actions] = useResource<MeterReadReplay>("meterReadReplays")
  const [formData, setFormData] = useState<Partial<MeterReadReplay>>({ meterSerials: [] })
  const handleSave = useDefaultSave(formData, actions)

  return (
    <PageContainer>
      <PageHeader title={'Replay Meter Reads from IoT Central'} />
      <ControlledForm data={formData} errors={formErrors(meterReadReplays, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(meterReadReplays, 'create', 'update')}</ErrorBanner>
        <DateTimePicker fullWidth name='from' />
        <DateTimePicker fullWidth name='to' />
        <TextField fullWidth name='meterSerials' label="Meter Serials"
          onChange={({ target: { value } }) => setFormData(fd => ({ ...fd, meterSerials: value.trim().length == 0 ? [] : value.split(',').map(v => v.trim()) }))}
          value={formData?.meterSerials?.join(',') || ''}
          helperText={"Comma separated, or leave blank to replay all active meters"} />
        <div css={{ display: 'flex', marginTop: 10 }}>
          <ExportButton
            color='primary'
            variant='contained'
            css={{ marginBottom: 0, paddingLeft: 0, flex: 1 }}
            resource="iot_central_telemetries"
            params={formData}
            disabled={!(formData.from && formData.to) && !formData?.meterSerials?.some(ms => !!ms)}
            qsOptions={{ arrayFormat: 'brackets'}}
          />
          <PromiseButton color='secondary' variant='contained' type='submit' css={{ flex: 1, marginBottom: 0 }}>Import</PromiseButton>
        </div>
        <Typography variant="caption" color="gray">Note that CSV export is limited to 10,000 rows.</Typography>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form