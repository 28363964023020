/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import {Table, TableBody, TableCell, TableRow} from '@mui/material'
import {PageContainer, Link, PageHeader} from 'components'
import {Customer, InstalledMeter, Settlement} from "types"
import {formatLocaleDate, formatLocaleDateTime, humanize, snakeCased} from "utils";
import pluralize from 'pluralize'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [settlements, actions] = useResource<Settlement>("settlements")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'price,settlementRun,target'})
    }
  }, [id])

  const settlement: Partial<Settlement> = settlements.selected ?? {}
  const targetLabel = `${humanize(pluralize.singular(settlement.target?.type?.replace('installed', '') || ''))} - ${(settlement.target as InstalledMeter)?.meterSerial || (settlement.target as Customer)?.name}`

  return (
    <PageContainer>
      <PageHeader title={`Settlement - ${targetLabel} ${formatLocaleDate(settlement.tradingDate)} TP ${settlement.tradingPeriod}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Target</TableCell>
            <TableCell>
              <Link to={`/${snakeCased(settlement.target?.type)}/${settlement.target?.id}`}>
                {targetLabel}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Trading Date</TableCell>
            <TableCell>{formatLocaleDate(settlement.tradingDate || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Trading Period</TableCell>
            <TableCell>{String(settlement.tradingPeriod || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>FIR Revenue</TableCell>
            <TableCell>{settlement.firRevenue ? parseFloat(settlement.firRevenue).toFixed(3) : ''}$</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>SIR Revenue</TableCell>
            <TableCell>{settlement.sirRevenue ? parseFloat(settlement.sirRevenue).toFixed(3) : ''}$</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>FIR Cleared</TableCell>
            <TableCell>{settlement.firDispatchedAmount ? parseFloat(settlement.firDispatchedAmount).toFixed(3) : ''} MW</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>SIR Cleared</TableCell>
            <TableCell>{settlement.sirDispatchedAmount ? parseFloat(settlement.sirDispatchedAmount).toFixed(3) : ''} MW</TableCell>
          </TableRow>
          {settlement.firOfferProportion &&
            <TableRow>
              <TableCell variant='head'>FIR Offer Proportion</TableCell>
              <TableCell>{settlement.firOfferProportion ? parseFloat(settlement.firOfferProportion).toFixed(6) : ''}</TableCell>
            </TableRow>
          }
          {settlement.sirOfferProportion &&
            <TableRow>
              <TableCell variant='head'>SIR Offer Proportion</TableCell>
              <TableCell>{settlement.sirOfferProportion ? parseFloat(settlement.sirOfferProportion).toFixed(6) : ''}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell variant='head'>Price</TableCell>
            <TableCell>
              <Link to={`/prices/${settlement.price?.id}`}>
                FIR {settlement.price?.firPrice}$ / SIR {settlement.price?.sirPrice}$
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Settlement Run</TableCell>
            <TableCell>
              <Link to={`/settlement_runs/${settlement.settlementRun?.id}`}>
                {formatLocaleDateTime(settlement.settlementRun?.startedAt || '')}
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show