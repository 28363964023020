/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDependency, useFilter, usePagination, useResource } from 'hooks'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { humanize, userFriendlyDate } from 'utils'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import {
  Pagination,
  SearchCombo,
  MultiSelect,
  LinkButton,
  ConfirmationDialog,
  PageContainer,
  LabeledSelect
} from 'components'
import { Audit, ChangeSet } from "types";
import { useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'

const humanizeValue = (value) => {
  if (value && isNaN(value) && moment(value).isValid()) {
    return userFriendlyDate(value)
  }
  else {
    return value
  }
}

const getChangeSet = (audit) => (
  Object.entries((audit?.changeset || {}) as ChangeSet).map(([field, [from, to]]) => ({ field, from, to }))
)

const List = () => {
  const [audits, actions] = useResource<Audit>("audits")

  const [page, setPage] = usePagination('audits')
  const [filter, setFilter] = useFilter(setPage, 'audits')

  useDependency(() => (
    actions.index({
      page,
      include: 'actor',
      filter
    })
  ), [page, filter])

  const [showAudit, setShowAudit] = useState<Audit | null>(null)

  const everyEvent = ['create', 'update', 'delete']

  const history = useHistory();

  const showUser = (id) => {
    history.push(`/users/${id}`)
  }

  const showServiceAccount = (id) => {
    history.push(`/service_accounts/${id}`)
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Audit Logs</Typography>
      <Pagination
        totalPages={audits.totalPages}
        page={page}
        onPageSelected={setPage}
        startAdornment={
          <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
            <div>
              <TextField label='Entity' name='item_type' fullWidth={false} css={{ width: '50%' }} />
              <TextField label='ID' name='item_id' fullWidth={false} css={{ width: '50%' }} />
            </div>
            <div>
              <TextField label='Actor ID' name='actor_id' fullWidth={false} css={{ width: '50%' }} />
              <LabeledSelect name='actor_type' options={["User", "ServiceAccount"]} fullWidth={false} css={{ width: '50%' }} />
            </div>
            <div>
              <DatePicker label='Start Time' name='createdAtDateAfter' css={{ width: '50%' }} />
              <DatePicker label='End Time' name='createdAtDateBefore' css={{ width: '50%' }} />
            </div>
            <div>
              <Typography variant="subtitle1">Events</Typography>
              <MultiSelect name="event" options={everyEvent} defaultOptions={everyEvent} unsetWhenDefault />
            </div>
          </SearchCombo>
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Entity</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Actor</TableCell>
            <TableCell>Create Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.list.map(audit => (
            <TableRow key={audit.id}>
              <TableCell>{humanize(audit.event)}</TableCell>
              <TableCell >{audit.itemType}</TableCell>
              <TableCell >
                <LinkButton onClick={() => setShowAudit(audit)} disabled={!getChangeSet(audit).length}>
                  {audit.itemId}
                </LinkButton>
              </TableCell>
              <TableCell><LinkButton onClick={() => audit.actorType == "User" ? showUser(audit?.actor?.id) : showServiceAccount(audit?.actor?.id)}>{audit?.actor?.name}</LinkButton></TableCell>
              <TableCell>{userFriendlyDate(audit.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog title="Changes" onConfirm={() => setShowAudit(null)} open={!!showAudit}
        canCancel={false}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Old Value</TableCell>
              <TableCell>New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getChangeSet(showAudit).map(change =>
              <TableRow key={change.field}>
                <TableCell >{humanize(change.field)}</TableCell>
                <TableCell >{humanizeValue(change.from)}</TableCell>
                <TableCell >{humanizeValue(change.to)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ConfirmationDialog>
    </PageContainer>
  )
}

export default List
