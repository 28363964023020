/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DetailIcon from '@mui/icons-material/InfoOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, formatLocaleDate } from 'utils'
import { usePagination, useDependency, useResource, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { MeterDetail } from "types"

const List = ({ installedMeterId }) => {
  const [meterDetails, actions] = useResource<MeterDetail>("meterDetails")

  const snackbar = useSnackbar()
  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("meterDetails")

  const [, , reloadMeterDetails] = useDependency(() => (
    actions.index({
      page: page,
      fields: { meterDetails: 'startDate,endDate' },
      filter: { installedMeterId },
      sort: '-startDate',
    })
  ), [page])

  const showMeterDetail = ({ id }) => () => {
    history.push(`/meter_details/${id}`)
  }

  const editMeterDetail = ({ id }) => event => {
    history.push(`/meter_details/${id}/edit`)
    event.stopPropagation()
  }

  const deleteMeterDetail = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadMeterDetails)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderMeterDetailListItem = (meterDetail) => {
    const { id, startDate, endDate } = meterDetail
    return (
      <ListItem button onClick={showMeterDetail(meterDetail)} key={id}>
        <ListItemIcon>
          <DetailIcon />
        </ListItemIcon>
        <ListItemText primary={`${formatLocaleDate(startDate)} - ${endDate ? formatLocaleDate(endDate) : 'Future'}`} />
        {authorization.internalOrAdmin && <ListItemSecondaryAction>
          <IconButton onClick={editMeterDetail(meterDetail)} size="large"><EditIcon /></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteMeterDetail(meterDetail)} entityName="meter detail" />
        </ListItemSecondaryAction>}
      </ListItem>
    )
  }

  const latestDetail = meterDetails.list[0]
  const newUrl = installedMeterId ? (latestDetail ? `/meter_details/${latestDetail.id}/new` : `/meter_details/new?installedMeterId=${installedMeterId}`) : '/meter_details/new'

  return (
    <PageContainer>
      <Typography variant='h4' css={{ marginBottom: 20, display: 'flex' }}>
        Meter Details
        <span css={{ flex: '1' }} />
        {installedMeterId && authorization.internalOrAdmin && <Button variant="contained" onClick={() => history.push(newUrl)}>New</Button>}
      </Typography>
      <ErrorBanner>
        {apiErrorStringsFor(meterDetails, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterDetails.totalPages} page={page} onPageSelected={setPage} />
      <MuiList dense>
        {meterDetails.list.map(renderMeterDetailListItem)}
      </MuiList>
      <Pagination totalPages={meterDetails.totalPages} page={page} onPageSelected={setPage} />
      {!installedMeterId && <FABFixed color='secondary' onClick={() => history.push('/meter_details/new')}>
        <AddIcon />
      </FABFixed>}
    </PageContainer>
  )
}

export default List