/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useNotifications } from 'hooks'
import { Table, TableBody, TableCell, TableRow, Button } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { Notification, StylesObject } from "types"
import { useDashboard } from 'contexts/DashboardContext'
import { humanize, formatLocaleDateTime } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [notifications, actions] = useNotifications()
  const dashboard = useDashboard()

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const notification: Partial<Notification> = notifications.selected ?? {}

  const handleMarkNotification = () => {
    actions.mark({[notification.readAt ? 'unread' : 'read']: [notification.id]})
      .then(() => actions.show(id || ''))
      .then(dashboard.fetchNotifications) // refresh dashboard alert count
  }

  return (
    <PageContainer>
      <PageHeader title={`Notification - ${humanize(notification.notificationType)}`} />
      <Table css={styles.table}>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Type</TableCell>
            <TableCell>{humanize(notification.notificationType)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Short Message</TableCell>
            <TableCell>{String(notification.shortMessage || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Message</TableCell>
            <TableCell>
              {notification.fullMessage?.split("\n")?.map((line, idx) => (
                idx !== 0 ? <><br />{line}</> : <>{line}</>
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Created At</TableCell>
            <TableCell>{formatLocaleDateTime(notification.createdAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Read At</TableCell>
            <TableCell>{formatLocaleDateTime(notification.readAt)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
      <div css={styles.actions}>
        <Button size="large" onClick={handleMarkNotification}>
          {notification.readAt ? 'Mark as Unread' : 'Mark as Read'}
        </Button>
      </div>
    </PageContainer>
  )
}

const styles = {
  table: {
    '& .MuiTableCell-head': {
      minWidth: 150
    }
  },
  actions: {
    marginTop: 20
  }
} as StylesObject

export default Show