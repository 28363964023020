/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import {Pagination, ErrorBanner, PageContainer, SearchCombo, LabeledSelect, DateTimePicker} from 'components'
import {
  Button,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDateTime} from 'utils'
import {usePagination, useDependency, useResource, useFilter} from 'hooks'
import { useHistory } from 'react-router-dom'
import { DispatchInstruction } from "types"
import {ControlledForm, PageHeader, DatePicker, ConfirmationDialog} from "../../components";
import useDispatchInstructions from "../../hooks/resources/useDispatchInstructions";
import {string} from "prop-types";
import {useSnackbar} from "../../contexts/SnackbarContext";

const List = () => {
  const [dispatchInstructions, actions] = useDispatchInstructions()
  const snackbar = useSnackbar()
  const history = useHistory()

  const [page, setPage] = usePagination("dispatchInstructions")
  const [filter, setFilter] = useFilter(setPage, 'dispatchInstructions')


  useDependency(() => (
    actions.index({
      page: page,
      fields: {dispatchInstructions: 'dispatchType,quantityMW,dispatchedAt,welId,block'},
      filter,
      sort: '-dispatchedAt'
    })
  ), [page, filter])

  const showDispatchInstruction = ({id}) => () => {
    history.push(`/dispatch_instructions/${id}`)
  }

  const renderDispatchInstructionListItem = (dispatchInstruction) => {
    const { id, welId, dispatchType, quantityMW, dispatchedAt, block } = dispatchInstruction
    return (
      <TableRow key={id} onClick={showDispatchInstruction(dispatchInstruction)} css={{ cursor: 'pointer' }}>
        <TableCell>{welId}</TableCell>
        <TableCell>{dispatchType}</TableCell>
        <TableCell>{formatLocaleDateTime(dispatchedAt, {includeSeconds: true})}</TableCell>
        <TableCell>{quantityMW} MW</TableCell>
        <TableCell>{block}</TableCell>
      </TableRow>
    )
  }

  const [fetchDialogOpen, setFetchDialogOpen] = useState<boolean>(false)
  const [dialogState, setDialogState] = useState<{startDate?: string; endDate?: string}>({})
  const handleRequestInstructions = async () => {
    if (!dialogState.startDate! || !dialogState.endDate!) {
      return;
    }
    try {
      await actions.manualRequest(dialogState as {startDate: string; endDate: string})
      snackbar.show("Manual request submitted")
    } catch (e) {
      console.error("Manual request failed", e)
      snackbar.show("Failed to request instructions")
    }
    setFetchDialogOpen(false)
    setDialogState({})
  }

  const dialog = (
    <ConfirmationDialog open={fetchDialogOpen}
                        title="Request dispatch instructions from BidWel"
                        confirmLabel="Request"
                        canConfirm={Boolean(dialogState.startDate && dialogState.endDate)}
                        onConfirm={handleRequestInstructions}
                        onCancel={() => setFetchDialogOpen(false)}>
      <ControlledForm data={dialogState} onChange={setDialogState}>
        <DatePicker name="startDate" fullWidth />
        <DatePicker name="endDate" fullWidth />
      </ControlledForm>
    </ConfirmationDialog>
  )

  return (
    <PageContainer>
      <PageHeader title="Dispatch Instructions"
                  actions={<Button variant="contained" onClick={() => setFetchDialogOpen(true)}>Manual Fetch</Button>} />
      <ErrorBanner>
        {apiErrorStringsFor(dispatchInstructions, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={dispatchInstructions.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='welId' onFilterChange={setFilter} filter={filter}>
                      <TextField name="welId" fullWidth label="WEL Dispatch Instruction ID" />
                      <LabeledSelect name="dispatchType" fullWidth options={{intf: "INTF", ints: "INTS"}} />
                      <DateTimePicker name="dispatchedAtFrom" fullWidth />
                      <DateTimePicker name="dispatchedAtTo" fullWidth />
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>WEL ID</TableCell>
            <TableCell>Dispatch Type</TableCell>
            <TableCell>Dispatch Time</TableCell>
            <TableCell>Quantity MW</TableCell>
            <TableCell>Block</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dispatchInstructions.list.map(renderDispatchInstructionListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={dispatchInstructions.totalPages} page={page} onPageSelected={setPage}/>
      {dialog}
    </PageContainer>
  )
}

export default List