/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import {Table, TableBody, TableCell, TableRow, Button} from '@mui/material'
import {PageContainer, PageHeader} from 'components'
import { SettlementRun } from "types"
import {formatLocaleDate, formatLocaleDateTime, history, humanize} from "utils"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [settlementRuns, actions] = useResource<SettlementRun>("settlementRuns")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const settlementRun: Partial<SettlementRun> = settlementRuns.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title="Settlement Run" actions={
        settlementRun.status === "completed" && <Button variant="contained" onClick={() => 
          history.push(`/settlements?Customer_settlements.filter.settlementRun.id=${id}&BulkReserveSource_settlements.filter.settlementRun.id=${id}&InstalledMeter_settlements.filter.settlementRun.id=${id}`)}>Settlements</Button>
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Started At</TableCell>
            <TableCell>{formatLocaleDateTime(settlementRun.startedAt || '', {includeSeconds: true})}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>From Date</TableCell>
            <TableCell>{formatLocaleDate(settlementRun.startDate || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>To Date</TableCell>
            <TableCell>{formatLocaleDate(settlementRun.endDate || '')}</TableCell>
          </TableRow>
          {settlementRun.completedAt &&
            <TableRow>
              <TableCell variant='head'>Completed At</TableCell>
              <TableCell>{formatLocaleDateTime(settlementRun.completedAt, {includeSeconds: true})}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>{humanize(settlementRun.status || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show