/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import {
  LabeledSelect,
  Pagination,
  ErrorBanner,
  PageContainer,
  SearchCombo,
  DatePicker, AutocompleteSearch, PageHeader
} from 'components'
import {
  TableRow, TableCell, TableHead, TableBody, Table, TextField,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime} from 'utils'
import {usePagination, useDependency, useResource, useFilter} from 'hooks'
import { useHistory } from 'react-router-dom'
import { BulkOffer } from "types"

const List = () => {
  const [bulkOffers, actions] = useResource<BulkOffer>("bulkOffers")
  const history = useHistory()

  const [page, setPage] = usePagination("bulkOffers")
  const [filter, setFilter] = useFilter(setPage, 'bulkOffers')

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      setFilter({current: true, lastAvailable: true})
    }
  }, [])

  useDependency(() => (
    actions.index({
      page: page,
      include: 'bulkReserveSource,bulkOfferBatch',
      filter,
      sort: 'tradingDate,tradingPeriod'
    })
  ), [page, filter])

  const showBulkOffer = ({id}) => () => {
    history.push(`/bulk_offers/${id}`)
  }

  const renderBulkOfferListItem = (bulkOffer) => {
    const { id, tradingDate, tradingPeriod, firOffer, sirOffer, bulkReserveSource, bulkOfferBatchId, bulkOfferBatch } = bulkOffer
    return (
      <TableRow key={id} onClick={showBulkOffer(bulkOffer)} css={{ cursor: 'pointer' }}>
        <TableCell>{bulkOfferBatchId}</TableCell>
        <TableCell>{bulkReserveSource.name}</TableCell>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>TP {tradingPeriod}</TableCell>
        <TableCell>{firOffer} MW</TableCell>
        <TableCell>{sirOffer} MW</TableCell>
        <TableCell>{formatLocaleDateTime(bulkOfferBatch?.createdAt)}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title="Bulk Offers" />
      <ErrorBanner>
        {apiErrorStringsFor(bulkOffers, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={bulkOffers.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
                      <DatePicker name="tradingDate" fullWidth />
                      <TextField name="tradingPeriod" type="number" fullWidth />
                      <AutocompleteSearch name="bulkReserveSource" />
                      <AutocompleteSearch name="bulkOfferBatch" searchableField="createdAt" getOptionLabel={(o) => o ? formatLocaleDateTime(o.createdAt) : ''} />
                      <LabeledSelect name="lastAvailable" label="Show only effective offers" fullWidth options={{'true': "Yes", "false": "No"}} />
                      <LabeledSelect name="current" label="Show only current offers" fullWidth options={{'true': "Yes", "false": "No"}} />
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Batch</TableCell>
            <TableCell>Bulk Reserve Source</TableCell>
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell>FIR Offer</TableCell>
            <TableCell>SIR Offer</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bulkOffers.list.map(renderBulkOfferListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={bulkOffers.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List