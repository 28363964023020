import useResource, {Actions, ResourceState} from "../useResource";
import {Price} from "types";
import useRequest from "../useRequest";

export type PricesResource = [
  ResourceState<Price>,
    Actions<Price> & {
    manualRequest: () => Promise<void>;
  }
]

const usePrices = (): PricesResource => {
  const [state, actions] = useResource<Price>("prices")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    manualRequest: async () => {
      await performRequest(actions.setState, "prices", 'manualRequest')
    },
  }
  return [state, newActions]
}

export default usePrices