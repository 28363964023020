/** @jsxImportSource @emotion/react */
import React  from 'react'
import {Pagination, ErrorBanner, PageContainer, PageHeader} from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, humanize} from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { InvoiceRun } from "types"
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const List = () => {
  const [invoiceRuns, actions] = useResource<InvoiceRun>("invoiceRuns")
  const history = useHistory()

  const [page, setPage] = usePagination("invoiceRuns")

  useDependency(() => (
    actions.index({
      page: page,
      fields: {invoiceRuns: 'dateFrom,dateTo,status'},
      sort: '-id'
    })
  ), [page])

  const showInvoiceRun = ({id}) => () => {
    history.push(`/invoice_runs/${id}`)
  }

  const renderInvoiceRunListItem = (invoiceRun) => {
    const { id, dateFrom, dateTo, status } = invoiceRun
    return (
      <ListItem button onClick={showInvoiceRun(invoiceRun)} key={id}>
        <ListItemIcon>
          <ReceiptLongIcon />
        </ListItemIcon>
        <ListItemText primary={`${formatLocaleDate(dateFrom)} - ${formatLocaleDate(dateTo)}`} secondary={humanize(status)}/>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title="Invoice Runs" actions={
        <Button variant="contained" onClick={() => history.push('/invoice_runs/new')}>
          Ad-hoc Invoice Run
        </Button>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(invoiceRuns, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={invoiceRuns.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {invoiceRuns.list.map(renderInvoiceRunListItem)}
      </MuiList>
      <Pagination totalPages={invoiceRuns.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List