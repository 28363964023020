/** @jsxImportSource @emotion/react */
import React, { Fragment, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import AuditIcon from '@mui/icons-material/Security'
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CustomerIcon from '@mui/icons-material/Factory'
import ForecastIcon from '@mui/icons-material/BarChart'
import ForecastRunIcon from '@mui/icons-material/PlayCircle'
import SiteIcon from '@mui/icons-material/Place'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import MeterIcon from '@mui/icons-material/Speed'
import MeterReadIcon from '@mui/icons-material/ElectricMeter'
import MeterReadUploadIcon from '@mui/icons-material/Backup'
import MeterReadReplayIcon from '@mui/icons-material/Replay'
import ConfigIcon from '@mui/icons-material/Settings'
import ServiceAccountIcon from '@mui/icons-material/MiscellaneousServices'
import DocumentIcon from '@mui/icons-material/Description'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShowerIcon from '@mui/icons-material/Shower';
import PublishIcon from '@mui/icons-material/Publish';
import AvailabilityUploadIcon from '@mui/icons-material/CalendarMonth';
import AdhocAvailabilityIcon from '@mui/icons-material/Today';
import FeedIcon from '@mui/icons-material/Feed';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import CalculateIcon from '@mui/icons-material/Calculate';
import FunctionsIcon from '@mui/icons-material/Functions';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ForecastEvaluationIcon from '@mui/icons-material/Insights'
import BiotechIcon from '@mui/icons-material/Biotech';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DashboardIcon from '@mui/icons-material/Dashboard'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTokens } from 'contexts/TokensContext';
import { useAuthorization, useVersion, useOffers } from 'hooks';
import { StylesObject } from 'types'
import { formatLocaleDateTime } from "utils";
import { Tooltip } from "@mui/material";
import { humanize } from 'utils';
import { Notification } from 'containers';
import { SubMenu } from 'components';

const Nav = (props) => {
  const [, tokensActions] = useTokens()
  const authorization = useAuthorization()
  const [version, fetchVersion] = useVersion()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [useMenuOpen, setUserMenuOpen] = useState(false)
  const userMenuButtonRef = useRef(null)

  const [offers, offerActions] = useOffers()

  useEffect(() => {
    fetchVersion()

    if (authorization.internalOrAdmin) {
      offerActions.latestAcknowledgedTime()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (!authorization.internalOrAdmin) {
      return;
    }

    const interval = setInterval(() => {
      offerActions.latestAcknowledgedTime()
    }, 60000);

    return () => clearInterval(interval);
  }, [])

  return (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <div css={styles.title}>
            <Link to='/' css={styles.home}>
              <img src="/logo_reversed.png" alt="WEL Networks Logo" css={styles.logo} />
              <Typography variant="h5">TradeWEL</Typography>
            </Link>
          </div>
          {authorization.internalOrAdmin &&
            <div css={{ marginRight: 15, cursor: 'help' }}>
              <Tooltip title="Time when the last successful offer acknowledgement was received">
                <Typography variant="body2">
                  Updated {offers.latestAcknowledgedTime ? formatLocaleDateTime(offers.latestAcknowledgedTime) : '...'}
                </Typography>
              </Tooltip>
            </div>
          }
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={() => setDrawerOpen(true)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Notification.Alerts />
          <IconButton
            color="inherit"
            onClick={() => setUserMenuOpen(true)}
            ref={userMenuButtonRef}
            size="large">
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={userMenuButtonRef.current}
        open={useMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <MenuItem disabled={true}>{authorization?.user?.name} ({humanize(authorization?.user?.role)})</MenuItem>
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={() => { tokensActions.destroy(true) }}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        css={styles.drawer}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClick={() => setDrawerOpen(false)}
      >
        {
          (authorization.admin || authorization.internal || authorization.external) &&
          <Link to='/dashboard/historic'>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        }

        <SubMenu title="Meter" icon={<MeterIcon />}>
          {
            (authorization.admin || authorization.internal || authorization.csc) &&
            <Link to='/customers'>
              <ListItem button>
                <ListItemIcon>
                  <CustomerIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
              </ListItem>
            </Link>
          }

          <Link to='/sites'>
            <ListItem button>
              <ListItemIcon>
                <SiteIcon />
              </ListItemIcon>
              <ListItemText primary="Sites" />
            </ListItem>
          </Link>

          <Link to='/installed_meters'>
            <ListItem button>
              <ListItemIcon>
                <MeterIcon />
              </ListItemIcon>
              <ListItemText primary="Meters" />
            </ListItem>
          </Link>

          {
            (authorization.admin || authorization.external || authorization.internal) &&
            <Link to='/meter_reads'>
              <ListItem button>
                <ListItemIcon>
                  <MeterReadIcon />
                </ListItemIcon>
                <ListItemText primary="Meter Reads" />
              </ListItem>
            </Link>
          }

          {
            authorization.admin &&
            <Link to='/meter_read_uploads'>
              <ListItem button>
                <ListItemIcon>
                  <MeterReadUploadIcon />
                </ListItemIcon>
                <ListItemText primary="Meter Read Uploads" />
              </ListItem>
            </Link>
          }

          {
            authorization.admin &&
            <Link to='/meter_read_replays'>
              <ListItem button>
                <ListItemIcon>
                  <MeterReadReplayIcon />
                </ListItemIcon>
                <ListItemText primary="Meter Read Replays" />
              </ListItem>
            </Link>
          }

          {
            (authorization.admin || authorization.internal) &&
            <Link to='/meter_test_result_uploads'>
              <ListItem button>
                <ListItemIcon>
                  <UploadFileIcon />
                </ListItemIcon>
                <ListItemText primary="Test Result Uploads" />
              </ListItem>
            </Link>
          }
        </SubMenu>

        <SubMenu title="Meter Availability" icon={<AvailabilityUploadIcon />}>
          <Link to='/availability_uploads'>
            <ListItem button>
              <ListItemIcon>
                <AvailabilityUploadIcon />
              </ListItemIcon>
              <ListItemText primary="Availability Uploads" />
            </ListItem>
          </Link>

          <Link to='/adhoc_availabilities'>
            <ListItem button>
              <ListItemIcon>
                <AdhocAvailabilityIcon />
              </ListItemIcon>
              <ListItemText primary="Adhoc Availabilities" />
            </ListItem>
          </Link>
        </SubMenu>

        {
          (authorization.admin || authorization.internal) &&
          <Link to='/hot_water_uploads'>
            <ListItem button>
              <ListItemIcon>
                <ShowerIcon />
              </ListItemIcon>
              <ListItemText primary="Hot Water" />
            </ListItem>
          </Link>
        }

        {
          (authorization.admin || authorization.internal || authorization.external) &&
          <SubMenu title="Forecast" icon={<ForecastEvaluationIcon />}>
            {
              (authorization.admin || authorization.internal) &&
              <Link to='/forecast_runs'>
                <ListItem button>
                  <ListItemIcon>
                    <ForecastRunIcon />
                  </ListItemIcon>
                  <ListItemText primary="Forecast Runs" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.internal || authorization.external) &&
              <Link to='/forecasts'>
                <ListItem button>
                  <ListItemIcon>
                    <ForecastIcon />
                  </ListItemIcon>
                  <ListItemText primary="Forecast Data" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.internal) &&
              <Link to='/forecast_evaluation_runs'>
                <ListItem button>
                  <ListItemIcon>
                    <QueryStatsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Forecast Evaluation Runs" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.internal) &&
              <Link to='/forecast_evaluations'>
                <ListItem button>
                  <ListItemIcon>
                    <ForecastEvaluationIcon />
                  </ListItemIcon>
                  <ListItemText primary="Forecast Evaluations" />
                </ListItem>
              </Link>
            }
          </SubMenu>
        }

        {
          (authorization.admin || authorization.internal) &&
          <SubMenu title="Offer" icon={<InsertDriveFileIcon />}>
            <Link to='/offer_runs'>
              <ListItem button>
                <ListItemIcon>
                  <PublishIcon />
                </ListItemIcon>
                <ListItemText primary="Offer Runs" />
              </ListItem>
            </Link>
            <Link to='/offers?offers.filter.showOnly=latest_offers&offers.filter.hideHistory=true'>
              <ListItem button>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Effective Offers" />
              </ListItem>
            </Link>
            <Link to='/bulk_offers'>
              <ListItem button>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary="Bulk Offers" />
              </ListItem>
            </Link>
            <Link to='/bulk_offer_batches'>
              <ListItem button>
                <ListItemIcon>
                  <SnippetFolderIcon />
                </ListItemIcon>
                <ListItemText primary="Bulk Offer Batches" />
              </ListItem>
            </Link>
            <Link to='/bulk_reserve_sources'>
              <ListItem button>
                <ListItemIcon>
                  <BatteryChargingFullIcon />
                </ListItemIcon>
                <ListItemText primary="Bulk Reserve Sources" />
              </ListItem>
            </Link>
          </SubMenu>
        }

        {
          (authorization.admin || authorization.external || authorization.internal) &&
          <SubMenu title="Settlement" icon={<CalculateIcon />}>

            {
              (authorization.admin || authorization.internal || authorization.external) &&
              <Link to='/prices'>
                <ListItem button>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Prices" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.internal) &&
              <Link to='/dispatch_instructions'>
                <ListItem button>
                  <ListItemIcon>
                    <FeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dispatch Instructions" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.internal) &&
              <Link to='/derived_dispatches'>
                <ListItem button>
                  <ListItemIcon>
                    <ViewTimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Average Dispatch" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin) &&
              <Link to='/settlement_runs'>
                <ListItem button>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settlement Runs" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin || authorization.external) &&
              <Link to='/settlements'>
                <ListItem button>
                  <ListItemIcon>
                    <CalculateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settlements" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin) &&
              <Link to='/invoice_runs'>
                <ListItem button>
                  <ListItemIcon>
                    <ReceiptLongIcon />
                  </ListItemIcon>
                  <ListItemText primary="Invoice Runs" />
                </ListItem>
              </Link>
            }

            {
              (authorization.admin) &&
              <Link to='/invoices'>
                <ListItem button>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary="Invoices" />
                </ListItem>
              </Link>
            }
          </SubMenu>
        }

        <Link to='/under_frequency_events'>
          <ListItem button>
            <ListItemIcon>
              <ElectricBoltIcon />
            </ListItemIcon>
            <ListItemText primary="Under Frequency Events" />
          </ListItem>
        </Link>

        {
          (authorization.admin || authorization.csc) &&
          <SubMenu title="Administration" icon={<ConfigIcon />}>
            {
              (authorization.admin || authorization.csc) &&
              <Link to='/users'>
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </Link>
            }
            {
              authorization.admin &&
              <Link to='/service_accounts'>
                <ListItem button>
                  <ListItemIcon>
                    <ServiceAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary="Service Accounts" />
                </ListItem>
              </Link>
            }
            {
              authorization.admin &&
              <Link to='/apidocs'>
                <ListItem button>
                  <ListItemIcon>
                    <DocumentIcon />
                  </ListItemIcon>
                  <ListItemText primary="API Docs" />
                </ListItem>
              </Link>
            }
            {authorization.admin &&
              <Link to='/audits'>
                <ListItem button>
                  <ListItemIcon>
                    <AuditIcon />
                  </ListItemIcon>
                  <ListItemText primary="Audits" />
                </ListItem>
              </Link>
            }
            {authorization.admin &&
              <Link to='/config_options'>
                <ListItem button>
                  <ListItemIcon>
                    <ConfigIcon />
                  </ListItemIcon>
                  <ListItemText primary="Config Options" />
                </ListItem>
              </Link>
            }
          </SubMenu>
        }

        <div css={styles.version}>
          {/*API {version} / CLIENT {process.env.REACT_APP_VERSION}*/}
          Version {version}
        </div>
      </Drawer>
    </Fragment >
  );
}

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: '1',
  },
  home: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 70,
    height: 'auto',
    margin: '18px 18px 12px 0px',
  },
  drawer: {
    '& .MuiPaper-root': {
      width: 300,
    }
  },
  version: {
    position: 'fixed',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: '#9a9a9a',
  }
} as StylesObject

export default Nav
