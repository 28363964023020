/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react'
import { Button } from '@mui/material'
import { useResource } from 'hooks'
import { ControlledForm, ErrorBanner, LabeledSelect, LoaderOverlay, PageContainer, PageHeader } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { AvailabilityUpload } from "types"
import { useSnackbar } from 'contexts/SnackbarContext'
import useRedirect from 'hooks/useRedirect'

const FileUploadButton = ({ onChange, value }: { onChange: React.ChangeEventHandler<HTMLInputElement>, value?: File, name?: string }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        onChange={onChange}
        accept=".xlsx"
        ref={inputFile}
        hidden
      />
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <div css={{ marginRight: 16 }}>{value?.name || "No file selected"}</div>
        <Button color="secondary" variant="contained" onClick={() => inputFile.current?.click()} css={{ marginBottom: 0, flexShrink: 0 }}>Select file</Button>
      </div>
    </>
  )
}

const Form = () => {
  const [availabilityUploads, actions] = useResource<AvailabilityUpload>("availabilityUploads")
  const [formData, setFormData] = useState<Partial<AvailabilityUpload>>({})
  const [saving, setSaving] = useState<boolean>(false)
  const snackbar = useSnackbar()
  const redirect = useRedirect()

  const handleFileChange = (event) => {
    setFormData(fd => ({ ...fd, file: event?.target?.files?.[0] }))
  }

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const upload = await actions.create(formData)
      redirect(`/availability_uploads/${upload.id}`)
    } catch {
      snackbar.show('Error Uploading File')
    }
    setSaving(false)
  }

  return (
    <PageContainer>
      <PageHeader title={`Upload Meter Availability`} actions={
        <span>
          <a href="/templates/Adhoc Availability Template.xlsx" css={{ marginRight: 16 }}>
            <Button variant="text" color="primary">Adhoc Template</Button>
          </a>
          <a href="/templates/Availability Template.xlsx">
            <Button variant="text" color="primary">Availability Template</Button>
          </a>
        </span>
      } />
      <LoaderOverlay open={saving} />
      <ControlledForm data={formData} errors={formErrors(availabilityUploads, 'create')} onChange={setFormData} onSubmit={handleSubmit}>
        <ErrorBanner>{apiErrorStringsFor(availabilityUploads, 'create', 'update')}</ErrorBanner>
        <LabeledSelect fullWidth name="uploadType" options={{ availability: 'Availability', "adhoc_availability": 'Ad-hoc Availability' }} />
        <FileUploadButton name="file" onChange={handleFileChange} />
        <Button color='secondary' fullWidth variant='contained' type='submit' disabled={!formData?.file || !formData.uploadType || saving}>Upload</Button>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form