/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, ErrorBanner, PageContainer, ExportButton, PageHeader, SearchCombo, AutocompleteSearch, DateTimePicker } from 'components'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDateTime, siteAndMeterFilters } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { MeterRead } from "types"

const List = () => {
  const [meterReads, actions] = useResource<MeterRead>("meterReads")

  const [page, setPage] = usePagination("meterReads")
  const [filter, setFilter] = useFilter(setPage, 'offers')
  const sort = '-readAt,installedMeterId'

  useDependency(() => (
    actions.index({
      page: page,
      fields: { meterReads: 'readAt,demand' },
      include: 'installedMeter',
      sort,
      filter
    })
  ), [page, filter])

  const renderMeterReadListItem = (meterRead) => {
    const { id, installedMeter, readAt, demand } = meterRead
    return (
      <TableRow key={id}>
        <TableCell>{installedMeter.meterSerial}</TableCell>
        <TableCell>{formatLocaleDateTime(readAt)}</TableCell>
        <TableCell>{demand}</TableCell>
      </TableRow>
    )
  }

  const autoClears = { customer: { fields: ['site', 'installedMeter'], value: null }, site: { fields: ['installedMeter'], value: null } }
  return (
    <PageContainer>
      <PageHeader title={`Meter Reads`} actions={
        <ExportButton resource="meter_reads" filter={filter} sort={sort} disabled={Object.keys(filter).length === 0} />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(meterReads, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterReads.totalPages} page={page} onPageSelected={setPage} startAdornment={(
        <SearchCombo searchTextMember='meterSerial' onFilterChange={setFilter} filter={filter} autoClears={autoClears}>
          {(state) => {
            const { sitesKey, sitesFilter, metersKey, metersFilter } = siteAndMeterFilters(state)
            return <>
              <AutocompleteSearch fullWidth name='customer' searchableField="name" />
              <AutocompleteSearch fullWidth name='site' key={sitesKey} filter={sitesFilter} searchableField="displayName" />
              <AutocompleteSearch fullWidth name="installedMeter" key={metersKey} filter={metersFilter} searchableField="meterSerial" label="Meter" />
              <DateTimePicker label='Start Time' name='readAtFrom' />
              <DateTimePicker label='End Time' name='readAtTo' />
            </>
          }}
        </SearchCombo>
      )} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Meter Serial</TableCell>
            <TableCell>Read At</TableCell>
            <TableCell>Demand (kW)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meterReads.list.map(renderMeterReadListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={meterReads.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List