import useResource, {Actions, replace, ResourceState, SaveOptions} from "../useResource";
import {DispatchInstruction, HotWaterEntry, HotWaterUpload} from "types";
import useRequest from "../useRequest";

export type DispatchInstructionsResource = [
  ResourceState<DispatchInstruction>,
    Actions<DispatchInstruction> & {
    manualRequest: (data: {startDate: string, endDate: string}) => Promise<void>;
  }
]

const useDispatchInstructions = (): DispatchInstructionsResource => {
  const [state, actions] = useResource<DispatchInstruction>("dispatchInstructions")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    manualRequest: async (data = {}) => {
      await performRequest(actions.setState, "dispatchInstructions", 'manualRequest', { data })
    },
  }
  return [state, newActions]
}

export default useDispatchInstructions