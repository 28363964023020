/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import Button from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { LabeledSelect } from 'components'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { StylesObject } from "types"

const INDIVIDUAL_OPTIONS = {
  'No grouping': null,
  'Avg': {type: 'aggregate', function: 'avg'},
  'Sum': {type: 'aggregate', function: 'sum'},
  'Bucket Avg': {type: 'bucket', function: 'avg'},
  'Bucket Sum': {type: 'bucket', function: 'sum'},
  'Rolling Avg': {type: 'rolling', function: 'avg'},
  'Rolling Sum': {type: 'rolling', function: 'sum'},
}

const AGGREGATE_OPTIONS = {
  'Avg': {type: 'aggregate', function: 'avg'},
  'Sum': {type: 'aggregate', function: 'sum'},
  'Bucket Avg': {type: 'bucket', function: 'avg'},
  'Bucket Sum': {type: 'bucket', function: 'sum'},
}

const INDIVIDUAL_AGGREGATE_INTERVALS = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
}

const AGGREGATE_INTERVALS = {
  trading_period: 'Trading Period',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
}

const DEFAULT_ROLLING_BUCKET_INTERVAL = 10
const DEFAULT_AGGREGATE_INTERVAL = 'day'

const GroupOptions = ({scope, name=null, value=null, onChange=null, individualAggregateIntervals=INDIVIDUAL_AGGREGATE_INTERVALS, aggregateIntervals=AGGREGATE_INTERVALS}: any) => {
  
  const [anchorEl, setAnchorEl] = useState(null)
  const options = scope === 'individual' ? INDIVIDUAL_OPTIONS : AGGREGATE_OPTIONS
  const open = Boolean(anchorEl)
  const selected = Object.keys(options).find(key => {
    const optn = options[key]
    return optn?.type === value?.type && optn?.function === value?.function
  })
  const interval = value && value['interval']
  const intervalUnit = value?.type === 'bucket' ? 'hours' : (value?.type === 'rolling' ? 'points' : '')
  
  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, selected: string) => {
    const optn = options[selected]
    const defaultInterval = optn?.type === 'aggregate' ? DEFAULT_AGGREGATE_INTERVAL : DEFAULT_ROLLING_BUCKET_INTERVAL
    const groupingTypeChanged = (optn?.type === 'aggregate' && value?.type !== 'aggregate') || (optn?.type !== 'aggregate' && value?.type === 'aggregate')
    onChange && onChange({target: {value: optn ? {...optn, interval: groupingTypeChanged ?  defaultInterval : (value?.interval || defaultInterval)} : optn}})
    setAnchorEl(null)
  }

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const handleIntervalChange = (event) => {
    onChange({target: {value: {...value, interval: event.target.value}}})
  }

  return (
    <React.Fragment>
      <Button
        css={styles.selected}
        aria-controls="group-menu"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {selected}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="group-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleToggle}
        keepMounted
        elevation={1}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        {Object.keys(options).map((key, idx) =>
          <MenuItem
            key={key}
            selected={key === selected}
            onClick={(event) => handleMenuItemClick(event, key)}
          >
            {key}
          </MenuItem>
        )}
      </Menu>
      {(value?.type === 'bucket' || value?.type === 'rolling') &&
        <TextField
          css={styles.interval}
          label="Interval"
          value={interval}
          onChange={handleIntervalChange}
          InputProps={{
            inputProps: {type: "number", min: 1, step: 1},
            endAdornment: <InputAdornment position="end">{intervalUnit}</InputAdornment>
          }}
          />}
        {value?.type === 'aggregate' &&
          <LabeledSelect
          css={styles.filter}
          label="Interval"
          value={interval}
          onChange={handleIntervalChange}
          options={scope === 'individual' ? individualAggregateIntervals : aggregateIntervals}
          /> }
    </React.Fragment>
  )
}

const styles = {
  selected: {
    marginTop: 16,
    marginRight: 5,
  },
  interval: {
    maxWidth: 100
  }
} as StylesObject

export default GroupOptions