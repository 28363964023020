import React from 'react'
import { ErrorBanner, ControlledForm, PageContainer, Select, AutocompleteSearch, PageHeader, LabeledSwitch } from 'components'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { apiErrorStringsFor, formErrors } from 'utils'
import MenuItem from '@mui/material/MenuItem'
import { useDependency, useCommonRouteParams, useAuthorization, useResource, useDefaultSave } from 'hooks'
import { User } from "types";

const Form = () => {
  const { id, action, resourceAction, editMode } = useCommonRouteParams()
  const [users, actions] = useResource<User>("users")
  const { admin } = useAuthorization()

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!, { include: 'customer' })
      setFormData(result)
    }
  }, [id, editMode])

  const [formData, setFormData] = React.useState<Partial<User>>({receiveInAppNotifications: true, receiveEmailNotifications: true, receiveTextMessageNotifications: false})
  const save = useDefaultSave(formData, actions, { linked: ['customer'] })

  const customerField = () => {
    if (formData.role === "external") {
      return (!admin ?
        <TextField fullWidth label='Customer' name='customer.name' disabled={true} /> :
        <AutocompleteSearch fullWidth name='customer' searchableField="name" />
      )
    } else {
      return (<></>)
    }
  }

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit User - ${formData.name}` : 'Create User'} />
      <ControlledForm data={formData} errors={formErrors(users, resourceAction)} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{apiErrorStringsFor(users, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='name' />
        <TextField fullWidth name='email' disabled={action === 'edit' && !admin} />
        <TextField fullWidth name='mobilePhone' />
        <Select disabled={action === 'edit' && !admin} fullWidth name='role'>
          <MenuItem value='admin'>Admin</MenuItem>
          <MenuItem value='internal'>Internal</MenuItem>
          <MenuItem value='external'>External</MenuItem>
          <MenuItem value='csc'>CSC</MenuItem>
        </Select>
        <Select fullWidth name='exportDatetimeFormat'>
          <MenuItem value='%d/%m/%Y %H:%M:%S.%L'>DD/MM/YYYY HH:MM:SS</MenuItem>
          <MenuItem value='%d/%m/%Y %H:%M:%S.%L %z'>DD/MM/YYYY HH:MM:SS +1200</MenuItem>
          <MenuItem value='%Y-%m-%d %H:%M:%S.%L'>YYYY-MM-DD HH:MM:SS</MenuItem>
          <MenuItem value='%Y-%m-%d %H:%M:%S.%L %z'>YYYY-MM-DD HH:MM:SS +1200</MenuItem>
        </Select>
        {customerField()}
        <LabeledSwitch name='receiveInAppNotifications' />
        <LabeledSwitch name='receiveEmailNotifications' />
        <LabeledSwitch name='receiveTextMessageNotifications' />
        <br/>
        <br/>

        <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form

