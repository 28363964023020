/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, ErrorBanner, PageContainer, PageHeader, SearchCombo, DatePicker, LabeledSelect } from 'components'
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDateTime, humanize } from 'utils'
import { usePagination, useDependency, useResource, useAuthorization, useFilter } from 'hooks'
import { Link, useHistory } from 'react-router-dom'
import { ForecastRun } from "types"

const List = () => {
  const [forecastRuns, actions] = useResource<ForecastRun>("forecastRuns")

  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("forecastRuns")
  const [filter, setFilter] = useFilter(setPage, 'forecastRuns')

  useDependency(() => (
    actions.index({
      page: page,
      fields: { forecastRuns: 'runAt,firstPeriodTs,status,source' },
      sort: '-id',
      filter
    })
  ), [page, filter])

  const showForecastRun = ({ id }) => () => {
    history.push(`/forecast_runs/${id}`)
  }

  const renderForecastRunListItem = (forecastRun) => {
    const { id, runAt, status, source } = forecastRun
    return (
      <TableRow key={id} onClick={showForecastRun(forecastRun)} css={{ cursor: 'pointer' }}>
        <TableCell>{id}</TableCell>
        <TableCell>{formatLocaleDateTime(runAt)}</TableCell>
        <TableCell>{humanize(source)}</TableCell>
        <TableCell>{<span css={{ color: statusColors[status] }}>{humanize(status)}</span>}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Forecast Runs`} actions={
        (authorization.admin || authorization.internal) && <Link to={`/forecast_run_logs`}>
          <Button variant="contained" color="secondary">View Logs</Button>
        </Link>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(forecastRuns, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={forecastRuns.totalPages}
        page={page}
        onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
            <DatePicker name="runAtFrom" fullWidth />
            <DatePicker name="runAtTo" fullWidth />
            <LabeledSelect name="source" fullWidth options={{ "prophet": "Prophet", "viotas": "Viotas" }} />
            <LabeledSelect name="status" fullWidth options={{ "pending": "Pending", "completed": "Completed", "cancelled": "Cancelled", "error": "Error" }} />
          </SearchCombo>
        )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Run ID</TableCell>
            <TableCell>Run At</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecastRuns.list.map(renderForecastRunListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={forecastRuns.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default List