/** @jsxImportSource @emotion/react */
import { Accordion, AccordionSummary, Typography, AccordionDetails, Table, TableBody, TableRow, TableCell } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StylesObject } from 'types';

const AvailabilitySchedule = ({ installedMeter, expanded, onExpandCollapse }) => {
  const schedule = installedMeter?.actualAvailabilityTable?.schedule || []
  const headers = installedMeter?.actualAvailabilityTable?.headers
  return (
    <Accordion onChange={onExpandCollapse} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4">Meter Availability</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {installedMeter.actualAvailabilityTable ? <div css={{ marginBottom: 24 }}>
          <div css={{ display: 'flex', flexWrap: 'wrap' }}>
            {renderAvailabilityTable(schedule.filter(([tp]) => tp < 25), headers)}
            {renderAvailabilityTable(schedule.filter(([tp]) => tp > 24), headers)}
          </div>
          {<div css={{ fontSize: '0.8em' }}>* Times are indicative only and will be incorrect on the day of a daylight saving change</div>}
        </div> : 'Loading...'}
      </AccordionDetails>
    </Accordion>
  )
}

const renderAvailabilityTable = (rows, headers) =>
  <Table size="small" css={{ width: 'initial', marginRight: 24, marginBottom: 24, borderCollapse: 'collapse', textAlign: 'center' }}>
    <TableBody>
      <TableRow>
        <TableCell variant="head" css={styles.availCell}>Period</TableCell>
        <TableCell variant="head" css={styles.availCell}>Time</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[1]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[2]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[3]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[4]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[5]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[6]}</TableCell>
        <TableCell variant="head" css={styles.availCell}>{headers[7]}</TableCell>
      </TableRow>
      {rows.map(([tradingPeriod, monday, tuesday, wednesday, thursday, friday, saturday, sunday], i) =>
        <TableRow key={i}>
          <TableCell css={styles.availCell}>{tradingPeriod}</TableCell>
          <TableCell css={styles.availCell}>{tradingPeriod > 48 ? '' : new Date((tradingPeriod - 1) * 1800000).toISOString().substring(11, 16)}</TableCell>
          <AvailabilityCell status={monday} />
          <AvailabilityCell status={tuesday} />
          <AvailabilityCell status={wednesday} />
          <AvailabilityCell status={thursday} />
          <AvailabilityCell status={friday} />
          <AvailabilityCell status={saturday} />
          <AvailabilityCell status={sunday} />
        </TableRow>
      )}
    </TableBody>
  </Table>

const styles = {
  availCell: {
    border: 'solid 1px silver',
    width: 36,
    textAlign: 'center',
    padding: '0px 8px',
  }
} as StylesObject

const statusFgColors = {
  armed: 'green',
  disarmed: 'red',
  off: 'white',
}

const statusBgColors = {
  armed: '#c6efce',
  disarmed: '#ffc7ce',
  off: 'gray',
}

const statusTexts = {
  armed: 'Yes',
  disarmed: 'No',
  off: 'Off',
}

const AvailabilityCell = ({ status }) =>
  <TableCell css={{
    ...styles.availCell,
    backgroundColor: statusBgColors[status] || 'gray',
    color: statusFgColors[status] || 'black',
  }}>
    {statusTexts[status] || ''}
  </TableCell>

export default AvailabilitySchedule