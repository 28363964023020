import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { useAuthorization } from "hooks";

const Routes = () => {
  const authorization = useAuthorization()
  return (
    <Switch>
      <Route exact path="/meter_read_replays"                    component={Containers.MeterReadReplay.List} requires={authorization.admin}/>
      <Route exact path="/meter_read_replays/new"                component={Containers.MeterReadReplay.Form} requires={authorization.admin}/>
      <Route exact path="/meter_read_replays/:id"                component={Containers.MeterReadReplay.Show} requires={authorization.admin}/>
      <Route exact path="/meter_read_replays/:id/edit"           component={Containers.MeterReadReplay.Form} requires={authorization.admin}/>
      <Route exact path="/tripped_installed_meters/:id"              component={Containers.TrippedInstalledMeter.Show}/>
      <Route exact path="/under_frequency_events"                    component={Containers.UnderFrequencyEvent.List}/>
      <Route exact path="/under_frequency_events/:id"                component={Containers.UnderFrequencyEvent.Show}/>
      <Route exact path="/under_frequency_event_data_entries"        component={Containers.UnderFrequencyEventDataEntry.List}/>
      <Route exact path="/notifications"                    component={Containers.Notification.List}/>
      <Route exact path="/notifications/:id"                component={Containers.Notification.Show}/>
      <Route exact path="/forecast_evaluations"                    component={Containers.ForecastEvaluation.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/forecast_evaluations/:id"                component={Containers.ForecastEvaluation.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/forecast_evaluation_runs"                    component={Containers.ForecastEvaluationRun.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/forecast_evaluation_runs/:id"                component={Containers.ForecastEvaluationRun.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/invoices"                    component={Containers.Invoice.List} requires={authorization.admin}/>
      <Route exact path="/invoices/:id"                component={Containers.Invoice.Show} requires={authorization.admin}/>
      <Route exact path="/invoice_runs"                    component={Containers.InvoiceRun.List} requires={authorization.admin}/>
      <Route exact path="/invoice_runs/new"                component={Containers.InvoiceRun.Form} requires={authorization.admin}/>
      <Route exact path="/invoice_runs/:id/override"       component={Containers.InvoiceRun.Override} requires={authorization.admin}/>
      <Route exact path="/invoice_runs/:id"                component={Containers.InvoiceRun.Show} requires={authorization.admin}/>
      <Route exact path="/settlements"                    component={Containers.Settlement.List} requires={authorization.admin || authorization.external}/>
      <Route exact path="/settlements/:id"                component={Containers.Settlement.Show} requires={authorization.admin || authorization.external}/>
      <Route exact path="/settlement_runs"                    component={Containers.SettlementRun.List} requires={authorization.admin}/>
      <Route exact path="/settlement_runs/:id"                component={Containers.SettlementRun.Show} requires={authorization.admin}/>
      <Route exact path="/bulk_offers"                    component={Containers.BulkOffer.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_offers/:id"                component={Containers.BulkOffer.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_offer_batches"                    component={Containers.BulkOfferBatch.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_offer_batches/:id"                component={Containers.BulkOfferBatch.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_reserve_sources"                    component={Containers.BulkReserveSource.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_reserve_sources/new"                component={Containers.BulkReserveSource.Form} requires={authorization.admin}/>
      <Route exact path="/bulk_reserve_sources/:id"                component={Containers.BulkReserveSource.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/bulk_reserve_sources/:id/edit"           component={Containers.BulkReserveSource.Form} requires={authorization.admin}/>
      <Route exact path="/offer_run_logs"                    component={Containers.OfferRunLog.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/offer_run_logs/:id"                component={Containers.OfferRunLog.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/adhoc_availabilities"                    component={Containers.AdhocAvailability.List}/>
      <Route exact path="/adhoc_availabilities/new"                component={Containers.AdhocAvailability.Form}/>
      <Route exact path="/adhoc_availabilities/:id"                component={Containers.AdhocAvailability.Show}/>
      <Route exact path="/adhoc_availabilities/:id/edit"           component={Containers.AdhocAvailability.Form}/>
      <Route exact path="/availability_uploads"                    component={Containers.AvailabilityUpload.List}/>
      <Route exact path="/availability_uploads/new"                component={Containers.AvailabilityUpload.Form}/>
      <Route exact path="/availability_uploads/:id"                component={Containers.AvailabilityUpload.Show}/>
      <Route exact path="/forecasts"                    component={Containers.Forecast.List} requires={(authorization.admin || authorization.internal || authorization.external)}/>
      <Route exact path="/forecast_runs"                    component={Containers.ForecastRun.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/forecast_runs/:id"                component={Containers.ForecastRun.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/config_options"                    component={Containers.ConfigOption.List} requires={authorization.admin}/>
      <Route exact path="/config_options/new"                component={Containers.ConfigOption.Form} requires={authorization.admin}/>
      <Route exact path="/config_options/:id"                component={Containers.ConfigOption.Show} requires={authorization.admin}/>
      <Route exact path="/config_options/:id/edit"           component={Containers.ConfigOption.Form} requires={authorization.admin}/>
      <Route exact path="/meter_test_result_uploads"                    component={Containers.MeterTestResultUpload.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/meter_test_result_uploads/:id"                component={Containers.MeterTestResultUpload.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/forecast_run_logs"                    component={Containers.ForecastRunLog.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/forecast_run_logs/:id"                component={Containers.ForecastRunLog.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/meter_reads"                    component={Containers.MeterRead.List} requires={authorization.admin || authorization.external || authorization.internal}/>
      <Route exact path="/meter_read_uploads"                    component={Containers.MeterReadUpload.List} requires={authorization.admin}/>
      <Route exact path="/meter_read_uploads/:id"                component={Containers.MeterReadUpload.Show} requires={authorization.admin}/>
      <Route exact path="/dashboard/:tab(offer|historic|forecast|price|settlement|hot_water|ufe)" component={Containers.Dashboard.Show}/>
      <Route exact path="/derived_dispatches"                    component={Containers.DerivedDispatch.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/derived_dispatches/:id"                component={Containers.DerivedDispatch.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/dispatch_instructions"                    component={Containers.DispatchInstruction.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/dispatch_instructions/:id"                component={Containers.DispatchInstruction.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/prices"                    component={Containers.Price.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/prices/:id"                component={Containers.Price.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/meter_details"                    component={Containers.MeterDetail.List}/>
      <Route exact path="/meter_details/new"                component={Containers.MeterDetail.Form}/>
      <Route exact path="/meter_details/:id"                component={Containers.MeterDetail.Show}/>
      <Route exact path="/meter_details/:id/edit"           component={Containers.MeterDetail.Form}/>
      <Route exact path="/meter_details/:id/new"           component={Containers.MeterDetail.Form}/>
      <Route exact path="/installed_meters"                    component={Containers.InstalledMeter.List}/>
      <Route exact path="/installed_meters/new"                component={Containers.InstalledMeter.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/installed_meters/:id"                component={Containers.InstalledMeter.Show}/>
      <Route exact path="/installed_meters/:id/edit"           component={Containers.InstalledMeter.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/installed_meters/:id/availability"           component={Containers.InstalledMeter.AvailabilityGrid}/>
      <Route exact path="/installed_meters/:installedMeterId/meter_read_uploads/new" component={Containers.MeterReadUpload.Form} requires={authorization.admin}/>
      <Route exact path="/installed_meters/:installedMeterId/meter_test_result_uploads/new" component={Containers.MeterTestResultUpload.Form} requires={authorization.admin}/>
      <Route exact path="/sites"                    component={Containers.Site.List}/>
      <Route exact path="/sites/new"                component={Containers.Site.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/sites/:id"                component={Containers.Site.Show}/>
      <Route exact path="/sites/:id/edit"           component={Containers.Site.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/customers"                    component={Containers.Customer.List} requires={authorization.admin || authorization.internal || authorization.csc}/>
      <Route exact path="/customers/new"                component={Containers.Customer.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/customers/:id"                component={Containers.Customer.Show} requires={authorization.admin || authorization.internal || authorization.csc}/>
      <Route exact path="/customers/:id/edit"           component={Containers.Customer.Form} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/offers"                    component={Containers.Offer.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/offers/:id"                component={Containers.Offer.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/offer_runs"                    component={Containers.OfferRun.List} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/offer_runs/:id"                component={Containers.OfferRun.Show} requires={(authorization.admin || authorization.internal)}/>
      <Route exact path="/hot_water_uploads"                    component={Containers.HotWaterUpload.List} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/hot_water_uploads/:id"                component={Containers.HotWaterUpload.Show} requires={authorization.admin || authorization.internal}/>
      <Route exact path="/service_accounts"                    component={Containers.ServiceAccount.List} requires={authorization.admin}/>
      <Route exact path="/service_accounts/new"                component={Containers.ServiceAccount.Form} requires={authorization.admin}/>
      <Route exact path="/service_accounts/:id"                component={Containers.ServiceAccount.Show} requires={authorization.admin}/>
      <Route exact path="/service_accounts/:id/edit"           component={Containers.ServiceAccount.Form} requires={authorization.admin}/>
      <Route exact path="/profile"                    component={Containers.User.Profile}/>
      <Route exact path="/users"                      component={Containers.User.List} requires={authorization.admin || authorization.csc}/>
      <Route exact path="/users/new"                  component={Containers.User.Form} requires={authorization.admin}/>
      <Route exact path="/users/:id"                  component={Containers.User.Show} requires={authorization.admin || authorization.csc}/>
      <Route exact path="/users/:id/edit"             component={Containers.User.Form} />
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={authorization.admin}/>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/"                           component={() => <Redirect to={defaultPageForRole(authorization)}/>}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs/:name"              component={Containers.Docs.ExternalApiDocs} requires={authorization.admin}/>
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} requires={authorization.admin}/>
      <Route exact path="/microsoft/callback"         component={Containers.Auth.MicrosoftCallbackSink} />
      <Route component={Containers.Status.NotFound} />
    </Switch>
  )
}

const defaultPageForRole = (authorization) => {
  if (authorization.internalOrAdmin) {
    return "/dashboard/offer"
  } else if (authorization.csc) {
    return "/customers"
  } else {
    return "/dashboard/historic"
  }
}

export default Routes