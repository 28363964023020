import React, { useEffect } from 'react'
import qs from 'qs'
import { MicrosoftService } from 'services'
import { SessionStatus } from '../../constants'
import { useHistory } from 'react-router-dom'
import { useTokens } from "contexts/TokensContext";
import { useSnackbar } from 'contexts/SnackbarContext'

const MicrosoftCallbackSink = (props) => {
  const [tokens, tokenActions] = useTokens();
  const snackbar = useSnackbar()

  const history = useHistory()
  const signIn = async (params) => {
    try {
      await tokenActions.create(params)
      // if successful, this will trigger a un-mount/mount of this component under Authenticated Routes
    } catch (err) {
      snackbar.show('Signed in failed')
      history.push('/tokens/sign_in?redirect=false')
    }
  }

  useEffect(() => {
    const init = async () => {
      if (tokens.loginState !== SessionStatus.AUTHENTICATED) {
        let query = qs.parse(window.location.search.substring(1))
        let { state } = query
        let nonce = global.localStorage.microsoftOAuthNonce

        if (nonce !== state) {
          snackbar.show('This OAuth redirect state does not match our state.')
          history.push('/tokens/sign_in?redirect=false')
        }

        let params = { ...MicrosoftService.authHashFromReply(query), auth_type: 'microsoft' }
        await signIn(params)
      }
      else {
        snackbar.show('Signed in succesfully')
        history.push('/')
      }
    }
    init()
  }, [])// eslint-disable-line


  return (
    <div>
      <link rel="stylesheet" href="/index.css" />
      <link rel="stylesheet" href="/fonts.css" />
      <link rel="stylesheet" href="/loader.css" />
      <div id='loader'>
        <div className="lds-circle">
          <div></div>
        </div>
        <span style={{ fontWeight: 'bold', color: 'white' }}>Loading...</span>
      </div>
    </div>
  )

}

export default MicrosoftCallbackSink