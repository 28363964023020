import useResource, {Actions, ResourceState} from "../useResource";
import {Invoice, InvoiceRun} from "types";
import useRequest from "../useRequest";

export type InvoicesResource = [
  ResourceState<Invoice>,
    Actions<Invoice> & {
    sendBCTI: ({id: string}) => Promise<void>;
  }
]

const useInvoices = (): InvoicesResource => {
  const [state, actions] = useResource<Invoice>("invoices")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    sendBCTI: async (data) => {
      await performRequest(actions.setState, "invoices", 'sendBCTI', data)
    },
  }
  return [state, newActions]
}

export default useInvoices