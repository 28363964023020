/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, AutocompleteSearch, LabeledSelect, PageHeader, ExportButton } from 'components'
import {
  IconButton,
  Typography,
  Button,
  TextField,
  Tooltip,
  Switch,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InstalledMeterIcon from '@mui/icons-material/Speed'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, siteAndMeterFilters } from 'utils'
import { usePagination, useDependency, useResource, useFilter, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { InstalledMeter, StylesObject } from "types"

const List = ({ siteId }) => {
  const [installedMeters, actions] = useResource<InstalledMeter>("installedMeters")

  const snackbar = useSnackbar()
  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("installedMeters")
  const [filter, setFilter] = useFilter(setPage, 'installedMeters')

  const sort = 'name,meter_serial'
  const [, , reloadInstalledMeters] = useDependency(() => (
    actions.index({
      page: page,
      fields: { installedMeters: 'name,meterSerial,gatewaySerial,active,deviceStatus' },
      filter: { siteId, ...filter },
      sort: sort,
    })
  ), [page, filter])

  const showInstalledMeter = ({ id }) => () => {
    history.push(`/installed_meters/${id}`)
  }

  const editInstalledMeter = ({ id }) => () => {
    history.push(`/installed_meters/${id}/edit`)
  }

  const deleteInstalledMeter = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadInstalledMeters)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderInstalledMeterListItem = (installedMeter) => {
    const { id, name, meterSerial, gatewaySerial, active, deviceStatus } = installedMeter
    return (
      <TableRow key={id}>
        <TableCell>
          <Tooltip title={active ? 'Active' : 'Inactive'}>
            <InstalledMeterIcon css={{ color: (active ? 'green' : 'red') }} />
          </Tooltip>
        </TableCell>
        <TableCell css={styles.meter} onClick={showInstalledMeter(installedMeter)}>{name}</TableCell>
        <TableCell css={styles.meter} onClick={showInstalledMeter(installedMeter)}>{meterSerial}</TableCell>
        <TableCell css={styles.meter} onClick={showInstalledMeter(installedMeter)}>{gatewaySerial}</TableCell>
        <TableCell css={styles.meter} onClick={showInstalledMeter(installedMeter)}>{deviceStatus}</TableCell>
        {authorization.internalOrAdmin &&
          <TableCell css={styles.actionsIcons}>
            <IconButton onClick={editInstalledMeter(installedMeter)} size="large"><EditIcon /></IconButton>
            <DeleteConfirmationDialog onConfirm={deleteInstalledMeter(installedMeter)} entityName="meter" />
          </TableCell>}
      </TableRow>
    )
  }

  const autoClears = { customer: { fields: ['site'], value: null } }
  return (
    <PageContainer>
      <PageHeader title="Meters" actions={
        <>
          <ExportButton resource="installed_meters" filter={filter} sort={sort} />
          {siteId && authorization.internalOrAdmin && <Button variant="contained" onClick={() => history.push(`/installed_meters/new?siteId=${siteId}`)}>New</Button>}
        </>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(installedMeters, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={installedMeters.totalPages} page={page} onPageSelected={setPage} startAdornment={
        !siteId && <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'} autoClears={autoClears}>
          {(state) => {
            const { sitesKey, sitesFilter } = siteAndMeterFilters(state)
            return <>
              {(authorization.internalOrAdmin || authorization.csc) && <AutocompleteSearch fullWidth name='customer' searchableField="name" />}
              <AutocompleteSearch fullWidth key={sitesKey} filter={sitesFilter} name='site' searchableField="displayName" />
              <TextField fullWidth name='meterSerial' />
              <TextField fullWidth name='gatewaySerial' />
              <LabeledSelect
                name="deviceStatus"
                label="Status"
                fullWidth
                options={{ unknown: 'Unknown', armed: 'Armed', disarmed: 'Disarmed', tripped: 'Tripped' }}
              />
              <FormControlLabel label="Active only" control={
                <Switch
                  name='active'
                  checked={!!filter.active}
                  onChange={() => setFilter({ ...filter, active: filter.active ? null : true })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } />
            </>
          }}
        </SearchCombo>
      } />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Meter</TableCell>
              <TableCell>Serial</TableCell>
              <TableCell>Gateway</TableCell>
              <TableCell>Status</TableCell>
              {authorization.internalOrAdmin &&
                <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {installedMeters.list.map(renderInstalledMeterListItem)}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination totalPages={installedMeters.totalPages} page={page} onPageSelected={setPage} />
      {!siteId && authorization.internalOrAdmin && <FABFixed color='secondary' onClick={() => history.push('/installed_meters/new')}>
        <AddIcon />
      </FABFixed>}
    </PageContainer>
  )
}

const styles = {
  meter: {
    cursor: 'pointer',
  },
  actionsIcons: {
    minWidth: '130px',
  },
} as StylesObject

export default List