/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { AvailabilityUpload } from "types"
import { humanize } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [availabilityUploads, actions] = useResource<AvailabilityUpload>("availabilityUploads")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const availabilityUpload: Partial<AvailabilityUpload> = availabilityUploads.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title="Availability Upload" />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head' css={{ width: 130 }}>Status</TableCell>
            <TableCell css={{ color: statusColors[availabilityUpload?.status || 'unknown'] }}>{String(humanize(availabilityUpload.status || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Upload Type</TableCell>
            <TableCell>{String(humanize(availabilityUpload.uploadType || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Filename</TableCell>
            <TableCell>{String(availabilityUpload.filename || '')}</TableCell>
          </TableRow>
          {availabilityUpload?.messages && <TableRow>
            <TableCell variant='head'>Messages</TableCell>
            <TableCell><ul css={{ padding: 0 }}>{availabilityUpload.messages.map(msg => <li>{msg}</li>)}</ul></TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

const statusColors = {
  unknown: 'black',
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default Show