/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDependency, useCommonRouteParams, useResource, useAuthorization, useQueryString } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { ConfirmationDialog, ExportButton, PageContainer, PageHeader } from 'components'
import { InstalledMeter } from "types"
import { List as MeterDetailsList } from "containers/meter_details"
import { List as MeterTestResultsUploadList } from "containers/meter_test_result_uploads"
import { formatLocaleDate } from 'utils'
import AvailabilitySchedule from './AvailabilitySchedule'
import { useSnackbar } from 'contexts/SnackbarContext'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [installedMeters, actions] = useResource<InstalledMeter>("installedMeters", undefined, ({ resourceName, performRequest, setState, replace }) => ({
    syncStatus: async (item, options = {}) => {
      const { data: selected } = await performRequest(setState, resourceName, 'syncStatus', { ...item, options })
      setState(prevState => ({ ...prevState, selected, list: replace(prevState.list, selected) }))
      return selected
    },
  }))

  const [queryString, setQueryString] = useQueryString()
  const [includes, setIncludes] = useState(queryString.availability === 'open' ? 'site,site.customer,actualAvailabilityTable,activeMeterDetail' : 'site,site.customer,activeMeterDetail')
  useDependency(async () => {
    if (id) {
      actions.show(id, { include: includes })
    }
  }, [id, includes])

  const installedMeter: Partial<InstalledMeter> = installedMeters.selected ?? {}
  const authorization = useAuthorization()
  const snackbar = useSnackbar()

  const [syncStatusDialogOpen, setSyncStatusDialogOpen] = useState(false)
  const [hideSyncStatusButton, setHideSyncStatusButton] = useState(false)

  const handleAvailabilityExpandCollapse = () => {
    setIncludes(incl => (incl.includes('actualAvailabilityTable') ? incl : incl + ',actualAvailabilityTable'))
    setQueryString(prev => ({ availability: prev.availability === 'open' ? undefined : 'open' }))
  }

  const availabilityButton = () => {
    if (installedMeter?.activeMeterDetail?.forecastMethod !== 'viotas')
      return (
        <Link to={`/installed_meters/${installedMeter.id}/availability`} css={{ marginLeft: 8 }}>
          <Button variant="contained" color="secondary">Edit Availability</Button>
        </Link>
      )
  }

  const availabilitySchedule = () => {
    if (installedMeter?.activeMeterDetail?.forecastMethod !== 'viotas')
      return (
        <div>
          <AvailabilitySchedule installedMeter={installedMeter} expanded={queryString.availability === 'open'} onExpandCollapse={handleAvailabilityExpandCollapse} />
        </div>
      )
  }

  const syncStatus = async () => {
    snackbar.show('Syncing may take up to 5 minutes - you will need to refresh the page', 10000)
    try {
      setSyncStatusDialogOpen(false)
      setHideSyncStatusButton(true)
      await actions.syncStatus(installedMeter, { include: includes })
    } catch (err) {
      setHideSyncStatusButton(false)
      console.error(err)
    }
  }

  return (
    <PageContainer>
      <PageHeader title={`Meter - ${installedMeter.name}`} actions={
        <div>
          {authorization.admin && <Link to={`/installed_meters/${installedMeter.id}/meter_read_uploads/new`}>
            <Button variant="contained" color="secondary">Upload Meter Reads</Button>
          </Link>}
          {availabilityButton()}
        </div>
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 130 }} variant='head'>Actions</TableCell>
            <TableCell>
              {authorization.internalOrAdmin && <Link to={`/installed_meters/${installedMeter.id}/edit`}>
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </Link>}
            </TableCell>
          </TableRow>
          {authorization.admin && <TableRow>
            <TableCell variant='head'>IoT Telemetry</TableCell>
            <TableCell>
              <ExportButton
                variant='text'
                css={{ marginBottom: 0, paddingLeft: 0 }}
                resource="iot_central_telemetries"
                params={{ meterSerial: installedMeter.meterSerial }}
              />
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell variant='head'>Meter Serial</TableCell>
            <TableCell>{String(installedMeter.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <div css={{ flex: '1' }}>{String(installedMeter.deviceStatus || '')}</div>
                {!hideSyncStatusButton && installedMeter?.activeMeterDetail?.forecastMethod !== 'viotas' &&
                  <Button variant="contained" color="secondary" css={{ margin: 0 }} onClick={() => setSyncStatusDialogOpen(true)}>Sync</Button>
                }
                <ConfirmationDialog open={syncStatusDialogOpen}
                  title="Sync Device Status"
                  onConfirm={syncStatus}
                  onCancel={() => setSyncStatusDialogOpen(false)}>
                  This will attempt to set the device status according to the availability schedule.
                </ConfirmationDialog>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Gateway Serial</TableCell>
            <TableCell>{String(installedMeter.gatewaySerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>WEL Asset Number</TableCell>
            <TableCell>{String(installedMeter.welAssetNumber || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>SIM Card Number</TableCell>
            <TableCell>{String(installedMeter.simCardNumber || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Start Date</TableCell>
            <TableCell>{installedMeter.startDate ? formatLocaleDate(installedMeter.startDate) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>End Date</TableCell>
            <TableCell>{installedMeter.endDate ? formatLocaleDate(installedMeter.endDate) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Customer</TableCell>
            <TableCell>{String(installedMeter?.site?.customer?.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>ICP Number</TableCell>
            <TableCell>{String(installedMeter?.site?.icpNumber || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Address</TableCell>
            <TableCell>
              <pre css={{ fontFamily: 'inherit', fontSize: 'inherit', margin: 0 }}>
                {installedMeter?.site?.address || ''}
              </pre>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Can Be Switched Off</TableCell>
            <TableCell>{String(installedMeter.canBeSwitchedOff ? 'Yes' : 'No')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Forecast Requires Review</TableCell>
            <TableCell>{String(installedMeter.forecastRequiresReview ? 'Yes' : 'No')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Exclude From Offer</TableCell>
            <TableCell>{String(installedMeter.excludeFromOffer ? 'Yes' : 'No')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <div>
        <MeterDetailsList installedMeterId={id} />
      </div>
      <br />
      <div>
        {!authorization.external && !authorization.csc &&
          <MeterTestResultsUploadList installedMeterId={id} />
        }
      </div>
      <br />
      {availabilitySchedule()}
    </PageContainer >
  )
}

export default Show