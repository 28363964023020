import React from 'react'
export function createActionNameList(namespace){
  const actionTypes = ['', 'REQUEST', 'SUCCESS', 'FAILURE'];
  return (...constants) => {
    return constants.reduce((acc, constant) => {
      const constantText = `${namespace}.${constant}`
      actionTypes.forEach((type) => {
        acc[[constant, type].filter(x => x).join('_').toUpperCase()] = [constantText, type].filter(x => x).join('.')
      })
      return acc;
    }, {});
  }
}

export function createNamespacedConstants(namespace){
  return (...constants) => {
    return constants.reduce((acc, constant) => {
      const constantText          = `${namespace}.${constant}`
      acc[constant.toUpperCase()] = constantText
      return acc;
    }, {});
  }
}

export function humanize(camelized){
  return `${camelized}`.split(/(?=[A-Z])|_/).map(s => s[0]?.toUpperCase() + s.slice(1)).join(" ");
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleize(string){
  if (!string) {
    return string;
  }
  return humanize(string).split(' ').map(word => word.length > 0 ? capitalize(word) : '').join(' ')
}

export function snakeCased(string){
  return humanize(string).replace(/\s+/g,"_").toLowerCase();
}

export function camelized(string, {lower=false}={}){
  let camelized = snakeCased(string).replace(/_\w/g, str => str.slice(1).toUpperCase())
  return lower ? camelized : capitalize(camelized)
}

export function formatLocaleNumeric(numeric, {decimalPlaces=2} ={}){
  return parseFloat(numeric).toLocaleString(undefined, { maximumFractionDigits: decimalPlaces })
}

export function createEnum(...constants){
  let i = 0;
  return constants.reduce((acc, constant) => {
    acc.values[constant] = i;
    acc.names[i] = constant
    i += 1
    return acc;
  }, {names: [], values: {}});
}

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant.toUpperCase()] = constant;
    return acc;
  }, {});
}

export function uuid() {
  // @ts-ignore
  let bits = (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(16))
  bits[8] = (bits[8] & 0x3F) | 0x80
  bits[6] = (bits[6] & 0x0F) | 0x40
  let i = 0
  // @ts-ignore
  return ([1e7]+-1e3+-1e3+-1e3+-1e11).replace(/[018][018]/g, d => {
    let x = bits[i++].toString(16)
    if(x.length === 1) {
      x = '0' + x
    }
    return x
  });
}

export function multiLine(string) {
  const lines = string.split('\n')
  return lines.reduce((accumulator,line,idx) => [...accumulator, ...(idx === 0 ? [] : [<br key={idx}/>]), line],[]);
}