import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#003865', contrastText: '#FFFFFF' },
    secondary: { main: '#009FDF', contrastText: '#FFFFFF' },
    mode: 'light'
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, arial',
    h1: {
      fontFamily: "Lato, Helvetica, arial"
    },
    h2: {
      fontFamily: "Lato, Helvetica, arial"
    },
    h3: {
      fontFamily: "Lato, Helvetica, arial"
    },
    h4: {
      fontFamily: "Lato, Helvetica, arial"
    },
    h5: {
      fontFamily: "Lato, Helvetica, arial"
    },
    h6: {
      fontFamily: "Lato, Helvetica, arial"
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .link-small': {
            margin: 5,
            textAlign: 'center',
            display: 'block',
            color: '#666565',
            fontSize: '0.8rem',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          maxWidth: 1000,
          margin: '0 auto',
          width: '100%',
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          '&+.MuiButton-root': {
            marginLeft: '10px'
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            marginBottom: '0px'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  }
})