/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import {
  DatePicker,
  ControlledForm,
  ErrorBanner,
  PageContainer,
  PageHeader,
  PromiseButton,
  LabeledSwitch
} from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { InvoiceRun } from "types"

const Form = () => {
  const {editMode, id, resourceAction} = useCommonRouteParams()
  const [invoiceRuns, actions] = useResource<InvoiceRun>("invoiceRuns")
  const [formData, setFormData] = useState<Partial<InvoiceRun>>({})
  const handleSave = useDefaultSave(formData, actions)

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!)
      setFormData(result)
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title="Ad-hoc Invoice Run" />
      <ControlledForm data={formData} errors={formErrors(invoiceRuns, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(invoiceRuns, 'create', 'update')}</ErrorBanner>
        <DatePicker fullWidth name='dateFrom'/>
        <DatePicker fullWidth name='dateTo'/>
        <LabeledSwitch name='deliverBctis' label="Deliver BCTIs" />
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit' disabled={!formData.dateFrom || !formData.dateTo}>Schedule Invoice Run</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form