/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton, Card, CardContent, CardHeader, Button, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CopyIcon from '@mui/icons-material/ContentCopy'
import { DeleteConfirmationDialog, PageContainer, PageHeader } from 'components'
import { ServiceAccount, ServiceAccountSecret } from "types"
import {titleize} from "utils";

const Show = () => {
  const { id: serviceAccountId } = useCommonRouteParams()
  const [serviceAccounts, actions] = useResource<ServiceAccount>("serviceAccounts")
  const [secrets, secretsActions] = useResource<ServiceAccountSecret>("serviceAccountSecrets")

  useDependency(async () => {
    if (serviceAccountId) {
      return Promise.all([
        actions.show(serviceAccountId),
        secretsActions.index({ params: { serviceAccountId } }),
      ])
    }
  }, [serviceAccountId])

  const serviceAccount: Partial<ServiceAccount> = serviceAccounts.selected ?? {}

  const createSecret = async () => {
    await secretsActions.create({ serviceAccountId })
  }

  const destroySecret = (id) => async () => {
    await secretsActions.destroy({ serviceAccountId, id })
  }

  const renderSecret = ({ id, clientSecret }: ServiceAccountSecret) =>
    <ListItem key={id}>
      <ListItemText primary={clientSecret} />
      <ListItemSecondaryAction>
        {clientSecret?.endsWith('****') === false && <IconButton onClick={() => navigator.clipboard.writeText(clientSecret)} size="large"><CopyIcon /></IconButton>}
        <DeleteConfirmationDialog onConfirm={destroySecret(id)} entityName="secret" />
      </ListItemSecondaryAction>
    </ListItem>

  return (
    <PageContainer>
      <PageHeader title={`ServiceAccount - ${serviceAccount.name}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 170 }} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/service_accounts/${serviceAccount.id}/edit`}>
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Client ID</TableCell>
            <TableCell>{String(serviceAccount.clientId || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(serviceAccount.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Role</TableCell>
            <TableCell>{titleize(serviceAccount.role || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Basic Auth Enabled?</TableCell>
            <TableCell>{serviceAccount.basicAuthEnabled ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} css={{ padding: 0, paddingTop: 16, borderBottom: 'initial' }}>
              <Card>
                <CardHeader title="Secrets" />
                <CardContent css={{ paddingTop: 0 }}>
                  <List css={{ '& .MuiListItem-container:hover': { background: '#EEE' } }}>
                    {secrets.list.map(renderSecret)}
                  </List>
                  <Button variant="contained" onClick={createSecret}>Add Secret</Button>
                </CardContent>
              </Card>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show