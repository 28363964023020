import React, { useMemo, useState } from "react";
import useRequest from "./useRequest";

export type Resource<ResourceT, AdditionalResourceState> = [
  ResourceState<ResourceT> & AdditionalResourceState,
  Actions<ResourceT, AdditionalResourceState>
]

export type ResourceState<ResourceT> = {
  requests: Record<ResourceActionName, RequestProxy[]>
  errors: Record<ResourceActionName, ResourceError | null>;
  selected?: ResourceT,
  list: ResourceT[],
  page: number,
  totalPages: number,
  count: number
}

export type Actions<ResourceT, AdditionalResourceState extends {} = {}> = {
  index: (params: IndexRequestParams) => Promise<ResourceT[]>;
  show: (id: string, params?: ShowParams) => Promise<ResourceT>;
  create: (item: ResourceT, options?: SaveOptions) => Promise<ResourceT>;
  update: (item: ResourceT, options?: SaveOptions) => Promise<ResourceT>;
  destroy: (item: Partial<ResourceT>) => Promise<void>;
  setState: SetState<ResourceState<ResourceT> & AdditionalResourceState>;
  [otherOptions: string]: any;
}

// Usage example:
// const [state, actions] = useResource('books')
//
// Optional additional initial state argument:
// const [books, actions] = useResource('books', {favouriteBooks: []})
// console.log(books.favouriteBooks) // => []
const useResource = <ResourceT = Record<string, any>, AdditionalResourceState extends {} = {}>(
  resourceName: string,
  additionalInitialState?: AdditionalResourceState,
  buildCustomActions?: Function,
): Resource<ResourceT, AdditionalResourceState> => {
  type State = ResourceState<ResourceT> & AdditionalResourceState

  const initialStateDefault = {
    requests: {},
    errors: {},
    selected: undefined,
    list: [],
    page: 1,
    totalPages: 1,
    count: 0
  }

  const initialState = { ...additionalInitialState, ...initialStateDefault } as unknown as State

  const [state, setState] = useState<State>(initialState)
  const performRequest = useRequest<State>()

  const actions = useMemo(() => ({
    setState,
    index: async ({ page, params = {}, fields = null, include = null, filter = null, order = null, pageSize = undefined, ...opts }: IndexRequestParams = {}) => {
      const { data: list, meta: { totalPages } } = await performRequest(setState, resourceName, 'index',
        {
          ...params,
          options: {
            page: { number: page, size: pageSize },
            ...(order && { order }),
            ...(filter && { filter }),
            ...(fields && { fields }),
            ...(include && { include }),
            ...opts
          }
        }
      )

      setState(prevState => ({ ...prevState, list, page, totalPages }))
      return list
    },
    show: async (id, { fields = null, include = null }: { fields?: string | null, include?: string | null } = {}) => {
      const { data: selected } = await performRequest(setState, resourceName, 'show', { id, options: { ...(fields && { fields }), ...(include && { include }) } })
      setState(prevState => ({ ...prevState, selected, list: replace(prevState.list, selected) }))
      return selected
    },
    create: async (item, options = {}) => {
      const { data: selected } = await performRequest(setState, resourceName, 'create', { ...item, options })
      setState(prevState => ({ ...prevState, selected, list: [...prevState.list, selected] }))
      return selected
    },
    update: async (item, options = {}) => {
      const { data: selected } = await performRequest(setState, resourceName, 'update', { ...item, options })
      setState(prevState => ({ ...prevState, selected, list: replace(prevState.list, selected) }))
      return selected
    },
    destroy: async (record) => {
      await performRequest(setState, resourceName, 'destroy', record)
      setState(prevState => ({ ...prevState, list: remove(prevState.list, record) }))
    },
    ...(buildCustomActions ? buildCustomActions({ resourceName, performRequest, setState, replace }) : {})
  }), [resourceName, performRequest, buildCustomActions])

  return [state, actions]
}

export type ResourceError = {
  code: string;
  message: string;
  meta: { [fieldName: string]: string[] } | null;
  status: number;
  title: string;
}

type ResourceActionName = string

export type RequestProxy = {
  id: string;
}

export type SetState<S> = React.Dispatch<React.SetStateAction<S>>

export type IndexRequestParams = {
  page?: number;
  params?: Record<string, any>;
  fields?: Record<string, string> | null;
  include?: string | null;
  filter?: Record<string, any> | null;
  order?: string | null;
  pageSize?: number;
  [other: string]: any;
}

export type SaveOptions = {
  fields?: Record<string, string> | null;
  include?: string | null;
}

export type ShowParams = {
  fields?: string | null;
  include?: string | null;
}

export const replace = (collection, item) => {
  return collection.map(i => i.id === item.id ? { ...i, ...item } : i)
}

export const remove = (collection, item) => {
  return collection.filter(i => i.id !== item.id)
}

export default useResource