/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import 'swagger-ui/dist/swagger-ui.css'
import {useRouteMatch} from "react-router-dom";

const ExternalApiDocs = () => {
  const match = useRouteMatch()
  useEffect(() => {
    const url = `${window.env.API_BASE}/docs/${match.params.name}`

    let swaggerUi
    import('swagger-ui').then(({default: SwaggerUI}) => {
      swaggerUi = SwaggerUI({
        dom_id: '#api-docs',
        url,
        responseInterceptor: (response) => {
          if (response.ok && response.url.endsWith("/api/tokens")) {
            const token = response?.obj?.data?.attributes?.auth;
            if (token) {
              swaggerUi.preauthorizeApiKey('AuthToken', response?.obj?.data?.attributes?.auth)
              // This part is a bit hacky: it relies on the implementation of swagger-ui to write the token
              // in the format that swagger-ui is using to load the api keys from localStorage after page reload
              // Unfortunately, 'persistAuthorization' setting only works for keys set from the UI
              const persistedKeysRaw = localStorage.getItem('authorized');
              const persistedKeys = persistedKeysRaw ? JSON.parse(persistedKeysRaw) : {}
              const newKey = {"AuthToken": {"name": "AuthToken","schema": {"type": "apiKey","name": "Authorization", "in": "header"}, "value": token}}
              localStorage.setItem('authorized', JSON.stringify({...persistedKeys, ...newKey}))
            }
          }
          return response;
        },
        deepLinking: true,
        persistAuthorization: true,
        sorter: "alpha",
        jsonEditor: true,
        defaultModelRendering: 'schema',
        showRequestHeaders: true,
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.presets.base,
          SwaggerUI.presets.lowlight
        ],
        plugins: [
          SwaggerUI.plugins.DownloadUrl
        ],
      })
    })
  }, [match.params.name])

  return (
    <div id='api-docs' css={{background: 'white'}} />
  )
}

export default ExternalApiDocs