/** @jsxImportSource @emotion/react */
import React from 'react'
import StatusPage from './StatusPage'
import {useHistory} from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const ConformationResent = () => {
  const history = useHistory()

  return (
    <StatusPage>
      <Typography variant='h6'>Confirmation Resent</Typography>
      <br/>
      <Typography variant='body2'>We have resent your verification email.</Typography>
      <br/>
      <Typography variant='body1'>
        Please follow the instruction on your email to verify your account to continue.
      </Typography>
      <br/>
      <Button fullWidth variant='contained' color="primary"
              onClick={() => history.push('/tokens/resend_confirmation')}>Resend Confirmation</Button>&emsp;
      <Button fullWidth variant='contained' onClick={() => history.push('/')} css={{ marginLeft: 0 }}>
        Go Back!
      </Button>
    </StatusPage>
  )
}

export default ConformationResent