import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { isProduction } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/apidocs/:name"              component={Containers.Docs.ExternalApiDocs} />
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/microsoft/callback"         component={Containers.Auth.MicrosoftCallbackSink} />
      {
        !isProduction() &&
        <Route exact path="/apidocs"                  component={Containers.Docs.ApiDocs} />
      }
      <Route component={() =>  <Redirect to={"/tokens/sign_in"}/>}/>
    </Switch>
  )
}

export default Routes
