/** @jsxImportSource @emotion/react */
import { Backdrop, CircularProgress, CircularProgressProps } from '@mui/material';
import { Component } from 'react'

export type LoaderOverlayProps = {
  size?: number;
  open?: boolean;
  color?: CircularProgressProps['color'];
}

export class LoaderOverlay extends Component<LoaderOverlayProps> {
  static defaultProps = {
    size: 100,
    color: 'primary'
  }

  render = () =>
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!!this.props.open}>
      <CircularProgress size={this.props.size} color={this.props.color} />
    </Backdrop>
}

export default LoaderOverlay
