import React, {ReactElement, ReactNode} from "react"
import Autocomplete, {AutocompleteProps} from "./Autocomplete"
import { humanize } from "utils"
import * as API from 'api'
import pluralize from 'pluralize'
import {camelized, capitalize} from 'utils'

export type AutocompleteSearchProps<Option, Value> = {
  name: string;
  label?: string;
  subject?: string;
  valueAs?: string;
  getOptionLabel?: (option: Option) => string,
  onSuggestionsFetchRequested?: (text: string, callback: (data: any[]) => void) => void,
  searchableField?: string;
  filterName?: string;
  children?: ReactNode;
  ref?: React.Ref<Autocomplete>;
  error?: boolean;
  helperText?: string;
  filter?: object;
  fields?: string;
  sort?: string;
} & Omit<AutocompleteProps<Option, Value>, "ref">

export const AutocompleteSearch: <Option = any, Value = any>(props: AutocompleteSearchProps<Option, Value>) => ReactElement = (props) => {
  const {
    name,
    subject = name,
    valueAs = "object",
    searchableField = "name",
    filterName = "search",
    fields = undefined,
    sort = undefined,
    filter,
    ...rest
  } = props

  const defaultOnSuggestionsFetchRequested = async (text, callback) => {
    const type = pluralize(camelized(subject, {lower: true}))
    const klass = capitalize(type)
    const { data } = await API[klass].index({
      options: {
        fields: {[type]: fields || `id,${searchableField}`},
        sort: sort,
        filter: {...filter, [filterName]: text},
        page: { number: 1, size: 15 }
      }
    })
    callback(valueAs === "string" ? data.map(o => o[searchableField]) : data)
  }

  const defaultGetOptionLabel = valueAs === "string" ? option => option : option => {
    if (option?.[searchableField]) {
      return option[searchableField]
    }

    return ""
  }

  const getOptionLabel = props.getOptionLabel || defaultGetOptionLabel
  const onSuggestionsFetchRequested = props.onSuggestionsFetchRequested || defaultOnSuggestionsFetchRequested

  return (
      <Autocomplete
          key={name}
          fullWidth
          name={name}
          label={props.label || humanize(name)}
          getOptionLabel={getOptionLabel}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          {...rest}
      />
  )
}

export default AutocompleteSearch