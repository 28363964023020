/** @jsxImportSource @emotion/react */
import React, {useRef, useState, useEffect} from 'react'
import { StylesObject } from "types"
import Dygraph from 'dygraphs'
import "./dygraph.css"
import { FormControlLabel, Checkbox } from "@mui/material";


// React dygraph wrapper
// Data should be of form [[x, y, z ...], [x, y, z...], ...]
// pass a new updatedAt to rerender the graph with new values/options to avoid expensive prop comparison

const ReactDygraph = ({data, options, updatedAt, showSeriesToggle=true}) => {
  const chartRef = useRef(null)
  const legendRef = useRef(null)

  const [chart, setChart]: any = useState()
  const [hiddenSeries, setHiddenSeries]: any = useState([])
  const labels = options.labels || []

  useEffect(() => {
    if (chartRef.current && !chart) {
      setChart(new Dygraph(chartRef.current, data, {
        labelsDiv: legendRef.current,
        ...options
      }))
    }
  }, [chartRef, chart])

  useEffect(() => {
    if (chart) {
      chart.updateOptions({
        file: data,
        ...options
      })
      resetSeriesVisibility()
      chart.resetZoom()
    }
  }, [updatedAt, options?.updatedAt])
  
  const resetSeriesVisibility = () => {
    for (let idx = 1; idx < chart.numColumns(); idx++) {
      chart.setVisibility(idx-1, true)
    }
    setHiddenSeries([])
  }

  const handleVisibiltyToggle = (idx, vis) => {
    chart.setVisibility(idx, vis)
    setHiddenSeries(vis ? hiddenSeries.filter(h => h !== idx) : [...hiddenSeries, idx])
  }

  const renderSeriesVisibility = () => {
    const series = labels.slice(1)
    const colors = options.colors || chart?.getColors() || []

    return (
      <div css={styles.seriesVisibility}>
        {series.map((label, idx) => {
          const vis = !hiddenSeries.includes(idx)
          return (
            <FormControlLabel
              key={idx}
              control={
                <Checkbox
                  checked={vis}
                  onChange={() => handleVisibiltyToggle(idx, !vis)}
                  style={{color: colors[idx]}}
                />
              }
              label={label}
            />)
        })}
    </div>
    )
  }

  return (
    <div css={styles.container}>
      {showSeriesToggle && renderSeriesVisibility()}
      <div css={styles.legend} ref={legendRef} />
      <div css={styles.chartBounds}>
        <div css={styles.charts} ref={chartRef} />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'hidden',
    flex: 1,
    minHeight: 400,
  },
  chartBounds: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  charts: {
    flex: 1,
    overflow: 'hidden'
  },
  legend: {
    flex: 0,
    paddingTop: 20,
  },
  seriesVisibility: {
  }
} as StylesObject

export default ReactDygraph