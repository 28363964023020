/** @jsxImportSource @emotion/react */
import  React  from 'react'
import { Pagination, ErrorBanner, PageContainer, SearchCombo, DatePicker, PageHeader } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDateTime} from 'utils'
import PublishIcon from '@mui/icons-material/Publish';
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { OfferRun } from "types"

const List = () => {
  const [offerRuns, actions] = useResource<OfferRun>("offerRuns")

  const history = useHistory()

  const [page, setPage] = usePagination("offerRuns")
  const [filter, setFilter] = useFilter(setPage, 'offerRuns')

  useDependency(() => (
    actions.index({
      page: page,
      fields: {offerRuns: 'createdAt'},
      sort: '-id',
      filter
    })
  ), [page, filter])

  const showOfferRun = ({id}) => () => {
    history.push(`/offer_runs/${id}`)
  }

  const renderOfferRunListItem = (offerRun) => {
    const { id, createdAt } = offerRun
    return (
      <ListItem button onClick={showOfferRun(offerRun)} key={id}>
        <ListItemIcon>
          <PublishIcon />
        </ListItemIcon>
        <ListItemText primary={formatLocaleDateTime(createdAt)}/>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Offer Runs`} />
      <ErrorBanner>
        {apiErrorStringsFor(offerRuns, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={offerRuns.totalPages} 
                  page={page} 
                  onPageSelected={setPage} 
                  startAdornment={(
                    <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
                      <DatePicker name="runDateFrom" fullWidth />
                      <DatePicker name="runDateTo" fullWidth />
                    </SearchCombo>
                  )}
      />
      <MuiList dense>
        {offerRuns.list.map(renderOfferRunListItem)}
      </MuiList>
      <Pagination totalPages={offerRuns.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List