/** @jsxImportSource @emotion/react */
import {Box, IconButton, Typography} from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom'

export type PageHeaderProps = {
  title: string;
  actions?: React.ReactNode;
  className?: string;
}

const PageHeader = ({title, actions, className}: PageHeaderProps) => {
  const history = useHistory()

  return (
    <Box display="flex" justifyContent="space-between" mb={1} className={className}>
      <Box display="flex">
        <IconButton color="secondary" onClick={history.goBack}>
          <ArrowBackIosNewIcon color="secondary" />
        </IconButton>
        <Typography variant="h4">&nbsp;{title}</Typography>
      </Box>
      <Box>
        {actions}
      </Box>
    </Box>
  )
}

export default PageHeader