import {ResourceError, ResourceState} from "hooks/useResource";
import {errorStringsFromError} from "./JSONApiUtils";
import {deepSet} from "./ObjectUtils";

export function apiErrorStringsFor<S extends ResourceState<unknown>> (state: S, ...errorTypes: string[]): string[] {
  return errorStringsFromError(apiErrorsFor(state, ...errorTypes))
}

export function apiErrorsFor<S extends ResourceState<unknown>> (state: S, ...errorTypes: string[]): ResourceError[] {
  return errorTypes.reduce((errors, errorType) =>
    (state.errors[errorType] ? [...errors, state.errors[errorType]] : errors) as ResourceError[]
  , [] as ResourceError[])
}

export function formErrors<S extends ResourceState<unknown>>(state: S, ...errorTypes: string[]): Record<string, string> {
  const types = errorTypes.length > 0 ? errorTypes : ["updated", "created"]
  let formErrors = {}
  types.forEach(errorType => {
    Object.entries(state.errors[errorType]?.meta ?? {}).forEach(([name, value]) => {
      formErrors = deepSet(value.join(', '), name, formErrors)
    })
  })

  return formErrors
}