/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource, useAuthorization } from 'hooks'
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { ForecastRun } from "types"
import { formatLocaleDateTime, humanize } from 'utils'
import { List as ForecastRunLogsList } from "containers/forecast_run_logs"
import { Link } from 'react-router-dom'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [forecastRuns, actions] = useResource<ForecastRun>("forecastRuns")
  const authorization = useAuthorization()

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const forecastRun: Partial<ForecastRun> = forecastRuns.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Forecast Run`} actions={
        (authorization.admin || authorization.internal) && <Link to={`/forecasts?forecasts.filter.forecastRunId=${id}`}>
          <Button variant="contained" color="secondary">View Data</Button>
        </Link>
       } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 130 }} variant='head'>Run at</TableCell>
            <TableCell>{formatLocaleDateTime(forecastRun.runAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Source</TableCell>
            <TableCell>{String(humanize(forecastRun.source || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>{String(humanize(forecastRun.status || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>First Period TS</TableCell>
            <TableCell>{formatLocaleDateTime(forecastRun.firstPeriodTs)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Period Count</TableCell>
            <TableCell>{String(forecastRun.periodCount || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Point In Time</TableCell>
            <TableCell>{String(forecastRun.pointInTime || false)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <div>
        <ForecastRunLogsList forecastRunId={id} />
      </div>
    </PageContainer>
  )
}

export default Show