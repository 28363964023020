/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { IconButton, TextField, Typography } from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave, useQueryString } from 'hooks'
import { AutocompleteSearch, ControlledForm, ErrorBanner, LabeledSelect, PageContainer, PageHeader, PromiseButton } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { Site } from "types"
import RemoveIcon from '@mui/icons-material/Close';

const Form = () => {
  const { editMode, id, resourceAction } = useCommonRouteParams()
  const [sites, actions] = useResource<Site>("sites")
  const [{ customerId }] = useQueryString()
  const [formData, setFormData] = useState<Partial<Site>>({ customerId, pricingMethod: 'spot', gxp: 'HAM0331' })
  const handleSave = useDefaultSave(formData, actions, { linked: ['customer', 'contacts'] })

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!, { include: 'customer,contacts' })
      setFormData(result)
    }
  }, [editMode, id])

  const handleAddContact = ({ target: { value } }) => {
    setFormData(data => ({ ...data, contacts: [...(data?.contacts?.filter(c => c.id !== value?.id) || []), value].filter(c => !!c) }))
  }

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Site - ${formData?.name}` : 'Create a new Site'} />
      <ControlledForm data={formData} errors={formErrors(sites, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(sites, 'create', 'update')}</ErrorBanner>
        {!customerId && <AutocompleteSearch fullWidth name='customer' searchableField="name" />}
        <TextField fullWidth label="Name" name='name' />
        <TextField fullWidth label="ICP Number" name='icpNumber' />
        <TextField fullWidth label="GXP" name='gxp' />
        <TextField fullWidth name='address' multiline />
        <LabeledSelect fullWidth name="pricingMethod" options={{ spot: 'Spot' }} />
        <PageContainer css={{ marginTop: 16, marginBottom: 16 }}>
          <Typography variant='h4' css={{ marginBottom: 20, display: 'flex' }}>Site Contacts</Typography>
          {formData?.contacts?.map(user => (<div css={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => { setFormData(data => ({ ...data, contacts: (data?.contacts?.filter(c => c.id !== user.id) || []) })) }}>
              <RemoveIcon />
            </IconButton>
            {user.name}
          </div>))}
          {formData?.customer &&
            <AutocompleteSearch fullWidth label="Search for a contact" name='user' searchableField="name" filter={{ customerId: (formData?.customer?.id || formData?.customerId) }} css={{ flex: '1 1 auto' }} onChange={handleAddContact} value={null} blurOnSelect={true} />
          }
        </PageContainer>
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form