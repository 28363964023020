import React, { useContext, useState } from "react";

export type Notification = {
  message: string;
  duration?: number;
};

export type SnackbarContextState = {
  show: (message: string, duration?: number) => void;
  clear: () => void;
  notification: Notification | null
};

const SnackbarContext = React.createContext<SnackbarContextState | undefined>(undefined);

export const SnackbarProvider = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const value = {
    show: (message: string, duration?: number) => setNotification({ message, duration }),
    clear: () => setNotification(null),
    notification
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextState => {
  const snackbar = useContext<SnackbarContextState | undefined>(SnackbarContext)
  if (!snackbar) {
    console.warn("SnackbarContext must be provided in order to use it")

    return {
      show: () => { },
      clear: () => { },
      notification: null,
    }
  }

  return snackbar
}