/** @jsxImportSource @emotion/react */
import React, {useRef} from 'react'
import { Pagination, ErrorBanner, PageContainer, DeleteConfirmationDialog, PageHeader } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import {apiErrorStringsFor, errorStringsFromError, formatLocaleDateTime} from 'utils'
import {usePagination, useDependency, useHotWater} from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"

const FileUploadButton = ({ onChange }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        onChange={onChange}
        accept=".xlsx"
        ref={inputFile}
        hidden
      />
      <Button color="secondary" variant="contained" onClick={() => inputFile.current?.click()}>Upload file</Button>
    </>
  )
}

const List = () => {
  const [hotWaterUploads, actions] = useHotWater()

  const snackbar = useSnackbar()
  const history = useHistory()

  const [page, setPage] = usePagination("hotWaterUploads")

  const [, , reloadHotWaterUploads] = useDependency(() => (
    actions.index({
      page: page,
      fields: {hotWaterUploads: 'fileName,createdAt,confirmed,confirmedAt'},
      sort: '-confirmedOrCreatedAt'
    })
  ), [page])

  const handleFileUpload = async ({target: {files: [file] }}) => {
    try{
      const { id } = await actions.create({file})
      history.push(`/hot_water_uploads/${id}`)
    }catch(error){
      snackbar.show(errorStringsFromError(error).join(', '))
    }
  }

  const showHotWaterUpload = ({id}) => () => {
    history.push(`/hot_water_uploads/${id}`)
  }


  const deleteHotWaterUpload = ({id}) => () => {
    actions.destroy({id})
           .then(reloadHotWaterUploads)
           .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderHotWaterUploadListItem = (hotWaterUpload) => {
    const { id, confirmed, confirmedAt, createdAt, fileName } = hotWaterUpload
    const Icon = confirmed ? CheckCircleIcon : PendingIcon;
    const iconColor = confirmed ? 'green' : 'darkorange'
    return (
      <ListItem button onClick={showHotWaterUpload(hotWaterUpload)} key={id}>
        <ListItemIcon>
          <Icon style={{ color: iconColor }}/>
        </ListItemIcon>
        <ListItemText primary={formatLocaleDateTime(confirmedAt ?? createdAt)} secondary={fileName} />
        <ListItemSecondaryAction>
          {!confirmed &&
            <DeleteConfirmationDialog onConfirm={deleteHotWaterUpload(hotWaterUpload)} entityName="hot water upload" />
          }
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title="Hot Water Uploads" actions={
        <FileUploadButton onChange={handleFileUpload} />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(hotWaterUploads, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={hotWaterUploads.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {hotWaterUploads.list.map(renderHotWaterUploadListItem)}
      </MuiList>
      <Pagination totalPages={hotWaterUploads.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List