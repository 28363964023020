/** @jsxImportSource @emotion/react */
import React from 'react'
import { PageHeader, Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, AutocompleteSearch, LabeledSelect } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ForecastEvaluationIcon from '@mui/icons-material/Insights'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, formatLocaleDateTime, siteAndMeterFilters } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { ForecastEvaluation } from "types"

const List = () => {
  const [forecastEvaluations, actions] = useResource<ForecastEvaluation>("forecastEvaluations")

  const history = useHistory()


  const [page, setPage] = usePagination("forecastEvaluations")
  const [filter, setFilter] = useFilter(setPage, 'forecastEvaluations')

  useDependency(() => (
    actions.index({
      page: page,
      include: 'installedMeter,forecastEvaluationRun',
      fields: { forecastEvaluations: 'metricName,metricValue,recordCount', installedMeters: 'meterSerial', forecastEvaluationRuns: 'runAt,fromDatetime,toDatetime' },
      filter,
    })
  ), [page, filter])

  const showForecastEvaluation = ({ id }) => () => {
    history.push(`/forecast_evaluations/${id}`)
  }

  const renderForecastEvaluationListItem = (forecastEvaluation) => {
    const { id, metricName, metricValue, recordCount, forecastEvaluationRun, installedMeter } = forecastEvaluation
    return (
      <TableRow key={id} onClick={showForecastEvaluation(forecastEvaluation)} css={{ cursor: 'pointer' }}>
        <TableCell>{forecastEvaluationRun.id}</TableCell>
        <TableCell>{formatLocaleDateTime(forecastEvaluationRun.runAt)}</TableCell>
        <TableCell>{formatLocaleDateTime(forecastEvaluationRun.fromDatetime)}</TableCell>
        <TableCell>{formatLocaleDateTime(forecastEvaluationRun.toDatetime)}</TableCell>
        <TableCell>{installedMeter.meterSerial}</TableCell>
        <TableCell>{recordCount}</TableCell>
        <TableCell>{metrics[metricName] || metricName}</TableCell>
        <TableCell>{metricValue}</TableCell>
      </TableRow>
    )
  }

  const autoClears = { customer: { fields: ['site', 'installedMeter'], value: null }, site: { fields: ['installedMeter'], value: null } }
  return (
    <PageContainer>
      <PageHeader title={`Forecast Evaluations`} />
      <ErrorBanner>
        {apiErrorStringsFor(forecastEvaluations, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={forecastEvaluations.totalPages} page={page} onPageSelected={setPage} startAdornment={
        <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'} autoClears={autoClears}>
          {(state) => {
            const { sitesKey, sitesFilter, metersKey, metersFilter } = siteAndMeterFilters(state)
            return <>
              <TextField name='forecastEvaluationRunId' fullWidth />
              <AutocompleteSearch fullWidth name='customer' searchableField="name" />
              <AutocompleteSearch fullWidth name='site' key={sitesKey} filter={sitesFilter} searchableField="displayName" />
              <AutocompleteSearch fullWidth name="installedMeter" key={metersKey} filter={metersFilter} searchableField="meterSerial" label="Meter" />
              <LabeledSelect name="metricName" label="Metric" fullWidth options={{ rmse: "RMSE" }} />
            </>
          }}
        </SearchCombo>
      } />

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Run ID</TableCell>
            <TableCell>Run At</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Meter Serial</TableCell>
            <TableCell>Count</TableCell>
            <TableCell>Metric</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecastEvaluations.list.map(renderForecastEvaluationListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={forecastEvaluations.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

const metrics = {
  rmse: 'RMSE'
}

export default List