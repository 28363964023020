/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { ServiceAccount } from "types"

const List = () => {
  const [serviceAccounts, actions] = useResource<ServiceAccount>("serviceAccounts")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("serviceAccounts")

  const [, , reloadServiceAccounts] = useDependency(() => (
    actions.index({
      page: page,
      fields: {serviceAccounts: 'name'}
    })
  ), [page])

  const showServiceAccount = ({id}) => () => {
    history.push(`/service_accounts/${id}`)
  }

  const editServiceAccount = ({id}) => event => {
    history.push(`/service_accounts/${id}/edit`)
    event.stopPropagation()
  }

  const deleteServiceAccount = ({id}) => () => {
    actions.destroy({id})
           .then(reloadServiceAccounts)
           .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderServiceAccountListItem = (serviceAccount) => {
    const { id, name } = serviceAccount
    return (
      <ListItem button onClick={showServiceAccount(serviceAccount)} key={id}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editServiceAccount(serviceAccount)} size="large"><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteServiceAccount(serviceAccount)} entityName="service account" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Service Accounts</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(serviceAccounts, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={serviceAccounts.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {serviceAccounts.list.map(renderServiceAccountListItem)}
      </MuiList>
      <Pagination totalPages={serviceAccounts.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='secondary' onClick={() => history.push('/service_accounts/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

export default List