/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, AutocompleteSearch } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SiteIcon from '@mui/icons-material/Place'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError } from 'utils'
import { usePagination, useDependency, useResource, useFilter, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { Site } from "types"

const List = ({ customerId }) => {
  const [sites, actions] = useResource<Site>("sites")

  const snackbar = useSnackbar()
  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination("sites")
  const [filter, setFilter] = useFilter(setPage, 'sites')

  const [, , reloadSites] = useDependency(() => (
    actions.index({
      page: page,
      fields: { sites: 'name,icpNumber,address', customers: 'name' },
      include: 'customer',
      filter: { customerId, ...filter },
      sort: 'customerName,icpNumber',
    })
  ), [page, filter])

  const showSite = ({ id }) => () => {
    history.push(`/sites/${id}`)
  }

  const editSite = ({ id }) => event => {
    history.push(`/sites/${id}/edit`)
    event.stopPropagation()
  }

  const deleteSite = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadSites)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderSiteListItem = (site) => {
    const { id, name, address, customer } = site
    return (
      <ListItem button onClick={showSite(site)} key={id}>
        <ListItemIcon>
          <SiteIcon />
        </ListItemIcon>
        <ListItemText primary={`${customer?.name} - ${name}`} secondary={address} />
        {authorization.internalOrAdmin && <ListItemSecondaryAction>
          <IconButton onClick={editSite(site)} size="large"><EditIcon /></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteSite(site)} title={`Confirm Site Removal`}>
            <div>
              <p>Are you sure you want to remove this site?</p>
              <p>Name: <b>{name}</b></p>
              <p>Customer: <b>{customer?.name}</b></p>
              <p>Address: <pre css={{ fontFamily: 'inherit', fontSize: 'inherit', margin: 0, fontWeight: 'bold' }}>
                {site.address || ''}
              </pre></p>
              <p css={{color: 'red'}}>WARNING: this will remove all related meters.</p>
            </div>
          </DeleteConfirmationDialog>
        </ListItemSecondaryAction>}
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' css={{ marginBottom: 20, display: 'flex' }}>
        Sites
        <span css={{ flex: '1' }} />
        {customerId && <Button variant="contained" onClick={() => history.push(`/sites/new?customerId=${customerId}`)}>New</Button>}
      </Typography>
      <ErrorBanner>
        {apiErrorStringsFor(sites, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={sites.totalPages} page={page} onPageSelected={setPage} startAdornment={
        !customerId && (authorization.internalOrAdmin || authorization.csc) && <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
          <AutocompleteSearch fullWidth name='customer' searchableField="name" />
        </SearchCombo>
      } />
      <MuiList dense>
        {
          sites.list.map(renderSiteListItem)
        }
      </MuiList>
      <Pagination totalPages={sites.totalPages} page={page} onPageSelected={setPage} />
      {!customerId && authorization.internalOrAdmin && <FABFixed color='secondary' onClick={() => history.push('/sites/new')}>
        <AddIcon />
      </FABFixed>}
    </PageContainer>
  )
}

export default List