/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import { ControlledForm, ErrorBanner, PageContainer, PageHeader, PromiseButton } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { Customer } from "types"

const Form = () => {
  const {editMode, id, resourceAction} = useCommonRouteParams()
  const [customers, actions] = useResource<Customer>("customers")
  const [formData, setFormData] = useState<Partial<Customer>>({})
  const handleSave = useDefaultSave(formData, actions)

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!)
      setFormData(result)
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Customer - ${formData.name}` : 'Create a new Customer'} />
      <ControlledForm data={formData} errors={formErrors(customers, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(customers, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='customerNumber'/>
        <TextField fullWidth name='name'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form