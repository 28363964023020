/** @jsxImportSource @emotion/react */
import {DatePicker, Pagination, SearchCombo, ErrorBanner, PageContainer, LabeledSelect, PageHeader, LabeledSwitch} from 'components'
import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime, titleize} from 'utils'
import {usePagination, useDependency, useResource, useFilter, useQueryString} from 'hooks'
import { useHistory } from 'react-router-dom'
import {Offer, OfferRun} from "types"

const List = () => {
  const [offers, actions] = useResource<Offer>("offers")
  const [offerRuns, offerRunsActions] = useResource<OfferRun>("offerRuns")
  const offerRun = offerRuns.selected

  const history = useHistory()
  const [page, setPage] = usePagination("offers")
  const [filter, setFilter] = useFilter(setPage, 'offers')
  const [{ offerRunId }] = useQueryString()

  useDependency(() => (
    actions.index({
      page: page,
      fields: {offers: 'tradingDate,tradingPeriod,status,bonaFide,firOffer,sirOffer,offerRunId'},
      sort: 'tradingDate,tradingPeriod,offerRunId',
      filter: { ...filter, ...(offerRunId && { offerRunId }) },
    })
  ), [page, filter])

  useDependency(async () => {
    if (filter.offerRunId) {
      await offerRunsActions.show(filter.offerRunId)
    }
  }, [filter.offerRunId])

  const showOffer = ({id}) => () => {
    history.push(`/offers/${id}`)
  }

  const renderOfferListItem = (offer) => {
    const { id, tradingDate, tradingPeriod, status, bonaFide, firOffer, sirOffer } = offer
    return (
      <TableRow key={id} onClick={showOffer(offer)} css={{ cursor: 'pointer' }}>
        <TableCell>{id}</TableCell>
        <TableCell>{offer.offerRunId}</TableCell>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>TP {tradingPeriod}</TableCell>
        <TableCell>{titleize(status)}</TableCell>
        <TableCell>{bonaFide ? 'Yes' : 'No'}</TableCell>
        <TableCell>{firOffer} MW</TableCell>
        <TableCell>{sirOffer} MW</TableCell>
      </TableRow>
    )
  }

  let title = 'Offers'
  if (offerRun?.createdAt) {
    title = `${formatLocaleDateTime(offerRun?.createdAt)} Offers`
  } else if (filter.showOnly === "latest_offers") {
    title = "Effective Offers"
  } else if (filter.showOnly === "latest_accepted_offers") {
    title = "Latest Accepted Offers"
  }

  return (
    <PageContainer>
      <PageHeader title={title} />
      <ErrorBanner>
        {apiErrorStringsFor(offers, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={offers.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
                      <LabeledSelect name="status" fullWidth options={["created", "ignored", "delivered", "accepted", "failed"]} />
                      <LabeledSelect name="bonaFide" fullWidth options={{'true': "Yes", "false": "No"}} />
                      <DatePicker name="tradingDate" fullWidth />
                      <TextField name="tradingPeriod" type="number" fullWidth />
                      <LabeledSelect name="showOnly" label="Show Only" fullWidth options={{'': "All Offers", 'latest_offers': "Effective Offers", "latest_accepted_offers": "Latest Accepted Offers"}} />
                      <LabeledSelect name="hideHistory" label="Hide History" fullWidth options={{ true: 'Yes', false: 'No' }} />
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Run ID</TableCell>
            {/*<TableCell>Created At</TableCell>*/}
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Bona Fide?</TableCell>
            <TableCell>FIR</TableCell>
            <TableCell>SIR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.list.map(renderOfferListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={offers.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List