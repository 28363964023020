/** @jsxImportSource @emotion/react */
import React  from 'react'
import {Pagination, ErrorBanner, PageContainer, SearchCombo, DatePicker, PageHeader, ExportButton} from 'components'
import {
  Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDate } from 'utils'
import {usePagination, useDependency, useResource, useFilter} from 'hooks'
import { useHistory } from 'react-router-dom'
import { DerivedDispatch } from "types"

const List = () => {
  const [derivedDispatches, actions] = useResource<DerivedDispatch>("derivedDispatches")

  const history = useHistory()

  const [page, setPage] = usePagination("derivedDispatches")
  const [filter, setFilter] = useFilter(setPage, 'derivedDispatches')

  useDependency(() => (
    actions.index({
      page: page,
      sort: '-tradingDate,-tradingPeriod',
      filter,
    })
  ), [page, filter])

  const showDerivedDispatch = ({id}) => () => {
    history.push(`/derived_dispatches/${id}`)
  }

  const renderDerivedDispatchListItem = (derivedDispatch) => {
    const { id, tradingDate, tradingPeriod, sirAvgDispatch, firAvgDispatch, block } = derivedDispatch
    return (
      <TableRow key={id} onClick={showDerivedDispatch(derivedDispatch)} css={{ cursor: 'pointer' }}>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>TP {tradingPeriod}</TableCell>
        <TableCell>{firAvgDispatch} MW</TableCell>
        <TableCell>{sirAvgDispatch} MW</TableCell>
        <TableCell>{block}</TableCell>
      </TableRow>
    )
  }

  const dateRangeSelected = filter.tradingDateFrom && filter.tradingDateTo

  return (
    <PageContainer>
      <PageHeader title="Average Dispatch" actions={
        <Tooltip title={!dateRangeSelected ? "Please select date range to export" : null}>
          <div><ExportButton resource="derived_dispatches" filter={filter} sort="tradingDate,tradingPeriod" disabled={!dateRangeSelected} /></div>
        </Tooltip>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(derivedDispatches, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={derivedDispatches.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='search' onFilterChange={setFilter} filter={filter}>
                      <TextField name="block" fullWidth />
                      <DatePicker name="tradingDateFrom" fullWidth />
                      <DatePicker name="tradingDateTo" fullWidth />
                      <TextField name="tradingPeriod" type="number" fullWidth />
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell>FIR</TableCell>
            <TableCell>SIR</TableCell>
            <TableCell>Block</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {derivedDispatches.list.map(renderDerivedDispatchListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={derivedDispatches.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List