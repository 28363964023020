import Dygraph from 'dygraphs'
import moment from 'moment'

export function tickGranularity(days, minTicks=6){
  if (days > minTicks*3650) return Dygraph.Granularity.DECADAL
  if (days > minTicks*365)  return Dygraph.Granularity.ANNUAL
  if (days > minTicks*182)  return Dygraph.Granularity.BIANNUAL
  if (days > minTicks*91)   return Dygraph.Granularity.QUARTERLY
  if (days > minTicks*30)   return Dygraph.Granularity.MONTHLY
  if (days > minTicks*3)    return Dygraph.Granularity.WEEKLY
  if (days > minTicks*1.5)  return Dygraph.Granularity.TWO_DAILY
  if (days > minTicks*0.7)  return Dygraph.Granularity.DAILY
  if (days > minTicks*0.2)  return Dygraph.Granularity.SIX_HOURLY
  if (days > minTicks*0.1)  return Dygraph.Granularity.TWO_HOURLY
  if (days > minTicks*0.05)  return Dygraph.Granularity.HOURLY
  if (days > minTicks*0.03) return Dygraph.Granularity.THIRTY_MINUTELY
  if (days > minTicks*0.01) return Dygraph.Granularity.FIVE_MINUTELY
  if (days > minTicks*0.001) return Dygraph.Granularity.MINUTELY
  return null
}

export function dateAxisFormatter(data){
  const days = data.length >= 2 ? (data[data.length-1][0] - data[0][0]) / 86400000 : 1
  const granularity = tickGranularity(days)
  switch(granularity){
    case Dygraph.Granularity.DECADAL: return v => moment(v).format('YYYY')
    case Dygraph.Granularity.ANNUAL: return v => moment(v).format('YYYY')
    case Dygraph.Granularity.BIANNUAL: return v => moment(v).format('MMM YY')
    case Dygraph.Granularity.QUARTERLY: return v => moment(v).format('MMM YY')
    case Dygraph.Granularity.MONTHLY: return v => moment(v).format('MMM YY')
    case Dygraph.Granularity.WEEKLY: return v => moment(v).format('DD/MM/YY')
    case Dygraph.Granularity.TWO_DAILY: return v => moment(v).format('DD/MM/YY')
    case Dygraph.Granularity.DAILY: return v => moment(v).format('DD/MM/YY')
    case Dygraph.Granularity.SIX_HOURLY: return v => moment(v).format('HH:mm')
    case Dygraph.Granularity.TWO_HOURLY: return v => moment(v).format('HH:mm')
    case Dygraph.Granularity.HOURLY: return v => moment(v).format('HH:mm')
    case Dygraph.Granularity.THIRTY_MINUTELY: return v => moment(v).format('HH:mm')
    case Dygraph.Granularity.FIVE_MINUTELY: return v => moment(v).format('HH:mm')
    case Dygraph.Granularity.MINUTELY: return v => moment(v).format('HH:mm')

    default: return v => moment(v).format('HH:mm:ss')
  }
}

export function dateTicker(data, maxGranularity=0){
  const days = data.length >= 2 ? (data[data.length-1][0] - data[0][0]) / 86400000 : 1
  let granularity = tickGranularity(days)
  granularity = granularity && granularity < maxGranularity ? maxGranularity : granularity
  return granularity && ((min, max, pixels, opts, dygraph, vals) => Dygraph.getDateAxis(min, max, granularity, opts, dygraph))
}

export function darkenColor(colorStr) {
  // Defined in dygraph-utils.js
  var color = Dygraph.toRGB_(colorStr);
  return color && 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
}

export function barChartPlotter(e) {
  var ctx = e.drawingContext;
  var points = e.points.filter(point => point.canvasy || point.canvasy === 0);
  var y_bottom = e.dygraph.toDomYCoord(0);
  ctx.fillStyle = e.color;
  ctx.strokeStyle = darkenColor(e.color);

  // Find the minimum separation between x-values.
  // This determines the bar width.
  var min_sep = Infinity;
  var i;
  for (i = 1; i < points.length; i++) {
    var sep = points[i].canvasx - points[i - 1].canvasx;
    if (sep < min_sep) min_sep = Math.max(sep, 1);
  }
  var bar_width = Math.floor(2.0 / 3 * min_sep)  

  // Do the actual plotting.
  for (i = 0; i < points.length; i++) {
    var p = points[i];
    var center_x = p.canvasx;

    ctx.fillRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);

    ctx.strokeRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);
  }
}

export function legendFormatter({showTotal=false, unit=''}) {
  return (data) => {
    var g = data.dygraph;

    if (g.getOption('showLabelsOnHighlight') !== true) return '';
    var sepLines = g.getOption('labelsSeparateLines');
    var html;
    if (typeof data.x === 'undefined') {
      if (g.getOption('legend') != 'always') {
        return '';
      }
      html = '';
      for (var i = 0; i < data.series.length; i++) {
        var series = data.series[i];
        if (!series.isVisible) continue;
        if (html !== '') html += sepLines ? '<br/>' : ' ';
        html += "<span style='font-weight: bold; color: ".concat(series.color, ";'>").concat(series.dashHTML, " ").concat(series.labelHTML, "</span>");
      }
      return html;
    }
    html = data.xHTML + ':';
    var total = 0
    for (var i = 0; i < data.series.length; i++) {
      var series = data.series[i];
      if (!series.y && !series.yHTML) continue;
      if (!series.isVisible) continue;
      if (sepLines) html += '<br>';
      var cls = series.isHighlighted ? ' class="highlight"' : '';
      html += "<span".concat(cls, "> <b><span style='color: ").concat(series.color, ";'>").concat(series.labelHTML, "</span></b>:&#160;").concat(series.yHTML, "</span>");
      total += series.y
    }
    if (showTotal){
      html += `<br/><b>Total:</b> ${total} ${unit}`
    }
    return html;
  }
}