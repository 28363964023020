/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react'
import { Button } from '@mui/material'
import { useDependency, useResource } from 'hooks'
import { ControlledForm, ErrorBanner, LoaderOverlay, PageContainer, PageHeader } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { InstalledMeter, MeterTestResultUpload } from "types"
import { useSnackbar } from 'contexts/SnackbarContext'
import useRedirect from 'hooks/useRedirect'
import { useRouteMatch } from 'react-router-dom'

const FileUploadButton = ({ onChange, value }: { onChange: React.ChangeEventHandler<HTMLInputElement>, value?: File, name?: string }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        type="file"
        onChange={onChange}
        accept=".csv"
        ref={inputFile}
        hidden
      />
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <div css={{ marginRight: 16 }}>{value?.name || "No file selected"}</div>
        <Button color="secondary" variant="contained" onClick={() => inputFile.current?.click()} css={{ marginBottom: 0, flexShrink: 0 }}>Select file</Button>
      </div>
    </>
  )
}

const Form = () => {
  const match = useRouteMatch<{ installedMeterId?: string }>()
  const installedMeterId = match.params.installedMeterId

  const [installedMeters, installedMeterActions] = useResource<InstalledMeter>("installedMeters")
  useDependency(async () => {
    if (installedMeterId) {
      installedMeterActions.show(installedMeterId)
    }
  }, [installedMeterId])
  const installedMeter: Partial<InstalledMeter> = installedMeters.selected ?? {}

  const [meterTestResultUploads, actions] = useResource<MeterTestResultUpload>("meterTestResultUploads")
  const [formData, setFormData] = useState<Partial<MeterTestResultUpload>>({ installedMeterId })
  const [saving, setSaving] = useState<boolean>(false)
  const snackbar = useSnackbar()
  const redirect = useRedirect()

  const handleFileChange = (event) => {
    setFormData(fd => ({ ...fd, file: event?.target?.files?.[0] }))
  }

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const upload = await actions.create(formData)
      setFormData({ installedMeterId })
      redirect(`/meter_test_result_uploads/${upload.id}`)
    } catch {
      snackbar.show('Error Uploading File')
    }
    setSaving(false)
  }

  return (
    <PageContainer>
      <PageHeader title="Upload Meter Test Results" />
      <LoaderOverlay open={saving} />
      <ControlledForm data={formData} errors={formErrors(meterTestResultUploads, 'create')} onChange={setFormData} onSubmit={handleSubmit}>
        <div>
          <b>Meter Serial: </b><span>{installedMeter.meterSerial}</span>
        </div>
        <ErrorBanner>{apiErrorStringsFor(meterTestResultUploads, 'create', 'update')}</ErrorBanner>
        <FileUploadButton name="file" onChange={handleFileChange} />
        <Button color='secondary' fullWidth variant='contained' type='submit' disabled={!formData?.file || saving}>Upload</Button>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form