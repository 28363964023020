/** @jsxImportSource @emotion/react */
import React from 'react'
import { DateTimePicker as DTPicker, DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export type DateTimePickerProps = Omit<MuiDateTimePickerProps<Date,Date>, "value" | "onChange" | "renderInput"> & {
  onChange?: (event: { target: { value?: string } }) => void;
  value?: number | string | Date;
  name?: string;
  className?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
};

export const DateTimePicker = ({ name, value, onChange, className, fullWidth, error, helperText, ...rest }: DateTimePickerProps) => {
  const handleChange = date => {
    onChange && onChange({
      target: {
        value: date ? date.format('YYYY-MM-DDTHH:mm:ssZ') : date
      }
    })
  }

  return <DTPicker
    inputFormat="DD/MM/YY HH:mm"
    {...rest}
    onChange={handleChange}
    value={value ? new Date(value) : null}
    renderInput={(props) => <TextField fullWidth {...props} className={className} error={error} helperText={helperText} css={{ '& .MuiInputAdornment-root': { paddingRight: 4 } }} />}
    componentsProps={{
      actionBar: { actions: ['clear', 'cancel', 'accept'] },
    }}
  />

}

export default DateTimePicker