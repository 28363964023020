/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import MUIAutocomplete, { AutocompleteInputChangeReason, AutocompleteProps as MUIAutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from 'utils'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export type AutocompleteProps<Option, Value> = {
  options?: Option[];
  onChange?: (event: { target: { value: Value } }) => void;
  onSuggestionsFetchRequested?: (text: string, callback: (options: Option[]) => void) => void;
  label?: string;
  value?: Value;
  variant?: 'filled' | 'outlined' | 'standard';
  name?: string;
  helperText?: string;
  error?: boolean;
} & Omit<MUIAutocompleteProps<Value, boolean | undefined, boolean | undefined, boolean | undefined>, "renderInput" | "options" | "onChange">

type State<Option> = {
  loading: boolean;
  open: boolean;
  options: Option[];
  defaultOptions?: Option[];
}

export class Autocomplete<Option = any, Value = any> extends Component<AutocompleteProps<Option, Value>, State<Option>> {

  state = {
    options: [],
    loading: true,
    open: false,
    defaultOptions: undefined,
  }

  handleInputChange = (
    _event: React.SyntheticEvent,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason !== 'reset') {
      this.requestFetch(newInputValue)
    }
  }

  handleReceiveDefaultOptions = (options) => {
    this.setState({ defaultOptions: options })
    this.handleReceiveOptions(options)
  }

  handleReceiveOptions = (options) => {
    let additionalOptions: any[] = []
    const currentValue = this.props.value

    if (currentValue !== undefined && currentValue !== null && currentValue !== '') {
      additionalOptions = Array.isArray(currentValue) ?
        [...currentValue] :
        [currentValue]
    }

    // Add the current value(s) to the list of options but make sure there are
    // no duplicates with respect to this.props.isOptionEqualToValue
    additionalOptions = additionalOptions.filter(value => !options.some((option) => this.isOptionEqualToValue(option, value)));
    const newOptions = [...additionalOptions, ...options]

    if (this.state.options !== options) {
      this.setState({ loading: false })
      this.setState({ options: newOptions })
    }
  }

  requestFetch = debounce((text) => {
    if (!this.props.onSuggestionsFetchRequested) {
      if (!this.props.options) {
        console.warn("No options given to Autocomplete. Please provide one of the following props: onSuggestionsFetchRequested(text, callback) or options[array]")
      }
    }
    else {
      text = typeof text === 'string' ? text : ''
      if (text === '' && this.state.defaultOptions) {
        this.handleReceiveOptions(this.state.defaultOptions)
      } else {
        this.setState({ loading: true })
        this.props.onSuggestionsFetchRequested(text, text === '' ? this.handleReceiveDefaultOptions : this.handleReceiveOptions)
      }
    }
  })

  handleChange = (event, value) => {
    this.props.onChange && this.props.onChange({ target: { value } })
    this.resetToDefaultOptions()
  }

  get getOptionLabel() {
    if (this.props.getOptionLabel) {
      return this.props.getOptionLabel;
    }

    return option => typeof option === 'object' ? option.name : option
  }

  isOptionEqualToValue = (option: Value, value: Value) => {
    const isOptionEqualToValue = this.props.isOptionEqualToValue || ((option, value) => {
      if (hasIdAndType(option) && hasIdAndType(value)) {
        return option.id === value.id && option.type === value.type
      } else if (hasId(option) && hasId(value)) {
        return option.id === value.id
      } else {
        return option === value
      }
    })
    return isOptionEqualToValue(option, value)
  }

  handleBlur = () => {
    this.resetToDefaultOptions()
  }

  resetToDefaultOptions = () => {
    if (this.state.defaultOptions) {
      this.setState({ options: this.state.defaultOptions })
    }
  }

  render = () => {
    const { onSuggestionsFetchRequested, getOptionLabel, onChange, label, error, helperText, options, variant, fullWidth, ...acProps } = this.props
    return (
      <MUIAutocomplete
        {...acProps}
        onOpen={this.requestFetch}
        onChange={this.handleChange}
        options={this.state.options}
        loading={this.state.loading}
        getOptionLabel={this.getOptionLabel}
        onInputChange={this.handleInputChange}
        isOptionEqualToValue={this.isOptionEqualToValue}
        onBlur={this.handleBlur}
        renderInput={params => (
          <TextField {...params}
            fullWidth={fullWidth}
            label={label}
            variant={variant}
            error={error}
            helperText={helperText}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const label = this.getOptionLabel(option)
          const matches = match(label, inputValue);
          const parts = parse(label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span key={index} css={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    )
  }
}

type IDObject = { id: string }
function hasId(opt: any): opt is IDObject {
  return typeof opt === 'object' && !!opt && (opt as IDObject).id !== undefined;
}

type TypeObject = { type: string }
function hasType(opt: any): opt is TypeObject {
  return typeof opt === 'object' && !!opt && (opt as TypeObject).type !== undefined;
}

type APIObject = IDObject & TypeObject
function hasIdAndType(opt: any): opt is APIObject {
  return hasId(opt) && hasType(opt)
}

export default Autocomplete