/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, ErrorBanner, PageContainer } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import MeterReadUploadIcon from '@mui/icons-material/Backup'
import { apiErrorStringsFor, humanize } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { MeterReadUpload } from "types"

const List = () => {
  const [meterReadUploads, actions] = useResource<MeterReadUpload>("meterReadUploads")

  const history = useHistory()

  const [page, setPage] = usePagination("meterReadUploads")

  useDependency(() => (
    actions.index({
      page: page,
      fields: {meterReadUploads: 'filename,status'},
      sort: '-id',
    })
  ), [page])

  const showMeterReadUpload = ({id}) => () => {
    history.push(`/meter_read_uploads/${id}`)
  }

  const renderMeterReadUploadListItem = (meterReadUpload) => {
    const { id, filename, status } = meterReadUpload
    return (
      <ListItem button onClick={showMeterReadUpload(meterReadUpload)} key={id}>
        <ListItemIcon>
          <MeterReadUploadIcon />
        </ListItemIcon>
        <ListItemText primary={filename} secondary={<span css={{ color: statusColors[status] }}>{humanize(status)}</span>} />
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Meter Read Uploads</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(meterReadUploads, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterReadUploads.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {meterReadUploads.list.map(renderMeterReadUploadListItem)}
      </MuiList>
      <Pagination totalPages={meterReadUploads.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default List