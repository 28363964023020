/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import {
  AutocompleteSearch,
  Pagination,
  ErrorBanner,
  PageContainer,
  SearchCombo,
  DatePicker,
  PageHeader, ExportButton
} from 'components'
import {
  Table, TableHead, TableRow, TableCell, TableBody, TextField, Box, Tab
} from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime, siteAndMeterFilters } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { Customer, InstalledMeter, Settlement } from "types"


const TargetList = ({ target, targetLabel }) => {
  const [settlements, actions] = useResource<Settlement>("settlements")
  const history = useHistory()

  const [page, setPage] = usePagination(`${target}_settlements`)
  const [filter, setFilter] = useFilter(setPage, `${target}_settlements`)

  useDependency(() => (
    actions.index({
      page: page,
      include: 'target',
      filter: { ...filter, targetType: target },
      sort: '-tradingDate,-tradingPeriod'
    })
  ), [page, filter])

  const showSettlement = ({ id }) => () => {
    history.push(`/settlements/${id}`)
  }

  const renderSettlementListItem = (settlement) => {
    const { id, target, settlementRunId, tradingDate, tradingPeriod, firRevenue, sirRevenue, firDispatchedAmount, sirDispatchedAmount }: Settlement = settlement
    const targetLabel = `${(target as InstalledMeter)?.meterSerial || (target as Customer)?.name}`

    return (
      <TableRow key={id} onClick={showSettlement(settlement)} css={{ cursor: 'pointer' }}>
        <TableCell>{settlementRunId}</TableCell>
        <TableCell>{targetLabel}</TableCell>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>TP {tradingPeriod}</TableCell>
        <TableCell>${parseFloat(firRevenue).toFixed(3)}</TableCell>
        <TableCell>${parseFloat(sirRevenue).toFixed(3)}</TableCell>
        <TableCell>{parseFloat(firDispatchedAmount).toFixed(3)} MW</TableCell>
        <TableCell>{parseFloat(sirDispatchedAmount).toFixed(3)} MW</TableCell>
      </TableRow>
    )
  }

  const autoClears = target === 'InstalledMeter' ? {
    installedMeterCustomer: { fields: ['installedMeterSite', 'installedMeter'], value: null },
    installedMeterSite: { fields: ['installedMeter'], value: null },
  } : undefined
  return (
    <>
      <PageHeader title={`${targetLabel} settlements`} actions={
        <ExportButton resource="settlements" filter={{ ...filter, targetType: target }} sort="tradingDate,tradingPeriod" />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(settlements, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination
        totalPages={settlements.totalPages}
        page={page}
        onPageSelected={setPage}
        startAdornment={(
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter} autoClears={autoClears}>
            {(state) => {
              const { sitesKey, sitesFilter, metersKey, metersFilter } = siteAndMeterFilters(state, 'installedMeterCustomer', 'installedMeterSite')

              return <>
                <DatePicker name="tradingDate" fullWidth />
                <TextField name="tradingPeriod" type="number" fullWidth />
                <DatePicker name="tradingDateFrom" fullWidth />
                <DatePicker name="tradingDateTo" fullWidth />
                <AutocompleteSearch name="settlementRun" getOptionLabel={(run) => `${run.startedAt ? formatLocaleDateTime(run.startedAt) : ''}`} searchableField='startedAt' sort='-startedAt' />
                <AutocompleteSearch name="bulkReserveSource" />
                {
                  target === 'Customer' && <>
                    <AutocompleteSearch name="customer" label="Customer" />
                  </>
                }
                {
                  target === 'BulkReserveSource' && <>
                    <AutocompleteSearch name="bulkReserveSourceCustomer" subject="customer" label="Customer" />
                  </>
                }
                {
                  target === 'InstalledMeter' && <>
                    <AutocompleteSearch name="installedMeterCustomer" subject="customer" label="Customer" />
                    <AutocompleteSearch fullWidth name='installedMeterSite' subject="site" key={sitesKey} filter={sitesFilter} searchableField="displayName" label="Site" />
                    <AutocompleteSearch name="installedMeter" key={metersKey} filter={metersFilter} searchableField="meterSerial" label="Meter" />
                  </>
                }
              </>
            }}
          </SearchCombo>
        )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Run</TableCell>
            <TableCell>{targetLabel}</TableCell>
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell>FIR Revenue</TableCell>
            <TableCell>SIR Revenue</TableCell>
            <TableCell>FIR Cleared</TableCell>
            <TableCell>SIR Cleared</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settlements.list.map(renderSettlementListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={settlements.totalPages} page={page} onPageSelected={setPage} />
    </>
  )
}

export const List = () => {
  const [tab, setTab] = useState("Customer")

  const handleChangeTab = (_e, newValue) => {
    setTab(newValue)
  }

  return (
    <PageContainer>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Customers" value="Customer" />
            <Tab label="Bulk Reserve Sources" value="BulkReserveSource" />
            <Tab label="Meters" value="InstalledMeter" />
          </TabList>
        </Box>
        <TabPanel value="Customer">
          <TargetList target={tab} targetLabel="Customer" />
        </TabPanel>
        <TabPanel value="BulkReserveSource">
          <TargetList target={tab} targetLabel="Bulk Reserve Source" />
        </TabPanel>
        <TabPanel value="InstalledMeter">
          <TargetList target={tab} targetLabel="Meter" />
        </TabPanel>
      </TabContext>
    </PageContainer>
  )
}

export default List