/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow, Button, Typography } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { InstalledMeter, StylesObject } from "types"
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { errorStringsFromError } from 'utils'


const AvailabilityGrid = () => {
  const { id } = useCommonRouteParams()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [installedMeters, actions] = useResource<InstalledMeter>("installedMeters", undefined, ({ resourceName, performRequest, setState, replace }) => ({
    updateAvailabilities: async (item, options = {}) => {
      const { data: selected } = await performRequest(setState, resourceName, 'updateAvailabilities', { ...item, options })
      setState(prevState => ({ ...prevState, selected, list: replace(prevState.list, selected) }))
      return selected
    },
  }))

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'recurringAvailabilityTable' })
    }
  }, [id])

  const installedMeter: Partial<InstalledMeter> = installedMeters.selected ?? {}
  const [grid, setGrid] = useState<Array<any> | null>(null)
  useEffect(() => {
    setGrid(installedMeter?.recurringAvailabilityTable?.schedule || [])
  }, [installedMeter?.recurringAvailabilityTable?.schedule])

  const statuses = installedMeter?.canBeSwitchedOff ? ['armed','disarmed','off'] : ['armed','disarmed']
  const handleCellClick = (tradingPeriod, idx) => () => {
    setGrid(
      grid!.map(row => {
        if (row[0] === tradingPeriod) {
          const newRow = [...row]
          newRow[idx + 1] = statuses[(statuses.indexOf(row[idx + 1]) + 1) % statuses.length]
          return newRow
        } else {
          return row
        }
      })
    )
  }

  const clearGrid = () => {
    setGrid(
      Array.from({ length: 48 }, (x, i) => [i + 1, true, true, true, true, true, true, true])
    )
  }

  const renderAvailabilityTable = (rows) =>
    <Table size="small" css={{ width: 'initial', marginRight: 24, marginBottom: 24, borderCollapse: 'collapse', textAlign: 'center' }}>
      <TableBody>
        <TableRow>
          <TableCell variant="head" css={styles.availCell}>Period</TableCell>
          <TableCell variant="head" css={styles.availCell}>Time</TableCell>
          <TableCell variant="head" css={styles.availCell}>Mon</TableCell>
          <TableCell variant="head" css={styles.availCell}>Tue</TableCell>
          <TableCell variant="head" css={styles.availCell}>Wed</TableCell>
          <TableCell variant="head" css={styles.availCell}>Thu</TableCell>
          <TableCell variant="head" css={styles.availCell}>Fri</TableCell>
          <TableCell variant="head" css={styles.availCell}>Sat</TableCell>
          <TableCell variant="head" css={styles.availCell}>Sun</TableCell>
        </TableRow>
        {rows.map(([tradingPeriod, ...days], i) =>
          <TableRow key={i}>
            <TableCell css={styles.availCell}>{tradingPeriod}</TableCell>
            <TableCell css={styles.availCell}>{tradingPeriod > 48 ? '' : new Date((tradingPeriod - 1) * 1800000).toISOString().substring(11, 16)}</TableCell>
            {days.map((dow, idx) => <AvailabilityCell key={idx} status={dow} onClick={handleCellClick(tradingPeriod, idx)} />)}
          </TableRow>
        )}
      </TableBody>
    </Table>

  const handleSave = () => {
    actions.updateAvailabilities({ id: id, availabilityTable: grid }, { include: 'recurringAvailabilityTable' })
      .then(() => history.push(`/installed_meters/${id}?availability=open`))
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  return (
    <PageContainer>
      <PageHeader title={`Edit Recurring Weekly Availability Schedule`} />
      <Typography variant='h5' css={{ marginBottom: 24 }}>{`Meter: ${installedMeter.meterSerial}`}</Typography>
      {grid ? <div css={{ marginBottom: 24 }}>
        <div css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {renderAvailabilityTable(grid?.filter(([tp]) => tp < 25))}
          {renderAvailabilityTable(grid?.filter(([tp]) => tp > 24))}
        </div>
      </div> : 'Loading...'}

      <div css={{ display: 'flex' }}>
        <Button variant='contained' css={{ flex: 1 }} onClick={clearGrid}>Clear</Button>
        <Button color='secondary' variant='contained' css={{ flex: 1 }} onClick={handleSave} disabled={!grid}>Save</Button>
      </div>
    </PageContainer >
  )
}

const styles = {
  availCell: {
    border: 'solid 1px silver',
    width: 36,
    textAlign: 'center',
    padding: '0px 8px',
    lineHeight: 1.5,
  }
} as StylesObject

const statusFgColors = {
  armed: 'green',
  disarmed: 'red',
  off: 'white',
}

const statusBgColors = {
  armed: '#c6efce',
  disarmed: '#ffc7ce',
  off: 'gray',
}

const statusTexts = {
  armed: 'Yes',
  disarmed: 'No',
  off: 'Off',
}

const AvailabilityCell = ({ status, onClick }) =>
  <TableCell onClick={onClick} css={{
    ...styles.availCell,
    backgroundColor: statusBgColors[status] || 'gray',
    color: statusFgColors[status] || 'black',
    cursor: 'pointer',
    userSelect: 'none',
  }}>
    {statusTexts[status] || ''}
  </TableCell>

export default AvailabilityGrid