/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { Pagination, ErrorBanner, PageContainer, PageHeader, ConfirmationDialog, SearchCombo, LabeledSelect, DatePicker } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  Button,
  MenuItem,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDateTime, humanize } from 'utils'
import { usePagination, useDependency, useNotifications, useFilter, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { NotificationType, StylesObject } from "types"
import { useDashboard } from 'contexts/DashboardContext'

const List = () => {
  const [notifications, actions] = useNotifications()
  const [notificationTypes, typeActions] = useResource<NotificationType>('notificationTypes')
  const dashboard = useDashboard()
  const history = useHistory()

  const [page, setPage] = usePagination("notifications")
  const [selected, setSelected]: any = useState([])
  const [filter, setFilter] = useFilter(setPage, 'notifications')

  const fetchNotifications = () => {
    if (!notificationTypes?.list?.length) {
      typeActions.index({})
    }
    return actions.index(
      {
        page: page,
        sort: '-createdAt',
        pageSize: 30,
        filter
      }
    )
  }

  useDependency(fetchNotifications, [page, filter])
  useEffect(() => setSelected([]), [page, filter])

  const numSelected = selected.length
  const rowCount = notifications.list.length
  const canMarkAsRead = numSelected > 0 && selected.some(id => !notifications.list.find(n => n.id === id)?.readAt)
  const canMarkAsUnread = numSelected > 0 && selected.some(id => notifications.list.find(n => n.id === id)?.readAt)

  const handleSelectAllClick = () => setSelected(selected.length === rowCount ? [] : notifications.list.map(({ id }) => id))

  const handleSelectClick = notification => event => {
    event.stopPropagation()
    setSelected(event?.target?.checked ? [...selected, notification.id] : selected.filter(id => id !== notification.id))
  }

  const handleMarkNotifications = async (markAs) => {
    await actions.mark({ [markAs]: selected })
    fetchNotifications()
    dashboard.fetchNotifications() // refresh dashboard alert count
    setSelected([])
  }

  const [confirmMarkAll, setConfirmMarkAll] = useState('')
  const handleMarkAllNotifications = async (markAs) => {
    setConfirmMarkAll('')
    await actions.mark({ all: markAs })
    fetchNotifications()
    dashboard.fetchNotifications() // refresh dashboard alert count
    setSelected([])
  }

  const showNotification = ({ id }) => () => {
    actions.mark({'read': [id]})
    history.push(`/notifications/${id}`)
  }

  const renderNotificationRowItem = (notification) => {
    const { id, fullMessage, createdAt, readAt } = notification
    const fullMessageLines = notification.fullMessage?.split("\n")
    const shortenedFullMessage = fullMessageLines?.length > 1 ? <>{fullMessageLines[0]}<br />...</> : <>{fullMessage}</>
    return (
      <TableRow onClick={showNotification(notification)} key={id} css={readAt ? styles.readNotification : styles.unreadNotification}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected.includes(notification.id)}
            onClick={handleSelectClick(notification)}
          />
        </TableCell>
        <TableCell>
          {shortenedFullMessage}
        </TableCell>
        <TableCell>
          {formatLocaleDateTime(createdAt)}
        </TableCell>
        <TableCell>
          {formatLocaleDateTime(readAt)}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>

      <PageHeader title="Notifications" actions={
        <>
          <Button onClick={() => setConfirmMarkAll('read')}> Mark All As Read </Button>
          <Button disabled={!canMarkAsRead} onClick={() => handleMarkNotifications('read')}> Mark as Read </Button>
          <Button disabled={!canMarkAsUnread} onClick={() => handleMarkNotifications('unread')}> Mark as Unread </Button>
        </>
      } />

      <ErrorBanner>
        {apiErrorStringsFor(notifications, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={notifications.totalPages} page={page} onPageSelected={setPage} startAdornment={
        <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
          <LabeledSelect fullWidth name='type'>
            {notificationTypes.list.map(({ id, label }) =>
              <MenuItem key={id} value={id}>{humanize(label)}</MenuItem>
            )}
          </LabeledSelect>
          <DatePicker fullWidth name='CreatedDate' />
          <LabeledSelect name="read" fullWidth options={{ '': "All", 'true': "Read", "false": "Unread" }} />
        </SearchCombo>
      } />

      <TableContainer>
        <Table
          size='medium'
          aria-label="notifications"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all notifications' }}
                />
              </TableCell>
              <TableCell>Notification</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Read At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.list.map(renderNotificationRowItem)}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination totalPages={notifications.totalPages} page={page} onPageSelected={setPage} />
      <ConfirmationDialog open={!!confirmMarkAll} title={`Mark all notifications as ${confirmMarkAll}?`}
        onConfirm={() => handleMarkAllNotifications('read')} onCancel={() => setConfirmMarkAll('')} />
    </PageContainer>
  )
}

const styles = {
  readNotification: {
    cursor: 'pointer',
  },
  unreadNotification: {
    cursor: 'pointer',
    '& .MuiTableCell-root': {
      fontWeight: 700
    }
  },
} as StylesObject

export default List