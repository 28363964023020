/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { MeterReadUpload } from "types"
import { humanize } from 'utils'
import { useEffect, useState } from 'react'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [meterReadUploads, actions] = useResource<MeterReadUpload>("meterReadUploads")
  const meterReadUpload: Partial<MeterReadUpload> = meterReadUploads.selected ?? {}

  const [refreshCount, setRefreshCount] = useState(0)
  useEffect(() => {
    if (meterReadUpload?.status === 'pending' && refreshCount < 30) {
      const timer = setTimeout(() => {
        setRefreshCount(i => (i+1))
      }, refreshCount > 10 ? 30000 : 3000);
      return () => clearTimeout(timer);
    }
  }, [meterReadUpload?.status, refreshCount])

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'installed_meter' })
    }
  }, [id, refreshCount])

  return (
    <PageContainer>
      <PageHeader title="Meter Read Upload" />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head' css={{ width: 130 }}>Status</TableCell>
            <TableCell><span css={{ color: statusColors[meterReadUpload?.status || ''] }}>{humanize(meterReadUpload.status || '')}</span></TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Filename</TableCell>
            <TableCell>{String(meterReadUpload.filename || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Meter Serial</TableCell>
            <TableCell>{String(meterReadUpload?.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Overwrite Existing Data</TableCell>
            <TableCell>{String(meterReadUpload.overwrite ? 'True' : 'False')}</TableCell>
          </TableRow>
          {meterReadUpload?.messages && <TableRow>
            <TableCell variant='head'>Messages</TableCell>
            <TableCell><ul css={{ padding: 0 }}>{meterReadUpload.messages.map(msg => <li>{msg}</li>)}</ul></TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}


export default Show