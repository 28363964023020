/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { useFilter, useQueryString, usePlot, useDependency } from 'hooks'
import { ControlledForm, Autocomplete, DateTimePicker, MultiSelect, ExportButton } from 'components'
import { TimeSeriesPlot, GroupOptions }  from 'components/charts'
import { StylesObject } from "types"
import { GridNodes } from '../../constants'
import { formatPickerDateTime } from 'utils'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const SCHEDULES = [
  "Final",
  "Interim"
]

const MARKETS = [
  {name: 'Energy', id: 'E'},
  {name: 'Reserves', id: 'R'},
]

const PRICE_TYPES = [
  {name: 'FIR', id: 'fir_price'},
  {name: 'SIR', id: 'sir_price'},
]

const DEFAULT_GRID_NODES = ['BEN2201', 'HAY2201']

const Prices = () => {
  
  const [urlState, setUrlState] = useQueryString()
  const [filter, setFilter] = useFilter(setUrlState, 'prices')
  const [plotState, plotActions] = usePlot("prices")
  const plot = plotState.plot
  const [dygraphOptions, setDygraphOptions] = useState({})

  const priceTypes = filter.priceTypes || []
  const schedules = filter.schedules || []
  const markets = filter.markets || []
  const gridNodes = filter.gridNodes || []

  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0){
      setFilter({
        gridNodes: DEFAULT_GRID_NODES,
        from: formatPickerDateTime(moment().subtract(7, 'days').startOf('day')),
        priceTypes: ["fir_price", "sir_price"],
        schedules: ["Final"],
        markets: ["R"],
      })
    }
  }, [])

  const [loading] = useDependency( async () => {
    if (gridNodes.length > 0) {
      const timeSeries: any = []
      priceTypes.forEach(priceType => 
        schedules.forEach(schedule => 
          markets.forEach(market => 
            gridNodes.forEach(node =>

              timeSeries.push({
                series: priceType,
                label: `${PRICE_TYPES.find(pt => pt.id === priceType)?.name} ${node} ${schedule} ${MARKETS.find(m => m.id === market)?.name}`,
                group: filter.group,
                filter: {
                  node,
                  schedule,
                  marketType: market,
                  tradingDatetimeFrom: filter.from,
                  tradingDatetimeTo: filter.to,
                }
              })
      ))))      

      await plotActions.plot({timeSeries})
    }
  }, [JSON.stringify(filter)])

  const onSuggestionsRequestedNode = async (text, callback) => {
    callback(GridNodes.filter(node => node.includes((text || '').toUpperCase())))
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}>
        <Autocomplete
          css={styles.leftFilter}
          fullWidth
          multiple
          options={GridNodes}
          name='gridNodes'
          onSuggestionsFetchRequested={onSuggestionsRequestedNode}
          value={gridNodes}
        />
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.filterRow}>
        <div css={styles.filter}>
          <span css={styles.multiSelectLabel}> Price Type </span>
          <MultiSelect
            css={styles.filter}
            options={PRICE_TYPES as any}
            name="priceTypes"
          />
        </div>
        <div css={styles.filter}>
          <span css={styles.multiSelectLabel}> Schedule </span>
          <MultiSelect
            css={styles.filter}
            options={SCHEDULES}
            name="schedules"
          />
        </div>
        <div css={styles.filter}>
        <span css={styles.multiSelectLabel}> Market </span>
          <MultiSelect
            css={styles.filter}
            options={MARKETS as any}
            name="markets"
          />
        </div>
      </div>
      <div css={styles.filterRow}>
        <div css={styles.groupOptions}>
          <GroupOptions name='group' scope='individual'/>
          <ExportButton
            css={styles.exportButton}
            variant='text'
            resource="prices/plot"
            params={plotState.params}
            disabled={!plot?.values?.length}
            qsOptions={{ arrayFormat: 'brackets'}}
          />
          <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
        </div>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}

      <TimeSeriesPlot
        plot={plot}
        unit="$ "
        appendUnit={false}
        ylabel="Price ($)"
        maxGranularity={Dygraph.Granularity.THIRTY_MINUTELY}
        dygraphOptions={dygraphOptions}
        loading={loading}
      />
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
    marginBottom: 5,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
    marginTop: 'auto',
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
    marginTop: 'auto',
  },
  groupOptions: {
    marginTop: 20,
    width: '100%',
    textAlign: 'right',
  },
  multiSelectLabel: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  }
} as StylesObject

export default Prices