/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import {Button, Table, TableBody, TableCell, TableRow, Tooltip, Typography} from '@mui/material'
import {PageContainer, PageHeader, LinkButton} from 'components'
import {OfferRun} from "types"
import {formatLocaleDateTime, history, humanize} from "utils";
import {Hail} from "@mui/icons-material";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [offerRuns, actions] = useResource<OfferRun>("offerRuns")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'hotWaterBatch'})
    }
  }, [id])

  const offerRun: Partial<OfferRun> = offerRuns.selected ?? {}

  return (
    <PageContainer>
      <PageHeader
        title={`Offer Run - ${formatLocaleDateTime(offerRun.createdAt || '')}`}
        actions={<>
          <Button onClick={() => history.push(`/offers?offerRunId=${id}`)} variant="contained">
            Offers
          </Button>
          <Button onClick={() => history.push(`/offer_run_logs?offerRunLogs.filter.offerRunId=${id}`)} variant="contained">
            Logs
          </Button>
        </>}
      />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>ID</TableCell>
            <TableCell>{offerRun.id || ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Status</TableCell>
            <TableCell>{humanize(offerRun.status || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Started At</TableCell>
            <TableCell>{formatLocaleDateTime(offerRun.createdAt || '', {includeSeconds: true})}</TableCell>
          </TableRow>
          {offerRun.completedAt &&
            <TableRow>
              <TableCell variant='head'>Completed At</TableCell>
              <TableCell>{formatLocaleDateTime(offerRun.completedAt || '', {includeSeconds: true})}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell variant='head'>
              <Tooltip title="Next five-minute point; Offer run is generated as if this is the current time">
                <span>Effective Date Time</span>
              </Tooltip>
            </TableCell>
            <TableCell>{formatLocaleDateTime(offerRun.effectiveDatetime || '', {includeSeconds: true})}</TableCell>
          </TableRow>
          {offerRun.hotWaterBatch &&
            <TableRow>
              <TableCell variant='head'>Hot Water Offers Batch Used</TableCell>
              <TableCell>
                <LinkButton onClick={() => history.push(`/bulk_offer_batches/${offerRun.hotWaterBatch?.id}`)}>
                  {formatLocaleDateTime(offerRun.hotWaterBatch?.createdAt || '')}
                </LinkButton>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show