/** @jsxImportSource @emotion/react */
import React from 'react'
import { useAuthorization } from 'hooks'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { PageContainer } from 'components'
import { Tabs, Tab } from '@mui/material'
import { StylesObject } from "types"
import { Dashboard } from 'containers'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MeterReadIcon from '@mui/icons-material/ElectricMeter'
import ShowerIcon from '@mui/icons-material/Shower';
import CalculateIcon from '@mui/icons-material/Calculate';
import ForecastEvaluationIcon from '@mui/icons-material/Insights'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const Show = () => {
  const match = useRouteMatch<{tab?: string}>()
  const history = useHistory()
  const authorization = useAuthorization()
  const internalUser = authorization.admin || authorization.internal

  const activeTab = match?.params?.tab
  return (
    <PageContainer>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        aria-label="dashboard selection"
        onChange={(event, tab) => history.push(`/dashboard/${tab}`)}
        variant="fullWidth"
      >
        {internalUser && <Tab icon={<InsertDriveFileIcon />} iconPosition="start" value='offer' label="Offers"/>}
        <Tab icon={<MeterReadIcon />} iconPosition="start" value='historic' label="Meter Reads"/>
        <Tab icon={<ForecastEvaluationIcon />} iconPosition="start" value='forecast' label="Forecast"/>
        <Tab icon={<AttachMoneyIcon />} iconPosition="start" value='price' label="Prices"/>
        {(authorization.admin || authorization.external) && <Tab icon={<CalculateIcon />} iconPosition="start" value='settlement' label="Settlements"/>}
        {internalUser && <Tab icon={<ShowerIcon />} iconPosition="start" value='hot_water' label="Hot Water"/>}
        <Tab icon={<ElectricBoltIcon />} iconPosition="start" value='ufe' label="UFE"/>
      </Tabs>
      <div css={styles.tabContent}>
        {internalUser && activeTab === 'offer' && <Dashboard.Offers/>}
        {activeTab === 'historic' && <Dashboard.MeterReads/>}
        {activeTab === 'forecast' && <Dashboard.Forecasts/>}
        {activeTab === 'price' && <Dashboard.Prices/>}
        {(authorization.admin || authorization.external) && activeTab === 'settlement' && <Dashboard.Settlements/>}
        {internalUser && activeTab === 'hot_water' && <Dashboard.HotWater/>}
        {activeTab === 'ufe' && <Dashboard.UnderFrequencyEvents/>}
      </div>
    </PageContainer>
  )
}

const styles = {
  tabContent: {
    marginTop: 20,
  }
} as StylesObject

export default Show