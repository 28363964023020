import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo, LabeledSelect } from 'components'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import { errorStringsFromError, apiErrorStringsFor } from 'utils'
import { useHistory } from 'react-router-dom'
import { useDependency, usePagination, useResource, useAuthorization, useFilter } from 'hooks'
import { useSnackbar } from "contexts/SnackbarContext";
import { User } from "types";
import { TextField } from '@mui/material';

const List = () => {
  const [users, actions] = useResource<User>("users")
  const snackbar = useSnackbar()

  const history = useHistory()
  const authorization = useAuthorization()

  const [page, setPage] = usePagination('books')
  const [filter, setFilter] = useFilter(setPage, 'users')

  const [, , reloadUsers] = useDependency(async () => (
    await actions.index({
      page: page,
      filter: filter,
      fields: { users: 'name,email,unconfirmedEmail,role'  },
      sort: 'name',
    })
  ), [page, filter])

  const showUser = ({ id }) => () => {
    history.push(`/users/${id}`)
  }

  const editUser = ({ id }) => event => {
    history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  const deleteUser = ({ id }: { id: string }) => event => {
    actions.destroy({ id })
      .then(reloadUsers)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const secondaryLine = (user) => {
    if (user.unconfirmedEmail) {
      return <>{user.unconfirmedEmail} (uncomfirmed) <br /> {user.role}</>
    }
    if(user.email) return <>{user.email} <br /> {user.role}</>
    return user.role
  }

  const renderUserListItem = (user) => {
    const { name, id } = user
    return (
      <ListItem button onClick={showUser(user)} key={id}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={secondaryLine(user)}/>
        <ListItemSecondaryAction>
          { authorization.admin && <>
            <IconButton onClick={editUser(user)} size="large"><EditIcon /></IconButton>
            <DeleteConfirmationDialog onConfirm={deleteUser(user)} entityName="user" />
          </>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Users</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(users, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination 
      totalPages={users.totalPages} 
      page={page} 
      onPageSelected={setPage} 
      startAdornment={(
        <SearchCombo searchTextMember={'freeText'} onFilterChange={setFilter} filter={filter}>
          <TextField name="name" fullWidth />
          <LabeledSelect name='role' options={{internal: "Internal", external: "External", admin: "Admin", csc: "CSC"}} fullWidth/>
        </SearchCombo>
      )}/>
      <MuiList dense>
        {users.list.map(renderUserListItem)}
      </MuiList>
      <Pagination totalPages={users.totalPages} page={page} onPageSelected={setPage} />
      { authorization.admin &&
      <FABFixed color='secondary' onClick={() => history.push('/users/new')}>
        <AddIcon />
      </FABFixed>
      }
    </PageContainer>
  )
}

export default List