import Typography from "@mui/material/Typography"
import React, {useState} from "react"
import ConfirmationDialog from "./ConfirmationDialog"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { titleize } from "utils"

export type DeleteConfirmationDialogProps = {
  onConfirm?: (event?: any) => void,
  entityName?: string;
  title?: string;
  children?: React.ReactNode;
}
const DeleteConfirmationDialog = ({ onConfirm, entityName, title, children }: DeleteConfirmationDialogProps) => {
  const [open, setOpen] = useState(false)
  return <>
    <IconButton
      onClick={event => {
        event.stopPropagation()
        setOpen(true)
      }}
      size="large">
      <DeleteIcon />
    </IconButton>
    <ConfirmationDialog
        canCancel
        onConfirm={() => {
          setOpen(false)
          onConfirm && onConfirm()
        }}
        onCancel={() => setOpen(false)}
        open={open}
        title={title ?? `Confirm ${titleize(entityName || 'record')} Removal`}
        confirmLabel="Remove"
    >
      {children ?? <Typography variant="body2">
        Are you sure you want to remove this {titleize(entityName || 'record')}?
      </Typography>}
    </ConfirmationDialog>
  </>;
}

export default DeleteConfirmationDialog