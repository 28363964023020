/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Badge, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { StylesObject } from "types"
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useDashboard } from 'contexts/DashboardContext'

const NOTIFICATION_REFRESH_TIME_MS = 15000

const Alerts = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const dashboard = useDashboard()
  const notificationsCount = dashboard.notifications.length

  useEffect(() => {
    dashboard.fetchNotifications()
    const timer = setInterval(dashboard.fetchNotifications, NOTIFICATION_REFRESH_TIME_MS)
    return () => clearTimeout(timer)
  }, [])

  const handleMenuOpen = event => setAnchorEl(event.currentTarget)

  const handleMenuClose = () => setAnchorEl(null)

  const showNotification = (id) => () => {
    dashboard.markNotification(id)
    history.push(`/notifications/${id}`)
    handleMenuClose()
  }

  const showNotificationList = () => {
    history.push('/notifications')
    handleMenuClose()
  }

  const renderMenuItem = (notification, index) => {
    const { alertLevel, shortMessage } = notification
    return (
      <MenuItem key={index} dense onClick={showNotification(notification.id)} css={styles.notification}>
        <ListItemIcon>{renderMenuIcon(alertLevel)}</ListItemIcon>
        <ListItemText primary={shortMessage} />
      </MenuItem>
    )
  }

  const renderMenuIcon = alertLevel => {
    switch (alertLevel) {
      case 'low': return <InfoOutlinedIcon color='primary' />
      case 'medium': return <WarningIcon color='warning' />
      case 'high': return <ErrorOutlineIcon color='error' />
      default: return null
    }
  }

  return (
    <>
      <IconButton aria-label="alerts" aria-controls="alert-menu" aria-haspopup="true" onClick={handleMenuOpen}>
        <Badge badgeContent={`${notificationsCount}${dashboard.maxNotifications ? '+' : ''}`} color={notificationsCount > 0 ? 'error' : 'info'}>
          {notificationsCount > 0 ? <NotificationsIcon css={styles.notificationIcon} /> : <NotificationsNoneIcon css={styles.notificationIcon} />}
        </Badge>
      </IconButton>
      <Menu
        id="alert-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        elevation={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem dense onClick={showNotificationList} css={styles.viewAll}>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="View all notifications" />
        </MenuItem>
        {dashboard.notifications.map((item, index) => renderMenuItem(item, index))}
      </Menu>
    </>
  )
}

const styles = {
  notificationIcon: {
    color: '#fff'
  },
  notification: {
  },
  viewAll: {
    borderBottom: '1px solid #E8E8E8',
    textAlign: 'center'
  }
} as StylesObject

export default Alerts