/** @jsxImportSource @emotion/react */
import React  from 'react'
import {
  Pagination,
  ErrorBanner,
  PageContainer,
  PageHeader,
  ExportButton,
  SearchCombo,
  LabeledSelect,
  DatePicker, AutocompleteSearch
} from 'components'
import {
  Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime, humanize} from 'utils'
import {usePagination, useDependency, useResource, useFilter} from 'hooks'
import { useHistory } from 'react-router-dom'
import { Invoice } from "types"

const List = () => {
  const [invoices, actions] = useResource<Invoice>("invoices")
  const history = useHistory()

  const [page, setPage] = usePagination("invoices")
  const [filter, setFilter] = useFilter(setPage, 'invoices')


  useDependency(() => (
    actions.index({
      page: page,
      include: 'customer',
      sort: '-id',
      filter,
    })
  ), [page, filter])

  const showInvoice = ({id}) => () => {
    history.push(`/invoices/${id}`)
  }

  const renderInvoiceListItem = (invoice) => {
    const { id, dateFrom, dateTo, customer, settledRevenue, actualRevenue, bctiStatus } = invoice
    return (
      <TableRow key={id} onClick={showInvoice(invoice)} css={{ cursor: 'pointer' }}>
        <TableCell>{customer.name}</TableCell>
        <TableCell>{formatLocaleDate(dateFrom)} - {formatLocaleDate(dateTo)}</TableCell>
        <TableCell>${parseFloat(settledRevenue).toFixed(2)}</TableCell>
        <TableCell>${parseFloat(actualRevenue ?? settledRevenue).toFixed(2)}</TableCell>
        <TableCell>{bctiStatus ? humanize(bctiStatus) : 'Not sent'}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title="Invoices" actions={
        <ExportButton resource="invoices" filter={filter} sort="id" />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(invoices, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={invoices.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
                      <LabeledSelect name="approved" fullWidth options={{"true": "Yes", "false": "No"}} />
                      <DatePicker name="date" fullWidth />
                      <AutocompleteSearch name="invoiceRun" getOptionLabel={(run) => `${run.startedAt ? formatLocaleDateTime(run.startedAt) : ''}`} searchableField='startedAt' />
                      <AutocompleteSearch name="customer"/>
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Period</TableCell>
            <TableCell>Settled Revenue</TableCell>
            <TableCell>Actual Revenue</TableCell>
            <TableCell>BCTI Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.list.map(renderInvoiceListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={invoices.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List