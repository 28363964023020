/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource, useAuthorization } from 'hooks'
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { PageHeader, PageContainer, ExportButton } from 'components'
import { UnderFrequencyEvent } from "types"
import { formatLocaleDateTime } from "utils";
import { List as TrippedMeters } from '../tripped_installed_meters'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [underFrequencyEvents, actions] = useResource<UnderFrequencyEvent>("underFrequencyEvents")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const underFrequencyEvent: Partial<UnderFrequencyEvent> = underFrequencyEvents.selected ?? {}

  const authorization = useAuthorization()
  const internalUser = authorization.admin || authorization.internal

  return (
    <PageContainer>
      <PageHeader title={`Under Frequency Event - ${formatLocaleDateTime(underFrequencyEvent.startedAt, { includeSeconds: true })}`} actions={
        internalUser ? <>
          <ExportButton resource="under_frequency_events" urlPath={`under_frequency_events/${id}/download_data.csv`} label="CSV" />
          <ExportButton resource="under_frequency_events" urlPath={`under_frequency_events/${id}/download_data.xlsx`} label="XLSX" />
        </> : null
      } />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Started At</TableCell>
            <TableCell>{underFrequencyEvent.startedAt ? formatLocaleDateTime(underFrequencyEvent.startedAt, { includeSeconds: true }) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Ended At</TableCell>
            <TableCell>{underFrequencyEvent.endedAt ? formatLocaleDateTime(underFrequencyEvent.endedAt, { includeSeconds: true }) : 'In Progress'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Event Reference</TableCell>
            <TableCell>{String(underFrequencyEvent.eventReferenceId || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <Typography variant="h5">Tripped Devices</Typography>
      {id &&
        <TrippedMeters underFrequencyEventId={id} />
      }
    </PageContainer>
  )
}

export default Show