/** @jsxImportSource @emotion/react */
import React, {ReactElement} from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, PageHeader } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {apiErrorStringsFor, formatLocaleDateTime} from 'utils'
import {usePagination, useDependency, useResource, useFilter} from 'hooks'
import { useHistory } from 'react-router-dom'
import { OfferRunLog } from "types"
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

const List = () => {
  const [offerRunLogs, actions] = useResource<OfferRunLog>("offerRunLogs")
  const history = useHistory()
  const [page, setPage] = usePagination("offerRunLogs")
  const [filter, ] = useFilter(setPage, 'offerRunLogs')

  useDependency(() => (
    actions.index({
      page: page,
      fields: {offerRunLogs: 'message,createdAt,severity'},
      sort: 'id',
      filter,
    })
  ), [page, filter])

  const showOfferRunLog = ({id}) => () => {
    history.push(`/offer_run_logs/${id}`)
  }

  const renderOfferRunLogListItem = (offerRunLog) => {
    const { id, message, createdAt, severity } = offerRunLog
    let icon: ReactElement
    switch (severity) {
      case 'error':
        icon = <ErrorIcon />
        break
      case 'warning':
        icon = <WarningIcon />
        break
      default:
        icon = <InfoIcon />
    }
    return (
      <ListItem button onClick={showOfferRunLog(offerRunLog)} key={id}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={message} secondary={formatLocaleDateTime(createdAt, {includeSeconds: true})}/>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Offer Run Logs`} />
      <ErrorBanner>
        {apiErrorStringsFor(offerRunLogs, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={offerRunLogs.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {offerRunLogs.list.map(renderOfferRunLogListItem)}
      </MuiList>
      <Pagination totalPages={offerRunLogs.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List