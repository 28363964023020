import {useSnackbar} from "contexts/SnackbarContext";
import {Actions} from "./useResource";
import useCommonRouteParams from "./useCommonRouteParams";
import pluralize from 'pluralize'
import {capitalize, formDataToJsonApi, FormDataToJsonApiOpts, RecursivePartial} from "utils";
import useRedirect from "./useRedirect";

export type DefaultSaveOpts = {redirectTo?: string | Function} & FormDataToJsonApiOpts

// Returns a `save` function that will handle the creation or update of the resource
const useDefaultSave = <ResourceT = Record<string, any>>(formData: RecursivePartial<ResourceT>, actions: Actions<ResourceT>, {redirectTo, ...opts}: DefaultSaveOpts = {}) => {
  const snackbar = useSnackbar()
  const redirect = useRedirect()
  const { action, type } = useCommonRouteParams()
  const resourceAction = action === "edit" ? actions.update : actions.create

  const save = async () => {
    const attributesToSave = formDataToJsonApi(formData, opts)
    const subject = attributesToSave.name ? `"${attributesToSave.name}"` : capitalize(pluralize.singular(type))
    try {
      const result = await resourceAction(attributesToSave)
      if (typeof redirectTo === 'function') {
        redirect(redirectTo(result))
      } else {
        redirect(redirectTo)
      }
      snackbar && snackbar.show(`Saved ${subject}`)
    } catch(err){
      console.error(err)
      snackbar && snackbar.show(`Error Saving ${subject}`)
    }
  }

  return save
}


export default useDefaultSave