/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { MeterTestResultUpload, StylesObject } from "types"
import { formatLocaleDateTime } from 'utils'
import { List as MeterTestResultsList } from "containers/meter_test_results"
import { Plot as MeterTestResultsPlot } from "containers/meter_test_results"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [meterTestResultUploads, actions] = useResource<MeterTestResultUpload>("meterTestResultUploads")
  const meterTestResultUpload: Partial<MeterTestResultUpload> = meterTestResultUploads.selected ?? {}

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'installed_meter' })
    }
  }, [id])

  return (
    <PageContainer>
      <PageHeader title="Meter Test Result Upload" />
      <br/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>File</TableCell>
            <TableCell>
                <a css={styles.downloadLink} href={meterTestResultUpload.fileUrlPath} target='_blank' rel="noopener noreferrer">
                  {String(meterTestResultUpload.filename || '')}
                </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Meter Serial</TableCell>
            <TableCell>{String(meterTestResultUpload?.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Created At</TableCell>
            <TableCell>{formatLocaleDateTime(meterTestResultUpload.createdAt)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <MeterTestResultsPlot meterTestResultUploadId={id} />
      <MeterTestResultsList meterTestResultUploadId={id} />
    </PageContainer>
  )
}

const styles = {
  downloadLink: {
    textDecoration: 'underline',
    color: 'rgb(0, 130, 200)'
  },
} as StylesObject

export default Show