/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import {TextField} from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import { ControlledForm, ErrorBanner, LabeledSelect, LabeledSwitch, PageContainer, PageHeader, PercentageField, PromiseButton } from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { ConfigOption } from "types"

const Form = () => {
  const { editMode, id, resourceAction } = useCommonRouteParams()
  const [configOptions, actions] = useResource<ConfigOption>("configOptions")
  const [formData, setFormData] = useState<Partial<ConfigOption>>({})
  const handleSave = useDefaultSave(formData, actions)

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!)
      setFormData(result)
    }
  }, [editMode, id])

  const renderValueField = (dataType) => {
    if (dataType === 'string') {
      return (<TextField fullWidth name='currentValue' />)
    } else if (dataType === 'number') {
      return (<TextField fullWidth name='currentValue' type="number" />)
    } else if (dataType === 'percentage') {
      return (<PercentageField fullWidth name='currentValue' />)
    } else if (dataType === 'integer') {
      return (<TextField fullWidth name='currentValue' type="number" inputProps={{ step: "1" }} />)
    } else if (dataType === 'boolean') {
      return (<LabeledSwitch name='currentValue' />)
    }
    return null;
  }

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Config Option - ${formData.name}` : 'Create a new Config Option'} />
      <ControlledForm data={formData} errors={formErrors(configOptions, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(configOptions, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='name' disabled={editMode} />
        <LabeledSelect fullWidth name='dataType' options={dataTypes} disabled={editMode} />
        <TextField fullWidth name='description' disabled={editMode} />
        {renderValueField(formData.dataType)}
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const dataTypes = { string: 'String', percentage: 'Percentage', integer: 'Integer', number: 'Number', boolean: 'Boolean' }

export default Form