/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { PageContainer, LinkButton, PageHeader } from 'components'
import { Offer } from "types"
import {formatLocaleDate, formatLocaleDateTime, titleize, history} from "utils";
import {List as OfferedForecastsList} from "../offered_forecasts";
import { OfferedForecasts } from 'api'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [offers, actions] = useResource<Offer>("offers")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'offerRun,offeredBulkOffers,offeredBulkOffers.bulkOffer,offeredBulkOffers.bulkOffer.bulkReserveSource,offeredForecasts.forecast.installedMeter'})
    }
  }, [id])

  const offer: Partial<Offer> = offers.selected ?? {}

  const renderBulkOfferListItem = (offeredBulkOffer) => {
    const { id, firOffer, sirOffer, bulkOffer } = offeredBulkOffer
    return (
      <TableRow key={id}>
        <TableCell>{firOffer} MW</TableCell>
        <TableCell>{sirOffer} MW</TableCell>
        <TableCell>{bulkOffer?.bulkReserveSource?.name}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Offer - ${formatLocaleDate(offer.tradingDate)} TP ${offer.tradingPeriod}`} />
      <Table>
        <TableBody>
            <TableRow css={{width: 130}}>
              <TableCell variant='head'>ID</TableCell>
              <TableCell>{String(offer.id || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>GXP</TableCell>
              <TableCell>{String(offer.gxp || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading date</TableCell>
              <TableCell>{formatLocaleDate(offer.tradingDate || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading period</TableCell>
              <TableCell>{String(offer.tradingPeriod || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Fir offer</TableCell>
              <TableCell>{String(offer.firOffer || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Sir offer</TableCell>
              <TableCell>{String(offer.sirOffer || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Status</TableCell>
              <TableCell>{titleize(offer.status)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Bona fide</TableCell>
              <TableCell>{offer.bonaFide ? "Yes" : "No"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Acknowledged At</TableCell>
              <TableCell>{offer.acknowledgedAt ? formatLocaleDateTime(offer.acknowledgedAt) : 'Not Acknowledged Yet'}</TableCell>
            </TableRow>
            {offer.error &&
              <TableRow>
                <TableCell variant='head'>Error</TableCell>
                <TableCell>{offer.error}</TableCell>
              </TableRow>
            }
            {offer.offerRun &&
              <TableRow>
                <TableCell variant='head'>Offer Run</TableCell>
                <TableCell onClick={() => history.push(`/offer_runs/${offer.offerRun?.id}`)}>
                  <LinkButton>Run at {formatLocaleDateTime(offer.offerRun?.createdAt)}</LinkButton>
                </TableCell>
              </TableRow>
            }
        </TableBody>
      </Table>
      <br/>
      <Typography variant="h6">Bulk Offers</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>FIR Offer</TableCell>
            <TableCell>SIR Offer</TableCell>
            <TableCell>Source Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offer.offeredBulkOffers?.map(renderBulkOfferListItem)}
        </TableBody>
      </Table>
      <br/>
      <Typography variant="h6">Offered Forecasts</Typography>
      <OfferedForecastsList offeredForecasts={offer.offeredForecasts ?? []} />
    </PageContainer>
  )
}

export default Show