import React from 'react'
import { MicrosoftService } from 'services'
import { Button } from '@mui/material'

const MicrosoftSignInButton = ({label}) => {

  const authorize = () => {
    MicrosoftService.authorize()
  }

  return(
    <Button variant="contained" color="primary" onClick={authorize} fullWidth>{label}</Button>
  )
}


export default MicrosoftSignInButton