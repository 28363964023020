/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { PageContainer, LinkButton, PageHeader } from 'components'
import { BulkOffer } from "types"
import {formatLocaleDate, history} from "utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [bulkOffers, actions] = useResource<BulkOffer>("bulkOffers")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'bulkReserveSource'})
    }
  }, [id])

  const bulkOffer: Partial<BulkOffer> = bulkOffers.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Bulk Offer - ` + bulkOffer.bulkReserveSource?.name + ` ` + formatLocaleDate(bulkOffer.tradingDate || '') + ` TP ` + (bulkOffer.tradingPeriod || '')}/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Batch</TableCell>
            <TableCell>
              <LinkButton onClick={() => history.push(`/bulk_offer_batches/${bulkOffer.bulkOfferBatchId}`)}>
                {String(bulkOffer.bulkOfferBatchId || '')}
              </LinkButton>
            </TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>Trading date</TableCell>
              <TableCell>{formatLocaleDate(bulkOffer.tradingDate || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading period</TableCell>
              <TableCell>{String(bulkOffer.tradingPeriod || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Fir offer</TableCell>
              <TableCell>{String(bulkOffer.firOffer || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Sir offer</TableCell>
              <TableCell>{String(bulkOffer.sirOffer || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Bulk reserve source</TableCell>
              <TableCell>
                <LinkButton onClick={() => history.push(`/bulk_reserve_sources/${bulkOffer.bulkReserveSource?.id}`)}>
                  {String(bulkOffer.bulkReserveSource?.name || '')}
                </LinkButton>
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show