/** @jsxImportSource @emotion/react */
import {  CenteredCard } from 'components'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'
import { MicrosoftSignInButton } from 'containers/auth';
import { useEffect } from 'react';
import { MicrosoftService } from 'services'
import { useQueryString } from 'hooks';

const SignIn = () => {
  const [qs] = useQueryString()
  useEffect(() => {
    if (qs.redirect !== 'false') {
      MicrosoftService.authorize()
    }
  }, [])

  return (
    <CenteredCard>
      <CardContent>
        <div css={styles.logoDiv}>
          <img src="/logo.png" alt="WEL Networks Logo" />
        </div>
        <Typography variant='h5' style={{ textAlign: 'center', marginBottom: 32 }}>TradeWEL Sign In</Typography>
      </CardContent>
      <CardActions style={{ paddingBottom: 24 }}>
        <MicrosoftSignInButton label="Click Here To Sign In" />
      </CardActions>
    </CenteredCard>
  )
}

const styles = {
  logoDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 36,
  }
}

export default SignIn