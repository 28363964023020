import useResource, {Actions, ResourceError, ResourceState} from "hooks/useResource";
import {SessionStatus} from "../constants";
import * as API from "api"
import { history, decodeJSONApiResponse } from 'utils'
import { MicrosoftService, TokenStore } from 'services'
import jwt_decode from 'jwt-decode'
import React, {useContext, useEffect, useRef} from "react";
import {User} from "types";

export const SECOND                  = 1000
export const MINUTE                  = 60 * SECOND
export const INACTIVITY_PERIOD       = 5  * MINUTE
export const REFRESH_TOKEN_THRESHOLD = 10 * SECOND

type ValueOf<T> = T[keyof T];

export type Token = {
  auth: string;
  refresh: string;
}

export type LogInCredentials = {
  email: string;
  password: string;
  otpAttempt?: string;
  rememberMe?: boolean;
}

export type ResendConfirmationParams = {email: string};
export type ForgotPasswordParams = {email: string};

export type AdditionalTokenState = {
  loginState: ValueOf<typeof SessionStatus>;
  currentUser?: User;
  exp?: number;
  savedLocation: string | null;
}

export type TokensResource = [
  ResourceState<Token> & AdditionalTokenState,
  TokensActions
]
export type TokensActions = Omit<Actions<Token, AdditionalTokenState>, "create" | "destroy"> & {
  create: (credentials: LogInCredentials) => Promise<void>;
  verify: (initial?: boolean) => Promise<void>;
  destroy: (b2cSignOut: boolean) => Promise<void>;
  refresh: (saveLocation?: boolean) => Promise<void>;
  registerActivity: () => void;
  clearSavedWindowLocation: () => void;
}
export type PasswordChangeParams = {
  password: string;
  passwordConfirmation: string;
  token: string;
}
const TokensContext = React.createContext<TokensResource | undefined>(undefined);

export const TokensProvider = ({ children }) => {
  const tokens = useTokensResource()

  return (
    <TokensContext.Provider value={tokens}>
      {children}
    </TokensContext.Provider>
  )
}

export const useTokens = () => {
  const tokens = useContext<TokensResource | undefined>(TokensContext)
  if (!tokens) {
    throw new Error("TokensContext must be provided in order to use it")
  }

  return tokens
}

const useTokensResource = (): TokensResource => {
  const additionalInitialState = {
    loginState: SessionStatus.UNKNOWN,
    savedLocation: null
  }

  const lastActivityRef = useRef<number | undefined>();
  useEffect(() => {
    lastActivityRef.current = currentTimestamp()
  }, [])

  const registerActivity = () => {
    lastActivityRef.current = currentTimestamp()
  }

  const [state, actions] = useResource<Token, AdditionalTokenState>("tokens", additionalInitialState);

  // This ref is needed to get current value inside interval closure
  const tokenRef = useRef<TokensResource[0]>(state);
  tokenRef.current = state;

  const saveWindowLocation = () => {
    actions.setState(prevState => ({...prevState, savedLocation: window.location.pathname}))
  }

  const clearSavedWindowLocation = () => {
    actions.setState(prevState => ({...prevState, savedLocation: null}))
  }

  const clearTimer = () => {
    if (window.inactivityTimeoutIntervalId) {
      clearInterval(window.inactivityTimeoutIntervalId)
      window.inactivityTimeoutIntervalId = undefined
    }
  }

  const timedOut = () => {
    clearTimer()
    saveWindowLocation()
    history.push('/inactive', {})
  }

  const startInactivityTimeout = () => {
    clearTimer()

    window.inactivityTimeoutIntervalId = setInterval(() => {
      if (!tokenRef.current.exp) {
        return
      }
      const inactivityCheckTime = (tokenRef.current.exp * SECOND) - REFRESH_TOKEN_THRESHOLD
      if(currentTimestamp() > inactivityCheckTime) {
        clearTimer()
        const lastActivity = lastActivityRef?.current
        if (!lastActivity) {
          return
        }
        const inactive = lastActivity < (currentTimestamp() - INACTIVITY_PERIOD)
        inactive ? timedOut() : verify()
      }
    }, SECOND)
  }

  const verify = async (initial?: boolean) => {
    if (initial) {
      saveWindowLocation()
    }
    try {
      const { data: payload } = await API.Tokens.refresh(TokenStore.refresh)
      startInactivityTimeout()
      actions.setState(prevState => ({...prevState, loginState: SessionStatus.AUTHENTICATED, ...decodeToken(payload)}))
    } catch(error) {
      actions.setState(prevState => ({...prevState, loginState: SessionStatus.UNAUTHENTICATED }))
      console.warn("Not logged in")
    }
  }

  return [state, {
    ...actions,
    registerActivity,
    verify,
    clearSavedWindowLocation,
    create: async (credentials) => {
      try{
        actions.setState(prevState => {
          const {create: _, ...errorsWithoutCreate} = prevState.errors
          return {...prevState, errors: errorsWithoutCreate}
        })
        const { data: payload } = await API.Tokens.create(credentials)
        registerActivity()
        startInactivityTimeout()
        actions.setState(prevState => ({...prevState, loginState: SessionStatus.AUTHENTICATED, ...decodeToken(payload)}))
      }catch(error){
        actions.setState(prevState => ({...prevState, loginState: SessionStatus.UNAUTHENTICATED, errors: {...prevState.errors, create: error as ResourceError}}))
        throw error
      }
    },
    destroy: async (b2cSignOut = true) =>{
      clearSavedWindowLocation()
      try{
        await API.Tokens.destroy(true)
      }catch(error){
        throw error
      } finally {
        TokenStore.destroy()
        if (b2cSignOut) {
          MicrosoftService.logout()
        } else {
          actions.setState(prevState => ({...prevState, currentUser: undefined, loginState: SessionStatus.UNAUTHENTICATED }))
        }
      }
    },
    refresh: async (saveLocation=true) => {
      if(saveLocation) {
        saveWindowLocation()
      }

      await API.Tokens.refresh(TokenStore.refresh)
      registerActivity()
      startInactivityTimeout()
    }
  }]
}

const decodeToken = ({ auth: token }) => {
  const { actor, exp } = jwt_decode(token)
  return { currentUser: decodeJSONApiResponse(actor).data, exp }
}

const currentTimestamp = () => {
  return + new Date()
}

export default TokensContext