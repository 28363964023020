/** @jsxImportSource @emotion/react */
import React  from 'react'
import { LinkButton } from 'components'
import {TableHead, TableRow, TableCell, TableBody, Table} from '@mui/material'
import { useHistory } from 'react-router-dom'

const List = ({ offeredForecasts }) => {
  const history = useHistory()

  const showForecast = ({forecastId}) => () => {
    history.push(`/forecasts?forecasts.filter.id=${forecastId}`)
  }

  const renderOfferedForecastListItem = (offeredForecast) => {
    const { id, firOfferMW, sirOfferMW } = offeredForecast
    return (
      <TableRow key={id}>
        <TableCell>{firOfferMW} MW</TableCell>
        <TableCell>{sirOfferMW} MW</TableCell>
        <TableCell>{offeredForecast.forecast.installedMeter.meterSerial}</TableCell>
        <TableCell><LinkButton onClick={showForecast(offeredForecast)}>Show</LinkButton></TableCell>
      </TableRow>
    )
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>FIR Offer</TableCell>
          <TableCell>SIR Offer</TableCell>
          <TableCell>Meter Serial</TableCell>
          <TableCell>Forecast</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {offeredForecasts.map(renderOfferedForecastListItem)}
      </TableBody>
    </Table>
  )
}

export default List