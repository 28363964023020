import useResource, {Actions, ResourceState} from "../useResource";
import {Notification} from "types";
import useRequest from "../useRequest";

export type NotificationsResource = [ResourceState<Notification>, Actions<Notification>]

const useNotifications = (): NotificationsResource => {
  const [state, actions] = useResource<Notification>("notifications")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    mark: async ({read, unread, all}) => performRequest(actions.setState, "notifications", 'mark', {options: {read, unread, all}}),
  }
  return [state, newActions]
}

export default useNotifications
