/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { ForecastRunLog } from "types"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [forecastRunLogs, actions] = useResource<ForecastRunLog>("forecastRunLogs")

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'forecastRun,installedMeter' })
    }
  }, [id])

  const forecastRunLog: Partial<ForecastRunLog> = forecastRunLogs.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title="Forecast Run Log"/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 130 }} variant='head'>Code</TableCell>
            <TableCell>{String(forecastRunLog.code || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Message</TableCell>
            <TableCell>{String(forecastRunLog.message || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Forecast Run</TableCell>
            <TableCell>{String(forecastRunLog.forecastRun?.id || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Installed Meter</TableCell>
            <TableCell>{String(forecastRunLog?.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show