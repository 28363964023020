/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource, useAuthorization } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton, Typography, ListItem, ListItemIcon, ListItemText, List } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, PageHeader } from 'components'
import { Site } from "types"
import { humanize } from 'utils'
import { List as InstalledMetersList } from "containers/installed_meters"
import { useHistory } from 'react-router-dom'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [sites, actions] = useResource<Site>("sites")
  const authorization = useAuthorization()
  const history = useHistory();

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'customer, contacts' })
    }
  }, [id])

  const site: Partial<Site> = sites.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Site - ${site?.customer?.name || ''} - ${String(site.name || '')}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 120 }} variant='head'>Actions</TableCell>
            <TableCell>
              {authorization.internalOrAdmin && <Link to={`/sites/${site.id}/edit`}>
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </Link>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Customer</TableCell>
            <TableCell><Link to={`/customers/${site?.customer?.id}`}>{site?.customer?.name || ''}</Link></TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(site.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>ICP Number</TableCell>
            <TableCell>{String(site.icpNumber || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>GXP</TableCell>
            <TableCell>{String(site.gxp || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Address</TableCell>
            <TableCell>
              <pre css={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
                {site.address || ''}
              </pre>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Pricing Method</TableCell>
            <TableCell>{String(humanize(site.pricingMethod || ''))}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <PageContainer css={{ marginTop: 16, marginBottom: 16 }}>
        <Typography variant='h4' css={{ marginBottom: 20, display: 'flex' }}>Site Contacts</Typography>
        <List>
        {site.contacts?.map(user => (
            <ListItem button onClick={() => {history.push(`/users/${id}`)}} key={user.id}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={user.name} />
            </ListItem>
        ))}
        </List>
      </PageContainer>
      <div>
        <InstalledMetersList siteId={id} />
      </div>
    </PageContainer>
  )
}

export default Show