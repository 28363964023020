import useResource, {Actions, ResourceState} from "../useResource";
import {InvoiceRun} from "types";
import useRequest from "../useRequest";

export type InvoiceRunsResource = [
  ResourceState<InvoiceRun>,
    Actions<InvoiceRun> & {
    override: (data: Partial<InvoiceRun>) => Promise<void>;
    approve: ({id: string}) => Promise<void>;
    retrySendBCTI: ({id: string}) => Promise<void>;
  }
]

const useInvoiceRuns = (): InvoiceRunsResource => {
  const [state, actions] = useResource<InvoiceRun>("invoiceRuns")
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    override: async (data) => {
      await performRequest(actions.setState, "invoiceRuns", 'override', data)
    },
    approve: async (data) => {
      await performRequest(actions.setState, "invoiceRuns", 'approve', data)
    },
    retrySendBCTI: async (data) => {
      await performRequest(actions.setState, "invoiceRuns", 'retrySendBCTI', data)
    },
  }
  return [state, newActions]
}

export default useInvoiceRuns