import React, {useState} from 'react'
import {
  Pagination,
  ErrorBanner,
  PageContainer,
  LabeledSelect,
  SearchCombo,
  DatePicker,
  DateTimePicker,
  ConfirmationDialog, ControlledForm, PageHeader
} from 'components'
import {
  Table, TableBody, TableCell, TableHead, TableRow,
  TextField, Button
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime} from 'utils'
import {usePagination, useDependency, usePrices, useFilter, useAuthorization} from 'hooks'
import { useHistory } from 'react-router-dom'
import {useSnackbar} from "contexts/SnackbarContext";


const List = () => {
  const [prices, actions] = usePrices()

  const history = useHistory()

  const [page, setPage] = usePagination("prices")
  const [filter, setFilter] = useFilter(setPage, 'prices')

  const { internalOrAdmin } = useAuthorization()


  useDependency(() => (
    actions.index({
      page: page,
      sort: '-trading_datetime',
      filter
    })
  ), [page, filter])

  const showPrice = ({id}) => () => {
    history.push(`/prices/${id}`)
  }

  const renderPriceListItem = (price) => {
    const { id, schedule, tradingDate, tradingPeriod, node, firPrice, sirPrice, lastRunTime } = price
    return (
      <TableRow key={id} onClick={showPrice(price)} css={{ cursor: 'pointer' }}>
        <TableCell>{schedule}</TableCell>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>TP {tradingPeriod}</TableCell>
        <TableCell>{node}</TableCell>
        <TableCell>${firPrice}</TableCell>
        <TableCell>${sirPrice}</TableCell>
        <TableCell>{formatLocaleDateTime(lastRunTime)}</TableCell>
      </TableRow>
    )
  }

  const snackbar = useSnackbar()
  const [fetchDialogOpen, setFetchDialogOpen] = useState<boolean>(false)
  const handleRequestFinalPrices = async () => {
    try {
      await actions.manualRequest()
      snackbar.show("Manual request submitted")
    } catch (e) {
      console.error("Manual request failed", e)
      snackbar.show("Failed to request prices")
    }
    setFetchDialogOpen(false)
  }

  const dialog = (
    <ConfirmationDialog open={fetchDialogOpen}
                        title="Request final prices from WITS"
                        confirmLabel="Request"
                        canConfirm={true}
                        onConfirm={handleRequestFinalPrices}
                        onCancel={() => setFetchDialogOpen(false)}>
      <p>New files will be loaded from the SFTP.</p>
      <p>Existing prices will not be updated.</p>
    </ConfirmationDialog>
  )

  return (
    <PageContainer>
      <PageHeader title="Prices" actions={
        internalOrAdmin && <Button variant="contained" onClick={() => setFetchDialogOpen(true)}>Manual Fetch</Button>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(prices, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={prices.totalPages}
                  page={page}
                  onPageSelected={setPage}
                  startAdornment={(
                    <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
                      <LabeledSelect name="schedule" fullWidth options={{"Interim": "Interim", "Final": "Final"}} />
                      <DatePicker name="tradingDateFrom" fullWidth />
                      <DatePicker name="tradingDateTo" fullWidth />
                      <TextField name="tradingPeriod" type="number" fullWidth />
                      <TextField name="node" fullWidth />
                    </SearchCombo>
                  )}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Schedule</TableCell>
            <TableCell>Trading date</TableCell>
            <TableCell>Trading period</TableCell>
            <TableCell>Node</TableCell>
            <TableCell>FIR price</TableCell>
            <TableCell>SIR price</TableCell>
            <TableCell>Last run time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices.list.map(renderPriceListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={prices.totalPages} page={page} onPageSelected={setPage}/>
      {dialog}
    </PageContainer>
  )
}

export default List