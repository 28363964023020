import moment from 'moment'

export function userFriendlyDate(date){
  return moment(date).format('MMM Do YY, h:mm:ss a')
}

export function roundDateOrMoment(date, nearestMins){
  const duration = moment.duration(nearestMins, 'minute')
  return moment(Math.floor((+date) / (+duration)) * (+duration))
}

export function formatLocaleDate(date, {includeYear=true}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function formatLocaleDateTime(date, {includeYear=true, includeSeconds=false, includeMilliseconds=false}={}){
  return date ? moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''} HH:mm${includeSeconds ? ':ss' : ''}${includeMilliseconds ? '.SSS' : ''}`) : ''
}

export function currentISODateString() {
  return moment().format().split('T')[0]
}

export function formatPickerDateTime(date){
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ssZ') : ''
}
