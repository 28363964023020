import { ResourceState, SetState } from "./useResource";
import { capitalize, uuid } from "utils";
import * as API from "api"
import { TokenStore } from "services";
import TokensContext, { TokensResource } from "contexts/TokensContext";
import { useMemo, useContext } from "react";

export type Meta = {
  count?: number;
  page?: number;
  totalPages?: number;
  warnings?: Warning[];
}

export type Warning = {
  message: string;
}

const useRequest = <T extends ResourceState<any>>() => {
  // NOTE: TokensContext might not be available until we initialise the context (useRequest is used in TokensContext)
  const tokenResource = useContext<TokensResource | undefined>(TokensContext) ?? null

  return useMemo(() => performRequest<T>(tokenResource), [tokenResource])
}

const performRequest = <T extends ResourceState<any>>(tokenResource: TokensResource | null) => async (
  setState: SetState<T>,
  resourceName: string,
  actionName: string,
  ...args
) => {
  const requestProxy = { id: uuid() }

  setState(prevState => {
    return ({
      ...prevState,
      requests: { ...prevState.requests, [actionName]: [...(prevState.requests[actionName] || []), requestProxy] },
      count: prevState.count + 1
    })
  })
  let error: any = null
  try {
    const { data, meta } = await apiRequestWithRetry(tokenResource)(resourceName, actionName, ...args)
    printMetaWarnings(resourceName, actionName, meta)
    return { data, meta }
  } catch (err) {
    error = err
    console.error(err)
    throw err
  } finally {
    setState(prevState => {
      let newState = {
        ...prevState,
        errors: { ...prevState.errors, [actionName]: error }
      }
      if (prevState.requests[actionName]) {
        newState.requests[actionName] = prevState.requests[actionName].filter(request => request !== requestProxy)
      }

      return newState
    })
  }
}

const printMetaWarnings = (resourceName: string, actionName: string, meta: Meta | undefined) => {
  try {
    if (meta?.warnings?.length) {
      console.warn(`Warning on fetching ${resourceName}:${actionName}`)
      meta.warnings.forEach(({ message }) => console.warn(message))
    }
  } catch (err) { }
}

const apiRequestWithRetry = (tokensResource: TokensResource | null) => async (resourceName: string, actionName: string, ...args: any[]) => {
  let tokensActions: TokensResource[1] | null = null
  if (tokensResource) {
    [, tokensActions] = tokensResource
  }
  resourceName = capitalize(resourceName)
  const resource = API[resourceName];
  if (!resource) {
    throw new Error(`Required API Resource ${resourceName} not found.`)
  }

  if (!resource[actionName]) {
    throw new Error(`Endpoint "${actionName}" not defined on API.${resourceName})}`)
  }
  try {
    return await resource[actionName](...args)
  } catch (err: any) {
    if (err.code !== "ERR_NOT_AUTHENTICATED" || !TokenStore.auth || !tokensActions) {
      throw err
    }
    try {
      await tokensActions.verify()
    } catch (err) {
      tokensActions.destroy(false)
      throw err
    }
    return await resource[actionName](...args)
  }
}



export default useRequest