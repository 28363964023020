/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, Typography, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, PageHeader } from 'components'
import { AdhocAvailability } from "types"
import { statusToAvailability } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [adhocAvailabilities, actions] = useResource<AdhocAvailability>("adhocAvailabilities")

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'installedMeter,installedMeter.site.customer' })
    }
  }, [id])

  const adhocAvailability: Partial<AdhocAvailability> = adhocAvailabilities.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Adhoc Availability`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{ width: 130 }} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/adhoc_availabilities/${adhocAvailability.id}/edit`}>
                <IconButton size="large">
                  <EditIcon />
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Customer</TableCell>
            <TableCell>{String(adhocAvailability.installedMeter?.site?.customer?.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Installed meter</TableCell>
            <TableCell>{String(adhocAvailability.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Available</TableCell>
            <TableCell>{String(statusToAvailability(adhocAvailability.status))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>From</TableCell>
            <TableCell>{String(adhocAvailability.fromDate || '')} TP{String(adhocAvailability.fromPeriod || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>To</TableCell>
            <TableCell>{String(adhocAvailability.toDate || '')} TP{String(adhocAvailability.toPeriod || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show