import React, {useContext, useState} from "react";
import { Notification } from "types"
import { useNotifications } from 'hooks'

const MAX_NOTIFICATIONS = 30

export type DashboardContextState = {
  fetchNotifications: () => Promise<Notification[]>;
  markNotification: (id) => Promise<Notification[]>;
  notifications: Notification[],
  maxNotifications: Boolean,
};

const DashboardContext = React.createContext<DashboardContextState | undefined>(undefined);

export const DashboardProvider = ({ children }) => {
  const [notifications, notificationActions] = useNotifications()
  const unreadNotifications = notifications.list.filter(n => !n.readAt)

  const value = {
    fetchNotifications: () => notificationActions.index({sort: '-unread', page: 1, pageSize: MAX_NOTIFICATIONS}),
    markNotification: (id) => notificationActions.mark({'read': [id]}),
    notifications: unreadNotifications,
    maxNotifications: unreadNotifications.length === MAX_NOTIFICATIONS
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = (): DashboardContextState => {
  const dashboard = useContext<DashboardContextState | undefined>(DashboardContext)
  if (!dashboard) {
    console.warn("DashboardContext must be provided in order to use it")

    return {
      fetchNotifications: (): any => {},
      markNotification: (id): any => {},
      notifications: [],
      maxNotifications: false
    }
  }

  return dashboard
}