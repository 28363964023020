import React, { useState } from "react";
import useRequest from "./useRequest"
import { Plot } from 'types'

type PlotState = {
  plot: Plot | null,
  params: any,
}

type PlotActions = {
  plot: (params: any) => Promise<any>;
  reset: () => Promise<any>;
}

const usePlot = (resourceName: string, plotAction='plot'): [PlotState, PlotActions] => {
  const initialState = {
    plot: null,
    params: null,
    requests: {},
    errors: {},
    count: 0,
  }
  const [state, setState] = useState<any>(initialState)
  const performRequest = useRequest()

  const actions = {
    plot: async (options = {}) => {
      const { data: plot } = await performRequest(setState, resourceName, plotAction, { options })
      setState(prevState => ({ ...prevState, plot, params: options }))
      return plot
    },
    reset: async () => setState(prevState => initialState)
  }

  return [state, actions]
}

export default usePlot
