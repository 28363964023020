import Big from 'big.js' // Use this to avoid floating point issues (e.g. 100.3 becomes 100.29999999999998)

export function percentageAsDecimal(percentage: string, { rawIfNaN } = { rawIfNaN: false }) {
  try {
    return new Big(percentage).div('100').toString()
  } catch {
    return rawIfNaN ? percentage : NaN
  }
}

export function decimalAsPercentage(decimal: string, { rawIfNaN } = { rawIfNaN: false }) {
  try {
    return new Big(decimal).times('100').toString()
  } catch {
    return rawIfNaN ? decimal : NaN
  }
}

export function round(number, digits=3){ 
  return !(number === '' || number === null || isNaN(number)) && Math.round((parseFloat(number) + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits) 
}