import useQueryString from 'hooks/useQueryString'
import { useCallback } from 'react'

export type Page = number;
export type SetPage = (newPage: number) => void;

const usePagination = (namespace): [Page, SetPage] => {
  const [urlState, setUrlState] = useQueryString(namespace, ['page'])

  const page = Number(urlState.page) || 1
  const setPage = useCallback((newPage) => {
    setUrlState({ page: newPage <= 1 ? undefined : newPage })
  }, [setUrlState])

  return [page, setPage]
}
export default usePagination
