/** @jsxImportSource @emotion/react */
import React, {PureComponent} from 'react'
import Checkbox from '@mui/material/Checkbox'
import {humanize} from 'utils'
import Typography from '@mui/material/Typography'
import shallowEqual from 'shallowequal'
import { StylesObject } from 'types'

export type MultiSelectProps<Option = any> = {
  onChange?: (event: {target: {value?: string}}) => void;
  labelProvider: (option: Option, index: number) => string;
  valueProvider: (option: Option, index: number) => string;
  equalityProvider: (option1: Option, option2: Option) => boolean;
  value?: string[],
  options: string[],
  defaultOptions?: string[],
  unsetWhenDefault?: boolean;
  disabled?: boolean;
  name?: string;
}

class MultiSelect extends PureComponent<MultiSelectProps> {
  static defaultProps = {
    classes: {},
    defaultOptions: [],
    labelProvider: option => option.name || humanize(option),
    valueProvider: option => option.id || option,
    equalityProvider: (option1, option2) => shallowEqual(option1, option2)
  }

  get value() {
    return this.props.value || this.props.defaultOptions
  }

  valueIsDefault(value) {
    const defaultOptions = this.props.defaultOptions || []
    return value.length === defaultOptions.length && value.every(o => defaultOptions.includes(o))
  }

  getResult = value => {
    return this.props.unsetWhenDefault && this.valueIsDefault(value) ? undefined : value
  }

  onValueChange = option => ({target: {checked}}) => {
    const newValue = checked ? [...(this.value ?? []), option] : this.value?.filter(o => !this.props.equalityProvider(o, option))
    this.props.onChange && this.props.onChange({target: {value: this.getResult(newValue)}})
  }

  includesOption = option => {
    return this.value?.some(o => this.props.equalityProvider(o, option))
  }

  render = () => {
    const {options, labelProvider, valueProvider, disabled} = this.props
    return (
      <ul css={styles.options}>
        {options.map((option, i) =>
          <Typography css={styles.option} key={i}>
            <Checkbox
              css={styles.checkbox}
              onChange={this.onValueChange(valueProvider(option, i))}
              checked={this.includesOption(valueProvider(option, i))}
              disabled={disabled}
            />
            <span css={styles.label}>{labelProvider(option, i)}</span>
          </Typography>
        )}
      </ul>
    )
  }
}

const styles = {
  options: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  option: {
    margin: 0,
    display: 'inline-block',
  },
  checkbox: {
    marginLeft: -12,
  },
  label: {
    marginRight: 25,
  }
} as StylesObject

export default MultiSelect
