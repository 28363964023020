/** @jsxImportSource @emotion/react */
import React from 'react'
import {useDependency, useCommonRouteParams, useHotWater, usePagination} from 'hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  TableHead, Alert
} from '@mui/material'
import {ErrorBanner, PageContainer, PageHeader, Pagination} from 'components'
import {HotWaterEntry, HotWaterUpload} from "types"
import {apiErrorStringsFor, errorStringsFromError, formatLocaleDate, formatLocaleDateTime} from "utils";
import moment from "moment";
import {useSnackbar} from "contexts/SnackbarContext";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [hotWaterUploads, actions] = useHotWater()
  const snackbar = useSnackbar()
  const [page, setPage] = usePagination("hotWaterUpload")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'hotWaterEntries'})
    }
  }, [id])

  const hotWaterUpload: Partial<HotWaterUpload> = hotWaterUploads.selected ?? {}
  const uploadDate = hotWaterUpload.createdAt ? formatLocaleDateTime(hotWaterUpload.createdAt) : ''

  const handleConfirm = async () => {
    try {
      await actions.confirm(hotWaterUpload.id, {include: 'hotWaterEntries'})
    } catch (error) {
      snackbar.show(`Failed to confirm: ${errorStringsFromError(error).join(', ')}`)
    }
  }

  const rowsWithErrors = hotWaterUpload?.hotWaterEntries?.filter(({errors}) => Object.keys(errors ?? {}).length).map(({rowNumber}) => String(rowNumber))
  const hasErrors = rowsWithErrors && rowsWithErrors.length > 0
  const { entries, totalPages } = applyPage(hotWaterUpload.hotWaterEntries ?? [], page)

  return (
    <PageContainer>
      <PageHeader title={`Hot Water Upload - ${uploadDate}`} actions={
        hotWaterUpload?.confirmed === false && !hasErrors &&
        <Button color="secondary" variant="contained" onClick={handleConfirm}>Confirm</Button>
      } />
      <ErrorBanner>
        {apiErrorStringsFor(hotWaterUploads, 'confirm')}
      </ErrorBanner>
      <Table size="small">
        <TableBody>
          <TableRow>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Confirmed</TableCell>
              <TableCell>{String(hotWaterUpload.confirmed ? 'Yes' : 'No')}</TableCell>
            </TableRow>
          <TableRow>
            <TableCell variant='head'>File Name</TableCell>
            <TableCell>
              <a css={{ textDecoration: 'underline', color: 'rgb(0, 130, 200)'}} href={hotWaterUpload.fileUrlPath} target='_blank' rel="noopener noreferrer">
                {String(hotWaterUpload.fileName || '')}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Uploaded</TableCell>
            <TableCell>{uploadDate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
      {hasErrors &&
        <Alert severity="error">Errors in rows: {rowsWithErrors.join(", ")}</Alert>
      }
      {entries &&
        <>
          <Pagination totalPages={totalPages} page={page} onPageSelected={setPage}/>
          <Entries entries={entries} showRows={!hotWaterUpload.confirmed} />
          <Pagination totalPages={totalPages} page={page} onPageSelected={setPage}/>
        </>
      }
    </PageContainer>
  )
}

const Entries = ({ entries, showRows }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {showRows &&
            <TableCell>Row</TableCell>
          }
          <TableCell>Trading Date</TableCell>
          <TableCell>Trading Period</TableCell>
          <TableCell>FIR</TableCell>
          <TableCell>SIR</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {entries.map(entry => (
          <TableRow key={entry.id}>
            {showRows &&
              <Tooltip title="Row Number as seen in the Excel file" css={{ cursor: 'pointer' }}>
                <TableCell>{entry.rowNumber}</TableCell>
              </Tooltip>
            }
            <Cell entry={entry} field="tradingDate" />
            <Cell entry={entry} field="tradingPeriod" />
            <Cell entry={entry} field="firOffer" />
            <Cell entry={entry} field="sirOffer" />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Cell = ({ entry, field }) => {
  const value = entry[field]
  const entryErrors = entry.errors[field]
  if (entryErrors?.length > 0) {
    return (
      <Tooltip title={entryErrors.join(", ")}>
        <TableCell css={{ backgroundColor: '#ff0000ad', cursor: 'pointer' }}>
          {(value == null || value === "") ? "[EMPTY]" : value}
        </TableCell>
      </Tooltip>
    )
  }

  if (field === "tradingDate") {
    return <TableCell>{displayDate(value)}</TableCell>
  }

  return <TableCell>{value}</TableCell>
}

const displayDate = (date) => {
  if (!date) {
    return date
  }

  return moment(date).isValid() ? formatLocaleDate(date) : date
}

const applyPage = (entries: HotWaterEntry[], page: number, perPage = 30) => {
  const totalPages = Math.ceil(entries.length / perPage)
  const from = perPage * (page - 1)
  const to = perPage * page
  return {entries: entries.slice(from, to), totalPages}
}

export default Show