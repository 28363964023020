/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { Plot, StylesObject } from "types"
import ReactDygraph from './ReactDygraph'
import { dateAxisFormatter, dateTicker, round, userFriendlyDate } from 'utils'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'

const COLORS = [
  "#0082c8", "#3cb44b", "#e6194b", "#f58231", "#911eb4", "#46f0f0",
  "#f032e6", "#290278", "#008080", "#aa6e28", "#800000", "#63b598",
  "#ea9e70", "#c6e1e8", "#648177", "#e1cf3b", "#088baf", "#1c0365",
  "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#c0a43c", "#f2510e",
  "#651be6", "#61da5e", "#802234", "#41d158", "#9348af", "#01ac53",
  "#c5a4fb", "#b11573", "#4bb473", "#2f3f94", "#2f7b99", "#34891f",
  "#b0d87b", "#ca4751", "#7e50a8", "#608fa4", "#20f6ba", "#07d7f6",
  "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1",
  "#513d98", "#aead3a", "#4b5bdc", "#f1ae16", "#ea24a3", "#792ed8",
  "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397",
  "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b",
  "#5be4f0", "#57c4d8", "#a4d17a", "#be608b", "#96b00c", "#d00043",
  "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0",
  "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941",
  "#fb21a3", "#51aed9", "#5bb32d", "#21538e", "#89d534", "#d36647",
  "#7fb411", "#0023b8", "#3b8c2a", "#f50422", "#983f7a", "#0ec0ff",
  "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
  "#1bb699", "#6b2e5f", "#64820f", "#21538e", "#89d534", "#0d5ac1",
  "#7fb411", "#0023b8", "#3b8c2a", "#f50422", "#983f7a", "#ea24a3",
  "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec", "#6119d0",
  "#1bb699", "#6b2e5f", "#64820f", "#9cb64a", "#9ab9b7", "#cd2f00",
  "#06e052", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#e7dbce",
  "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57",
  "#8fe22a", "#ef6e3c", "#243eeb", "#dd93fd", "#3f8473", "#ce00be",
  "#421f79", "#7a3d93", "#93f2d7", "#15b9ee", "#0f5997", "#406df9",
  "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582",
  "#fc6b57", "#f07815", "#8fd883", "#96e591", "#21d52e", "#dc1c06",
  "#1ec227", "#4f0f6f", "#1d1d58", "#bde052", "#2f1179", "#3cec35",
  "#28fcfd", "#36486a", "#d02e29", "#1ae6db", "#a84a8f", "#77ecca",
  "#911e7e", "#3f16d9", "#0f525f", "#b4c086", "#c9d730", "#30cc49",
  "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b",
  "#615af0", "#79bca0", "#a8b8d4", "#00efd4", "#0cd36d", "#880977",
  "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c",
  "#f53b2a", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
  "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893",
  "#1c65cb", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
  "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c",
  "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e",
  "#f812b3", "#b17fc9", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
  "#4cf09d", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#dce77a",
  "#32d5d6", "#608572", "#c79bc2", "#00f87c", "#6995ba", "#dba2e6",
  "#76fc1b",
]

const defaultTimeFormatter = v => moment(v).format('DD/MM/YY HH:mm')

type TimeSeriesPlotParams = {
  plot: Plot | null;
  unit?: string;
  appendUnit?: boolean;
  ylabel?: string;
  maxGranularity?: number;
  dygraphOptions?: any;
  timeFormatter?: (v: any) => string;
  digitsAfterDecimal?: number;
  loading?: boolean;
  colorOverrides?: string[];
}

const TimeSeriesPlot = ({plot, unit, appendUnit=true, ylabel='', dygraphOptions={}, maxGranularity=0, timeFormatter=defaultTimeFormatter, digitsAfterDecimal=3, loading=false, colorOverrides}: TimeSeriesPlotParams) => {
  const plotValues = plot?.values || []
  const timeSeries = plot?.timeSeries || []
  const ticker = dateTicker(plotValues, maxGranularity)

  const axes = {
    x: {
      axisLabelFormatter: dateAxisFormatter(plotValues),
      valueFormatter: timeFormatter,
      ...(ticker ? {ticker} : {})
    },
    y: {
      valueFormatter: v => appendUnit ? `${round(v, digitsAfterDecimal)} ${unit}` : `${unit}${round(v, digitsAfterDecimal)}`,
    }
  }

  const labels = ['Time', ...timeSeries.map(s => (s.breakdownGroups || [null]).map(bg => `${s.label}${bg ? (' ' + bg) : ''}`))].flat()

  const renderWarning = () => {
    const overflow = timeSeries.filter(s => s.overflowLastTimestamp)
      .map(s => `Too many values for ${s.label}, stopped at ${userFriendlyDate(s.overflowLastTimestamp)}`)

    return overflow.length > 0 ? <ul css={styles.warning}> {overflow.map(msg => <li>{msg}</li>)} </ul> : null
  }

  const colors = colorOverrides ? COLORS.map((c,i) => (colorOverrides[i] || c)) : COLORS
  return (
    <div css={styles.container}>
      { loading && <CircularProgress css={styles.wheel}/> }
      { plotValues.length > 0 &&
          <ReactDygraph
            data={plotValues}
            options={{
              colors,
              axes,
              labels,
              ylabel,
              ...dygraphOptions
            }}
            updatedAt={plot?.createdAt}
          /> }
      {renderWarning()}
    </div>
  )



}

const styles = {
  container: {
    position: 'relative',
  },
  warning: {
  },
  wheel: {
    position: 'absolute',
    top: 120,
    left: '50%',
  }
} as StylesObject

export default TimeSeriesPlot