/** @jsxImportSource @emotion/react */
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageHeader, PageContainer } from 'components'
import { ForecastEvaluation } from "types"
import { formatLocaleDateTime } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [forecastEvaluations, actions] = useResource<ForecastEvaluation>("forecastEvaluations")

  useDependency(async () => {
    if (id) {
      actions.show(id, { include: 'installedMeter,forecastEvaluationRun' })
    }
  }, [id])

  const forecastEvaluation: Partial<ForecastEvaluation> = forecastEvaluations.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Forecast Evaluation`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Meter Serial</TableCell>
            <TableCell>{String(forecastEvaluation?.installedMeter?.meterSerial || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Metric Name</TableCell>
            <TableCell>{String(metrics[forecastEvaluation.metricName || ''] || forecastEvaluation.metricName || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Metric Value</TableCell>
            <TableCell>{String(forecastEvaluation.metricValue || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>From</TableCell>
            <TableCell>{formatLocaleDateTime(forecastEvaluation?.forecastEvaluationRun?.fromDatetime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>To</TableCell>
            <TableCell>{formatLocaleDateTime(forecastEvaluation?.forecastEvaluationRun?.toDatetime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell css={{ width: 130 }} variant='head'>Number of Records Evaluated</TableCell>
            <TableCell>{String(forecastEvaluation.recordCount || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

const metrics = {
  rmse: 'RMSE'
}

export default Show