/** @jsxImportSource @emotion/react */
import React  from 'react'
import { PageHeader, Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, formatLocaleDateTime, humanize } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { MeterReadReplay } from "types"

const List = () => {
  const [meterReadReplays, actions] = useResource<MeterReadReplay>("meterReadReplays")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("meterReadReplays")

  const [, , reloadMeterReadReplays] = useDependency(() => (
    actions.index({
      page: page,
      fields: {meterReadReplays: 'createdAt,from,to,status'}
    })
  ), [page])

  const showMeterReadReplay = ({id}) => () => {
    history.push(`/meter_read_replays/${id}`)
  }

  const renderMeterReadReplayListItem = (meterReadReplay) => {
    const { id, from, to, status } = meterReadReplay
    return (
      <TableRow key={id} onClick={showMeterReadReplay(meterReadReplay)} css={{ cursor: 'pointer' }}>
        <TableCell>{id}</TableCell>
        <TableCell>{formatLocaleDateTime(from)}</TableCell>
        <TableCell>{formatLocaleDateTime(to)}</TableCell>
        <TableCell>{<span css={{ color: statusColors[status] }}>{humanize(status)}</span>}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Meter Read Replays`} />
      <ErrorBanner>
        {apiErrorStringsFor(meterReadReplays, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterReadReplays.totalPages} page={page} onPageSelected={setPage}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {meterReadReplays.list.map(renderMeterReadReplayListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={meterReadReplays.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='secondary' onClick={() => history.push('/meter_read_replays/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default List