/** @jsxImportSource @emotion/react */
import React, { FunctionComponent, useMemo } from 'react'
import { Pagination, ErrorBanner } from 'components'
import {
  Table, TableHead, TableRow, TableCell, TableBody,
  Button,
} from '@mui/material'
import { apiErrorStringsFor, formatLocaleDateTime } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { Link, useHistory } from 'react-router-dom'
import { TrippedInstalledMeter } from "types"

export type ListProps = {
  underFrequencyEventId?: string;
  installedMeterId?: string;
}

const List: FunctionComponent<ListProps> = ({ underFrequencyEventId, installedMeterId }) => {
  const [trippedInstalledMeters, actions] = useResource<TrippedInstalledMeter>("trippedInstalledMeters")

  const history = useHistory()

  const [page, setPage] = usePagination("trippedInstalledMeters")

  const filter = useMemo(() => ({ underFrequencyEventId, installedMeterId }), [underFrequencyEventId, installedMeterId]);

  useDependency(() => (
    actions.index({
      page: page,
      include: 'installedMeter,underFrequencyEvent',
      filter
    })
  ), [page, filter])

  const showTrippedInstalledMeter = ({ id }) => () => {
    history.push(`/tripped_installed_meters/${id}`)
  }

  const renderTrippedInstalledMeterListItem = (trippedInstalledMeter) => {
    const { id, trippedAt, createdAt, installedMeter, underFrequencyEvent } = trippedInstalledMeter

    return (
      <TableRow key={id} onClick={showTrippedInstalledMeter(trippedInstalledMeter)} css={{ cursor: 'pointer' }}>
        {!installedMeterId &&
          <TableCell>{installedMeter.meterSerial}</TableCell>
        }
        <TableCell>{formatLocaleDateTime(trippedAt, { includeSeconds: true })}</TableCell>
        <TableCell>{formatLocaleDateTime(createdAt, { includeSeconds: true })}</TableCell>
        {!underFrequencyEventId &&
          <TableCell>{formatLocaleDateTime(underFrequencyEvent.startedAt, { includeSeconds: true })}</TableCell>
        }
        <TableCell onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
          <Link to={`/under_frequency_event_data_entries?underFrequencyEventId=${underFrequencyEvent?.id}&installedMeterId=${installedMeter.id}`}>
            <Button color="secondary" css={{ marginBottom: 0 }}>View Data</Button>
          </Link>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <ErrorBanner>
        {apiErrorStringsFor(trippedInstalledMeters, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={trippedInstalledMeters.totalPages} page={page} onPageSelected={setPage} />
      <Table size="small">
        <TableHead>
          <TableRow>
            {!installedMeterId &&
              <TableCell>Installed Meter</TableCell>
            }
            <TableCell>Tripped At</TableCell>
            <TableCell>Created At</TableCell>
            {!underFrequencyEventId &&
              <TableCell>Under Frequency Event</TableCell>
            }
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trippedInstalledMeters.list.map(renderTrippedInstalledMeterListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={trippedInstalledMeters.totalPages} page={page} onPageSelected={setPage} />
    </>
  )
}

export default List