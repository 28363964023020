/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDependency, useCommonRouteParams, useInvoiceRuns } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PageHeader, PromiseButton} from 'components'
import {formErrors, apiErrorStringsFor, formDataToJsonApi} from 'utils'
import { InvoiceRun } from "types"
import {TextField, Typography} from "@mui/material";
import {useSnackbar} from "contexts/SnackbarContext";
import useRedirect from "hooks/useRedirect";

const Override = () => {
  const {editMode, id} = useCommonRouteParams()
  const [invoiceRuns, actions] = useInvoiceRuns()
  const [formData, setFormData] = useState<Partial<InvoiceRun>>({})
  const snackbar = useSnackbar()
  const redirect = useRedirect()

  const override = async () => {
    const attributesToSave = formDataToJsonApi(formData)
    try {
      await actions.override({id: id, clearedRevenue: attributesToSave['clearedRevenue']})
      snackbar && snackbar.show(`Override completed`)
      redirect()
    } catch(err){
      console.error(err)
      snackbar && snackbar.show(`Error performing invoice revenue override`)
    }
  }

  useDependency(async () => {
    if (editMode) {
      const {totalRevenue} = await actions.show(id!)
      setFormData({totalRevenue})
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title="Override settled Revenue amount" />
      <Typography variant="subtitle2" gutterBottom>
        Revenue amounts will be proportionally adjusted for all invoices in this invoice run
      </Typography>
      <ControlledForm data={formData} errors={formErrors(invoiceRuns, 'override')} onChange={setFormData} onSubmit={override}>
        <ErrorBanner>{apiErrorStringsFor(invoiceRuns, 'override')}</ErrorBanner>
        <TextField fullWidth name='totalRevenue' disabled />
        <TextField fullWidth name='clearedRevenue'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Override</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Override