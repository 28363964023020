/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave, useQueryString } from 'hooks'
import { AutocompleteSearch, ControlledForm, DatePicker, ErrorBanner, LabeledSwitch, PageContainer, PageHeader, PromiseButton } from 'components'
import { formErrors, apiErrorStringsFor, currentISODateString } from 'utils'
import { InstalledMeter } from "types"

const Form = () => {
  const { editMode, id, resourceAction } = useCommonRouteParams()
  const [installedMeters, actions] = useResource<InstalledMeter>("installedMeters")
  const [{ siteId }] = useQueryString()
  const [formData, setFormData] = useState<Partial<InstalledMeter>>({ startDate: currentISODateString(), siteId })
  const handleSave = useDefaultSave(formData, actions, { linked: ['site'] })

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!, { include: 'site' })
      setFormData(result)
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Meter - ${formData.name}` : 'Create a new Meter'}></PageHeader>
      <ControlledForm data={formData} errors={formErrors(installedMeters, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(installedMeters, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='name' />
        <TextField fullWidth name='meterSerial' />
        <TextField fullWidth name='gatewaySerial' />
        <TextField fullWidth name='welAssetNumber' />
        <TextField fullWidth name='simCardNumber' />
        <div css={{ display: 'flex' }}>
          <DatePicker name='startDate' css={{ flex: 1 }} />
          <DatePicker name='endDate' css={{ flex: 1 }} />
        </div>
        {!siteId && <AutocompleteSearch fullWidth name='site' searchableField="displayName" />}
        <LabeledSwitch name="canBeSwitchedOff" />
        <br />
        <LabeledSwitch name="forecastRequiresReview" />
        <br />
        <LabeledSwitch name="excludeFromOffer" />
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit' css={{ marginTop: 16 }}>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form