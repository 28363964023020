import useResource, {Actions, ResourceState} from "../useResource";
import {Offer} from "types";
import useRequest from "../useRequest";

export type OffersResource = [
  ResourceState<Offer> & {latestAcknowledgedTime?: string},
    Actions<Offer> & {
    latestAcknowledgedTime: () => Promise<string>;
  }
]

const useOffers = (): OffersResource => {
  const [state, actions] = useResource<Offer, {latestAcknowledgedTime?: string}>("offers", {latestAcknowledgedTime: undefined})
  const performRequest = useRequest<typeof state>()
  const newActions = {
    ...actions,
    latestAcknowledgedTime: async () => {
      const { data } = await performRequest(actions.setState, "offers", 'latestAcknowledgedTime')
      actions.setState(prevState => ({ ...prevState, latestAcknowledgedTime: data.acknowledgedAt }))
      return data
    },
  }
  return [state, newActions]
}

export default useOffers
