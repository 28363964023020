/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useDependency, useCommonRouteParams, useResource, useDefaultSave } from 'hooks'
import {
  ControlledForm,
  ErrorBanner,
  PageContainer,
  PageHeader,
  PromiseButton,
  LabeledSelect,
  LabeledSwitch
} from 'components'
import { formErrors, apiErrorStringsFor } from 'utils'
import { ServiceAccount } from "types"

const Form = () => {
  const { editMode, id, resourceAction } = useCommonRouteParams()
  const [serviceAccounts, actions] = useResource<ServiceAccount>("serviceAccounts")
  const [formData, setFormData] = useState<Partial<ServiceAccount>>({})
  const handleSave = useDefaultSave(formData, actions, { redirectTo: (o) => `/service_accounts/${o.id}` })

  useDependency(async () => {
    if (editMode) {
      const result = await actions.show(id!)
      setFormData(result)
    }
  }, [editMode, id])

  return (
    <PageContainer>
      <PageHeader title={editMode ? `Edit Service Account - ${formData.name}` : 'Create a new Service Account'} />
      <ControlledForm data={formData} errors={formErrors(serviceAccounts, resourceAction)} onChange={setFormData} onSubmit={handleSave}>
        <ErrorBanner>{apiErrorStringsFor(serviceAccounts, 'create', 'update')}</ErrorBanner>
        <TextField fullWidth name='name' />
        <LabeledSelect fullWidth name='role' options={['offers', 'scada', 'meters', 'internal_azure_webhook']} />
        <LabeledSwitch name="basicAuthEnabled" />
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

export default Form