import React, { Component } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export type PageContainerProps = {
  children?: React.ReactNode;
}
export class PageContainer extends Component<PageContainerProps> {

  render = () => {
    const { children, ...props } = this.props
    return (
      <Card {...props}>
        <CardContent>{children}</CardContent>
      </Card>
    )
  }
}

export default PageContainer