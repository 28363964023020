/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { useFilter, useQueryString, usePlot, useDependency } from 'hooks'
import { ControlledForm, AutocompleteSearch, DateTimePicker, LabeledSelect, ExportButton } from 'components'
import { TimeSeriesPlot, GroupOptions }  from 'components/charts'
import { StylesObject } from "types"
import * as API from 'api'
import { formatPickerDateTime } from 'utils'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const SERIES = {
  frequency: 'Frequency',
  demand: 'Demand'
}

const SERIES_DETAILS = {
  frequency: {label: 'Frequency (Hz)', unit: 'Hz'},
  demand: {label: 'Demand (kW)', unit: 'kW'},
}

const DEFAULT_INDIVIDUAL_GROUP = null

const DEFAULT_AGGREGATE_GROUP = {
  type: 'aggregate',
  function: 'sum',
  interval: 'day',
}

const UnderFrequencyEvents = () => {

  const [urlState, setUrlState] = useQueryString()
  const [filter, setFilter] = useFilter(setUrlState, 'underFrequencyEvents')
  const [plotState, plotActions] = usePlot("underFrequencyEventDataEntries")
  const plot = plotState.plot
  const scope = filter.scope
  const site = filter.site
  const installedMeters = filter.site?.installedMeters || filter.underFrequencyEvent?.installedMeters || []
  const series = filter.series
  const underFrequencyEvent = filter.underFrequencyEvent
  const [dygraphOptions, setDygraphOptions] = useState({})

  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0){
      setFilter({
        scope: 'individual',
        series: 'frequency',
        from: formatPickerDateTime(moment().startOf('month')),
        group: DEFAULT_AGGREGATE_GROUP,
      })
    }
  }, [])

  const [loading] = useDependency( async () => {
    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      await plotActions.plot({ timeSeries })
    }
  }, [JSON.stringify(filter)])

  const currentIndividualTimeSeries = () => {
    return installedMeters.map(im => ({
      series,
      label: im.meterSerial,
      filter: {
        installedMeterId: im.id,
        timestampFrom: filter.from,
        timestampTo: filter.to,
        underFrequencyEventId: filter.underFrequencyEvent?.id,
      },
      group: filter.group
    }))
  }

  const currentAggregateTimeSeries = () => {
    return [{
      series,
      label: `Aggregated ${series}`,
      filter: {
        customerId: filter.customer?.id,
        siteId: filter.site?.id,
        timestampFrom: filter.from,
        timestampTo: filter.to,
        underFrequencyEventId: filter.underFrequencyEvent?.id,
      },
      group: filter.group
    }]
  }

  const currentTimeSeries = () => {
    if (!series){
      return []
    }

    switch (scope) {
      case 'individual':
        return currentIndividualTimeSeries()
      case 'aggregate':
        return currentAggregateTimeSeries()
      default:
        return []
    }
  }

  const onSuggestionsFetchRequestedEvents = async (text, callback) => {
    const events: any = API['UnderFrequencyEvents']
    const { data } = await events.index({
      options: {
        fields: {underFrequencyEvents: "id,eventReferenceId", installedMeters: "id,meterSerial"},
        filter: {search: text},
        page: { number: 1, size: 15 },
        include: 'installedMeters'
      }
    })
    callback(data)
  }

  const onSuggestionsFetchRequestedSites = async (text, callback) => {
    const sites: any = API['Sites']
    const { data } = await sites.index({
      options: {
        fields: {sites: "id,displayName", installedMeters: "id,meterSerial"},
        filter: {search: text, customerId: filter.customer?.id},
        page: { number: 1, size: 15 },
        include: 'customer,installedMeters'
      }
    })
    callback(data)
  }

  const handleScopeChange = ({target: {value}}: any) => {
    setFilter({ scope: value, group: value === 'aggregate' ? DEFAULT_AGGREGATE_GROUP : DEFAULT_INDIVIDUAL_GROUP })
    plotActions.reset()
  }

  // keep customer/site relationship valid
  const handleCustomerChange = ({target: {value}}: any) => {
    setFilter({...filter, customer: value, site: value?.id === filter.site?.customer?.id ? filter.site : null})
  }

  const handleSiteChange = ({target: {value}}: any) => {
    setFilter({...filter, site: value, customer: value?.customer || filter.customer})
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}>
        <LabeledSelect
          css={styles.leftFilter}
          name="series"
          fullWidth
          options={SERIES}
        />
        <AutocompleteSearch
          css={styles.filter}
          label='Event'
          name='underFrequencyEvent'
          searchableField='eventReferenceId'
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedEvents}
          error={scope === 'individual' && !underFrequencyEvent && !installedMeters.length}
          helperText={scope === 'individual' && !underFrequencyEvent && !installedMeters.length ? 'is required' : ''}
        />
        <AutocompleteSearch
          css={styles.filter}
          name='customer'
          searchableField='name'
          onChange={handleCustomerChange}
        />
        <AutocompleteSearch
          key={`sites-for-customer-${filter?.customer?.id ?? 'all'}`} // Key prevents caching for the wrong customer
          css={styles.filter}
          name='site'
          searchableField='displayName'
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedSites}
          onChange={handleSiteChange}
          error={scope === 'individual' && !site && !installedMeters.length}
          helperText={scope === 'individual' && !site && !installedMeters.length ? 'is required' : ''}
        />
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.filterRow}>
        <div css={styles.groupOptions}>
          <LabeledSelect
            css={styles.scopeSelect}
            name="scope"
            label="Plot"
            onChange={handleScopeChange}
            options={{individual: 'Per Meter', aggregate: 'Aggregated'}}
          />
          <GroupOptions scope="individual" name='group'/>
          <ExportButton
            css={styles.exportButton}
            variant='text'
            resource="under_frequency_event_data_entries/plot"
            params={plotState.params}
            disabled={!plot?.values?.length}
            qsOptions={{ arrayFormat: 'brackets'}}
          />
          <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
        </div>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}

      <TimeSeriesPlot
        plot={plot}
        unit={SERIES_DETAILS[series]?.unit}
        ylabel={SERIES_DETAILS[series]?.label}
        maxGranularity={Dygraph.Granularity.MINUTELY}
        dygraphOptions={dygraphOptions}
        loading={loading}
      />
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
  },
  groupOptions: {
    marginTop: 30,
    width: '100%',
    textAlign: 'right',
  },
  multiSelectLabel: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  }
} as StylesObject

export default UnderFrequencyEvents