/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { DerivedDispatch } from "types"
import {formatLocaleDate} from "utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [derivedDispatches, actions] = useResource<DerivedDispatch>("derivedDispatches")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const derivedDispatch: Partial<DerivedDispatch> = derivedDispatches.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Average Dispatch - ${formatLocaleDate(derivedDispatch.tradingDate)} TP${derivedDispatch.tradingPeriod}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{width: 230}} variant='head'>Trade Code</TableCell>
            <TableCell>{String(derivedDispatch.traderCode || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Block</TableCell>
            <TableCell>{String(derivedDispatch.block || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Trading date</TableCell>
            <TableCell>{formatLocaleDate(derivedDispatch.tradingDate || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Trading period</TableCell>
            <TableCell>{String(derivedDispatch.tradingPeriod || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Fir avg dispatch</TableCell>
            <TableCell>{String(derivedDispatch.firAvgDispatch || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Sir avg dispatch</TableCell>
            <TableCell>{String(derivedDispatch.sirAvgDispatch || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show