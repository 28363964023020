/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, PageHeader } from 'components'
import { Customer } from "types"
import { List as SitesList } from "containers/sites"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [customers, actions] = useResource<Customer>("customers")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const customer: Partial<Customer> = customers.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Customer - ${customer.name}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/customers/${customer.id}/edit`}>
                <IconButton size="large">
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Customer Number</TableCell>
              <TableCell>{String(customer.customerNumber || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(customer.name || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
      <div>
        <SitesList customerId={id} />
      </div>
    </PageContainer>
  )
}

export default Show