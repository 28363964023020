/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import {apiErrorStringsFor, errorStringsFromError, humanize} from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { BulkReserveSource } from "types"

const List = () => {
  const [bulkReserveSources, actions] = useResource<BulkReserveSource>("bulkReserveSources")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("bulkReserveSources")

  const [, , reloadBulkReserveSources] = useDependency(() => (
    actions.index({
      page: page,
      fields: {bulkReserveSources: 'name,sourceType'}
    })
  ), [page])

  const showBulkReserveSource = ({id}) => () => {
    history.push(`/bulk_reserve_sources/${id}`)
  }

  const editBulkReserveSource = ({id}) => event => {
    history.push(`/bulk_reserve_sources/${id}/edit`)
    event.stopPropagation()
  }

  const deleteBulkReserveSource = ({id}) => () => {
    actions.destroy({id})
           .then(reloadBulkReserveSources)
           .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderBulkReserveSourceListItem = (bulkReserveSource) => {
    const { id, name, sourceType } = bulkReserveSource
    return (
      <ListItem button onClick={showBulkReserveSource(bulkReserveSource)} key={id}>
        <ListItemIcon>
          <BatteryChargingFullIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={humanize(sourceType)}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editBulkReserveSource(bulkReserveSource)} size="large"><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteBulkReserveSource(bulkReserveSource)} entityName="bulkReserveSource" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Bulk Reserve Sources</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(bulkReserveSources, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={bulkReserveSources.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {bulkReserveSources.list.map(renderBulkReserveSourceListItem)}
      </MuiList>
      <Pagination totalPages={bulkReserveSources.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='secondary' onClick={() => history.push('/bulk_reserve_sources/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

export default List