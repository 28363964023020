/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { ForecastEvaluationRun } from "types"
import { formatLocaleDateTime, titleize } from 'utils'

const Show = () => {
  const { id } = useCommonRouteParams()
  const [forecastEvaluationRuns, actions] = useResource<ForecastEvaluationRun>("forecastEvaluationRuns")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const forecastEvaluationRun: Partial<ForecastEvaluationRun> = forecastEvaluationRuns.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`ForecastEvaluationRun - ${formatLocaleDateTime(forecastEvaluationRun.runAt)}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head' css={{ width: 130 }}>Status</TableCell>
            <TableCell>{String(titleize(forecastEvaluationRun.status || ''))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Run At</TableCell>
            <TableCell>{formatLocaleDateTime(forecastEvaluationRun.runAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>From</TableCell>
            <TableCell>{formatLocaleDateTime(forecastEvaluationRun.fromDatetime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>To</TableCell>
            <TableCell>{formatLocaleDateTime(forecastEvaluationRun.toDatetime)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  )
}

export default Show