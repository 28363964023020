/** @jsxImportSource @emotion/react */
import { Link as RouterLink } from 'react-router-dom'
import React from "react";

type RouteLinkProps = React.ComponentProps<typeof RouterLink>


export const Link = (props: RouteLinkProps) => {
  return <RouterLink {...props} css={{ textDecoration: 'underline' }} />
}

export default Link