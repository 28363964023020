/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { DispatchInstruction } from "types"
import {formatLocaleDateTime} from "utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [dispatchInstructions, actions] = useResource<DispatchInstruction>("dispatchInstructions")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const dispatchInstruction: Partial<DispatchInstruction> = dispatchInstructions.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Dispatch Instruction - ${dispatchInstruction.dispatchType} ${formatLocaleDateTime(dispatchInstruction.dispatchedAt, {includeSeconds: true})}`}/>
      <Table>
        <TableBody>
            <TableRow>
              <TableCell css={{width: 250}} variant='head'>WEL Dispatch Instruction ID</TableCell>
              <TableCell>{String(dispatchInstruction.welId || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trader Code</TableCell>
              <TableCell>{String(dispatchInstruction.traderCode || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Block</TableCell>
              <TableCell>{String(dispatchInstruction.block || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Dispatch type</TableCell>
              <TableCell>{String(dispatchInstruction.dispatchType || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Quantity MW</TableCell>
              <TableCell>{String(dispatchInstruction.quantityMW || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Dispatched at</TableCell>
              <TableCell>{formatLocaleDateTime(dispatchInstruction.dispatchedAt || '', {includeSeconds: true})}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Created at</TableCell>
              <TableCell>{formatLocaleDateTime(dispatchInstruction.createdAt || '', {includeSeconds: true})}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show