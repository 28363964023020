/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableCell, TableRow, Typography, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { PageContainer, LinkButton, PageHeader } from 'components'
import { BulkReserveSource } from "types"
import {humanize, history} from "utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [bulkReserveSources, actions] = useResource<BulkReserveSource>("bulkReserveSources")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'customer'})
    }
  }, [id])

  const bulkReserveSource: Partial<BulkReserveSource> = bulkReserveSources.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Bulk Reserve Source - ` + bulkReserveSource.name} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={{width: 130}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/bulk_reserve_sources/${bulkReserveSource.id}/edit`}>
                <IconButton size="large">
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(bulkReserveSource.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Source type</TableCell>
              <TableCell>{humanize(bulkReserveSource.sourceType || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>GXP</TableCell>
              <TableCell>{String(bulkReserveSource.gxp || '')}</TableCell>
            </TableRow>
          <TableRow>
            <TableCell variant='head'>Customer</TableCell>
            <TableCell>
              <LinkButton onClick={() => history.push(`/customers/${bulkReserveSource.customer?.id}`)}>
                {String(bulkReserveSource.customer?.name || '')}
              </LinkButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show