/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import {Link, PageContainer, PageHeader} from 'components'
import { TrippedInstalledMeter } from "types"
import {formatLocaleDateTime} from "utils";

const Show = () => {
  const { id } = useCommonRouteParams()
  const [trippedInstalledMeters, actions] = useResource<TrippedInstalledMeter>("trippedInstalledMeters")

  useDependency(async () => {
    if (id) {
      actions.show(id, {include: 'installedMeter,underFrequencyEvent'})
    }
  }, [id])

  const trippedInstalledMeter: Partial<TrippedInstalledMeter> = trippedInstalledMeters.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Tripped Installed Meter - ${formatLocaleDateTime(trippedInstalledMeter.createdAt)}`} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant='head'>Tripped At</TableCell>
            <TableCell>{trippedInstalledMeter.trippedAt ? formatLocaleDateTime(trippedInstalledMeter.trippedAt, {includeSeconds: true}) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Created At</TableCell>
            <TableCell>{trippedInstalledMeter.createdAt ? formatLocaleDateTime(trippedInstalledMeter.createdAt, {includeSeconds: true}) : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Installed Meter</TableCell>
            <TableCell>
              <Link to={`/installed_meters/${trippedInstalledMeter.installedMeter?.id}`}>
                {String(trippedInstalledMeter.installedMeter?.meterSerial || '')}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Under Frequency Event</TableCell>
            <TableCell>
              <Link to={`/under_frequency_events/${trippedInstalledMeter.underFrequencyEvent?.id}`}>
                {formatLocaleDateTime(trippedInstalledMeter.underFrequencyEvent?.startedAt)}
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show