/** @jsxImportSource @emotion/react */
import React  from 'react'
import {Pagination, ErrorBanner, PageContainer, PageHeader} from 'components'
import {
  Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDate, formatLocaleDateTime, humanize} from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { SettlementRun } from "types"

const List = () => {
  const [settlementRuns, actions] = useResource<SettlementRun>("settlementRuns")

  const history = useHistory()
  const [page, setPage] = usePagination("settlementRuns")

  useDependency(() => (
    actions.index({
      page: page,
      sort: '-id'
    })
  ), [page])

  const showSettlementRun = ({id}) => () => {
    history.push(`/settlement_runs/${id}`)
  }

  const renderSettlementRunListItem = (settlementRun) => {
    const { id, startedAt, completedAt, status, startDate, endDate } = settlementRun
    return (
      <TableRow key={id} onClick={showSettlementRun(settlementRun)} css={{ cursor: 'pointer' }}>
        <TableCell>{id}</TableCell>
        <TableCell>{formatLocaleDateTime(startedAt)}</TableCell>
        <TableCell>{formatLocaleDate(startDate)}</TableCell>
        <TableCell>{formatLocaleDate(endDate)}</TableCell>
        <TableCell>{humanize(status)}</TableCell>
        <TableCell>{completedAt ? formatLocaleDateTime(completedAt) : '-'}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title="Settlement Runs" />
      <ErrorBanner>
        {apiErrorStringsFor(settlementRuns, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={settlementRuns.totalPages} page={page} onPageSelected={setPage}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Run Started At</TableCell>
            <TableCell>From Date</TableCell>
            <TableCell>To Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settlementRuns.list.map(renderSettlementRunListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={settlementRuns.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List