/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, PageContainer, PageHeader, SearchCombo, DatePicker, LabeledSelect } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { ForecastEvaluationRun } from "types"
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { formatLocaleDateTime, humanize } from 'utils'

const List = () => {
  const [forecastEvaluationRuns, actions] = useResource<ForecastEvaluationRun>("forecastEvaluationRuns")

  const history = useHistory()

  const [page, setPage] = usePagination("forecastEvaluationRuns")
  const [filter, setFilter] = useFilter(setPage, 'forecastEvaluations')

  useDependency(() => (
    actions.index({
      page: page,
      filter,
      sort: '-runAt',
    })
  ), [page, filter])

  const showForecastEvaluationRun = ({id}) => () => {
    history.push(`/forecast_evaluation_runs/${id}`)
  }

  const renderForecastEvaluationRunListItem = (forecastEvaluationRun) => {
    const { id, runAt, status } = forecastEvaluationRun
    return (
      <ListItem button onClick={showForecastEvaluationRun(forecastEvaluationRun)} key={id}>
        <ListItemIcon>
          <QueryStatsIcon />
        </ListItemIcon>
        <ListItemText primary={formatLocaleDateTime(runAt)} secondary={<span css={{ color: statusColors[status] }}>{humanize(status)}</span>}  />
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Forecast Evaluation Runs`} />
      <Pagination totalPages={forecastEvaluationRuns.totalPages} page={page} onPageSelected={setPage} startAdornment={
        <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
          <DatePicker name="date" fullWidth />
          <LabeledSelect name="status" fullWidth options={["pending", "completed", "error"]} />
        </SearchCombo>
      } />
      <MuiList dense>
        {forecastEvaluationRuns.list.map(renderForecastEvaluationRunListItem)}
      </MuiList>
      <Pagination totalPages={forecastEvaluationRuns.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default List