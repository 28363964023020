/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { PageHeader, Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, ExportButton } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, errorStringsFromError, formatLocaleDateTime } from 'utils'
import { usePagination, useDependency, useResource, useQueryString } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { UnderFrequencyEventDataEntry } from "types"

const List = () => {
  const [underFrequencyEventDataEntries, actions] = useResource<UnderFrequencyEventDataEntry>("underFrequencyEventDataEntries")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("underFrequencyEventDataEntries")
  const [{ underFrequencyEventId, installedMeterId, underFrequencyEventDataUploadId }] = useQueryString()

  const sort = '-underFrequencyEventId,meterSerial,-timestamp'
  const filter = useMemo(() => ({ underFrequencyEventId, installedMeterId, underFrequencyEventDataUploadId }), [
    underFrequencyEventId, installedMeterId, underFrequencyEventDataUploadId
  ])
  const [loading, , reloadUnderFrequencyEventDataEntries] = useDependency(() => (
    actions.index({
      page: page,
      fields: { underFrequencyEventDataEntries: 'timestamp,frequency,totalDemandkW', installedMeters: 'meterSerial' },
      filter: filter,
      sort: sort,
      include: 'underFrequencyEventDataUpload,underFrequencyEventDataUpload.installedMeter',
    })
  ), [page, sort, filter])

  const renderUnderFrequencyEventDataEntryListItem = (underFrequencyEventDataEntry) => {
    const { id, underFrequencyEventDataUpload, timestamp, frequency, totalDemandkW } = underFrequencyEventDataEntry
    return (
      <TableRow key={id}>
        <TableCell>{underFrequencyEventDataUpload?.installedMeter?.meterSerial}</TableCell>
        <TableCell>{formatLocaleDateTime(timestamp, { includeYear: true, includeSeconds: true, includeMilliseconds: true })}</TableCell>
        <TableCell>{frequency}</TableCell>
        <TableCell>{totalDemandkW}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Under Frequency Event Data`} actions={
        <ExportButton resource="under_frequency_event_data_entries" filter={filter} sort={sort} disabled={!(underFrequencyEventId && installedMeterId) && !underFrequencyEventDataUploadId} />}
      />
      <ErrorBanner>
        {apiErrorStringsFor(underFrequencyEventDataEntries, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={underFrequencyEventDataEntries.totalPages} page={page} onPageSelected={setPage} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Meter</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Total Demand kW</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {underFrequencyEventDataEntries.list.map(renderUnderFrequencyEventDataEntryListItem)}
          {!loading && underFrequencyEventDataEntries.list.length === 0 && <TableRow>
            <TableCell colSpan={5}>No Data Found</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <Pagination totalPages={underFrequencyEventDataEntries.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List