/** @jsxImportSource @emotion/react */
import {useEffect, Fragment, useRef} from 'react'
import { SessionStatus } from '../../constants'
import * as Containers from 'containers'
import { throttle } from 'utils'
import { Routes, Nav } from '.'
import CssBaseline from '@mui/material/CssBaseline'
import {SnackbarProvider} from "contexts/SnackbarContext";
import {DashboardProvider} from "contexts/DashboardContext";
import {TokensProvider, useTokens} from "contexts/TokensContext";
import { TokenStore } from 'services'

const activityEventTypes = ['mousemove', 'click', 'keydown', 'resize']

const styles = {
  contentWrapper: {
    flex: '1 0 auto',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',
      maxWidth: '100%'
    }
  }
}

const CurrentRoutes = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return null }
  }
}

const CurrentNav = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return null }
  }
}

const App = () => {
  const [tokens, actions] = useTokens();

  useEffect(() => {
    actions.registerActivity()
    if (window.location.pathname === '/microsoft/callback') {
      TokenStore.destroy() // Remove any existing tokens so that if the callback is for a different user they sign in correctly
    }
    actions.verify(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const containerRef = useRef<HTMLDivElement>(null)
  const registerActivity = throttle(() => {
    actions.registerActivity()
  })
  useEffect(() => {
    const container = containerRef.current
    activityEventTypes.forEach(type => container?.addEventListener(type, registerActivity))
    return () => {
      activityEventTypes.forEach(type => container?.removeEventListener(type, registerActivity))
    }
  })

  return (
    <Fragment>
      <CssBaseline />
      <CurrentNav loginState={tokens.loginState} />
      <div css={styles.contentWrapper} ref={containerRef}>
        <CurrentRoutes loginState={tokens.loginState} />
      </div>
      <Containers.Shared.Snackbar/>
    </Fragment>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => (
  <TokensProvider>
    <SnackbarProvider>
      <DashboardProvider>
        <App {...props} />
      </DashboardProvider>
    </SnackbarProvider>
  </TokensProvider>
)