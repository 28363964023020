/** @jsxImportSource @emotion/react */
import { Pagination, ErrorBanner, PageContainer, DatePicker, DateTimePicker, AutocompleteSearch, SearchCombo, PageHeader, ExportButton } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@mui/material'
import { apiErrorStringsFor, siteAndMeterFilters, formatLocaleDate, formatLocaleDateTime } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { Forecast } from "types"

const List = () => {
  const [forecasts, actions] = useResource<Forecast>("forecasts")
  const [page, setPage] = usePagination("forecasts")
  const [filter, setFilter] = useFilter(setPage, 'forecasts')

  const sort = '-forecastRunId,installedMeterId,tradingDate,tradingPeriod'
  useDependency(() => (
    actions.index({
      page: page,
      fields: { forecasts: 'tradingDate, tradingPeriod, forecastDemand', installedMeters: 'meterSerial', forecastRuns: 'runAt' },
      include: 'installedMeter,forecastRun',
      filter,
      sort: sort,
    })
  ), [page, filter, sort])

  const renderForecastListItem = (forecast) => {
    const { id, installedMeter, forecastRun, tradingDate, tradingPeriod, demand } = forecast
    return (
      <TableRow key={id}>
        <TableCell>{forecastRun.id}</TableCell>
        <TableCell>{formatLocaleDateTime(forecastRun.runAt)}</TableCell>
        <TableCell>{installedMeter.meterSerial}</TableCell>
        <TableCell>{formatLocaleDate(tradingDate)}</TableCell>
        <TableCell>{tradingPeriod}</TableCell>
        <TableCell>{demand}</TableCell>
      </TableRow>
    )
  }

  const autoClears = { customer: { fields: ['site', 'installedMeter'], value: null }, site: { fields: ['installedMeter'], value: null } }
  return (
    <PageContainer>
      <PageHeader title={`Forecast Data`} actions={
        <ExportButton resource="forecasts" filter={filter} sort={sort} disabled={Object.keys(filter).length === 0} />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(forecasts, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={forecasts.totalPages} page={page} onPageSelected={setPage} startAdornment={(
        <SearchCombo searchTextMember='meterSerial' onFilterChange={setFilter} filter={filter} autoClears={autoClears}>
          {(state) => {
            const { sitesKey, sitesFilter, metersKey, metersFilter } = siteAndMeterFilters(state)
            return <>
              <TextField name='forecastRunId' fullWidth />
              <AutocompleteSearch fullWidth name='customer' searchableField="name" />
              <AutocompleteSearch fullWidth name='site' key={sitesKey} filter={sitesFilter} searchableField="displayName" />
              <AutocompleteSearch fullWidth name="installedMeter" key={metersKey} filter={metersFilter} searchableField="meterSerial" label="Meter" />
              <DatePicker name='tradingDate' fullWidth />
              <TextField name='tradingPeriod' fullWidth />
              <DateTimePicker label='Start Time' name='tradingDatetimeFrom' fullWidth />
              <DateTimePicker label='End Time' name='tradingDatetimeTo' fullWidth />
            </>
          }}
        </SearchCombo>
      )} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Run ID</TableCell>
            <TableCell>Run At</TableCell>
            <TableCell>Meter Serial</TableCell>
            <TableCell>Trading Date</TableCell>
            <TableCell>Trading Period</TableCell>
            <TableCell>Forecast Demand (kW)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecasts.list.map(renderForecastListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={forecasts.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List