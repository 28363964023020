/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
} from '@mui/material'
import MeterTestResultUploadIcon from '@mui/icons-material/Backup'
import { apiErrorStringsFor, errorStringsFromError } from 'utils'
import { usePagination, useDependency, useResource, useAuthorization } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { MeterTestResultUpload } from "types"

const List = ({ installedMeterId }) => {
  const [meterTestResultUploads, actions] = useResource<MeterTestResultUpload>("meterTestResultUploads")

  const history = useHistory()
  const authorization = useAuthorization()
  const snackbar = useSnackbar()

  const [page, setPage] = usePagination("meterTestResultUploads")

  const [, , reloadMeterTestResultUploads] = useDependency(() => (
    actions.index({
      page: page,
      include: 'installedMeter',
      fields: {meterTestResultUploads: 'filename', installedMeters: 'meterSerial'},
      filter: { installedMeterId },
      sort: '-id',
    })
  ), [page])

  const showUpload = ({id}) => () => {
    history.push(`/meter_test_result_uploads/${id}`)
  }

  const deleteMeterTestResultUpload = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadMeterTestResultUploads)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderMeterTestResultUploadListItem = (meterTestResultUpload) => {
    const { id, filename, installedMeter } = meterTestResultUpload
    return (
      <ListItem button onClick={showUpload(meterTestResultUpload)} key={id}>
        <ListItemIcon>
          <MeterTestResultUploadIcon />
        </ListItemIcon>
        <ListItemText primary={`${installedMeter?.meterSerial} - ${filename}`} />
        {authorization.admin && 
        <ListItemSecondaryAction>
          <DeleteConfirmationDialog onConfirm={deleteMeterTestResultUpload(meterTestResultUpload)} entityName="meter test result upload" />
        </ListItemSecondaryAction>}
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' css={{ marginBottom: 20, display: 'flex' }}>
        Meter Test Result Uploads
        <span css={{ flex: '1' }} />
        {installedMeterId && (authorization.admin || authorization.internal) && <Button variant="contained" onClick={() => history.push(`/installed_meters/${installedMeterId}/meter_test_result_uploads/new`)}>New</Button>}
      </Typography>

      <ErrorBanner>
        {apiErrorStringsFor(meterTestResultUploads, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterTestResultUploads.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {meterTestResultUploads.list.map(renderMeterTestResultUploadListItem)}
      </MuiList>
      <Pagination totalPages={meterTestResultUploads.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List