/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog, SearchCombo } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ConfigIcon from '@mui/icons-material/Settings'
import EditIcon from '@mui/icons-material/Edit'
import { apiErrorStringsFor, decimalAsPercentage, errorStringsFromError } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from "contexts/SnackbarContext"
import { ConfigOption } from "types"

const List = () => {
  const [configOptions, actions] = useResource<ConfigOption>("configOptions")

  const snackbar = useSnackbar()
  const history = useHistory()


  const [page, setPage] = usePagination("configOptions")
  const [filter, setFilter] = useFilter(setPage, 'configOptions')

  const [, , reloadConfigOptions] = useDependency(() => (
    actions.index({
      page: page,
      fields: { configOptions: 'name,currentValue,dataType' },
      filter,
    })
  ), [page, filter])

  const showConfigOption = ({ id }) => () => {
    history.push(`/config_options/${id}`)
  }

  const editConfigOption = ({ id }) => event => {
    history.push(`/config_options/${id}/edit`)
    event.stopPropagation()
  }

  const deleteConfigOption = ({ id }) => () => {
    actions.destroy({ id })
      .then(reloadConfigOptions)
      .catch(error => snackbar.show(errorStringsFromError(error).join(', ')))
  }

  const renderConfigOptionListItem = (configOption) => {
    const { id, name, currentValue, dataType } = configOption
    const displayValue = dataType === "percentage" ? `${decimalAsPercentage(currentValue)}%` : JSON.stringify(currentValue)
    return (
      <ListItem button onClick={showConfigOption(configOption)} key={id}>
        <ListItemIcon>
          <ConfigIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={displayValue} />
        <ListItemSecondaryAction>
          <IconButton onClick={editConfigOption(configOption)} size="large"><EditIcon /></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteConfigOption(configOption)} entityName="config option" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Config Options</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(configOptions, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={configOptions.totalPages} page={page} onPageSelected={setPage} startAdornment={
        <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
          <TextField fullWidth name='name' />
        </SearchCombo>
      } />
      <MuiList dense>
        {configOptions.list.map(renderConfigOptionListItem)}
      </MuiList>
      <Pagination totalPages={configOptions.totalPages} page={page} onPageSelected={setPage} />
      <FABFixed color='secondary' onClick={() => history.push('/config_options/new')}>
        <AddIcon />
      </FABFixed>
    </PageContainer>
  )
}

export default List