/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { useFilter, useQueryString, usePlot, useDependency } from 'hooks'
import { ControlledForm, DateTimePicker, MultiSelect, ExportButton } from 'components'
import { TimeSeriesPlot, GroupOptions }  from 'components/charts'
import { StylesObject } from "types"
import { formatPickerDateTime } from 'utils'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const OFFER_TYPES = [
  {name: 'FIR', id: 'fir_offer'},
  {name: 'SIR', id: 'sir_offer'},
]

const HotWater = () => {
  
  const [urlState, setUrlState] = useQueryString()
  const [filter, setFilter] = useFilter(setUrlState, 'hotWaterEntries')
  const [plotState, plotActions] = usePlot("hotWaterEntries")
  const plot = plotState.plot
  const offerTypes = filter.offerTypes || []
  const [dygraphOptions, setDygraphOptions] = useState({})
  
  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0){
      setFilter({
        from: formatPickerDateTime(moment().startOf('month')),
        offerTypes: ['fir_offer']
      })
    }
  }, [])

  const [loading] = useDependency( async () => {
    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      await plotActions.plot({ timeSeries })
    }
  }, [JSON.stringify(filter)])

  const currentTimeSeries = () => {
    return offerTypes.map(offerType => ({
      series: offerType,
      label: OFFER_TYPES.find(ot => ot.id === offerType)?.name,
      filter: {
        tradingDatetimeFrom: filter.from,
        tradingDatetimeTo: filter.to,
      },
      group: filter.group
    }))
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}> 
        <div css={styles.leftFilter}>
          <span css={styles.multiSelectLabel}> Offer </span>
          <MultiSelect
            css={styles.filter}
            options={OFFER_TYPES as any}
            name="offerTypes"
          />
        </div>
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.filterRow}>
        <div css={styles.groupOptions}>
          <GroupOptions scope="individual" name='group'/>
          <ExportButton
            css={styles.exportButton}
            variant='text'
            resource="hot_water_entries/plot"
            params={plotState.params}
            disabled={!plot?.values?.length}
            qsOptions={{ arrayFormat: 'brackets'}}
          />
          <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
        </div>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}

      <TimeSeriesPlot 
        plot={plot}
        unit="MW"
        ylabel="Demand (MW)"
        maxGranularity={Dygraph.Granularity.THIRTY_MINUTELY}
        dygraphOptions={dygraphOptions}
        loading={loading}
      />
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
  },
  groupOptions: {
    marginTop: 30,
    width: '100%',
    textAlign: 'right',
  },
  multiSelectLabel: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  }
} as StylesObject

export default HotWater