/** @jsxImportSource @emotion/react */
import React  from 'react'
import { Pagination, ErrorBanner, PageContainer } from 'components'
import {
  Typography, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material'
import {apiErrorStringsFor, formatLocaleDateTime} from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { BulkOfferBatch } from "types"

const List = () => {
  const [bulkOfferBatches, actions] = useResource<BulkOfferBatch>("bulkOfferBatches")

  const history = useHistory()
  const [page, setPage] = usePagination("bulkOfferBatches")

  useDependency(() => (
    actions.index({
      page: page,
      fields: {bulkOfferBatches: 'createdAt'},
      include: 'bulkReserveSource',
      sort: '-id'
    })
  ), [page])

  const showBulkOfferBatch = ({id}) => () => {
    history.push(`/bulk_offer_batches/${id}`)
  }

  const renderBulkOfferBatchListItem = (bulkOfferBatch) => {
    const { id, createdAt, bulkReserveSource } = bulkOfferBatch
    return (
      <TableRow key={id} onClick={showBulkOfferBatch(bulkOfferBatch)} css={{ cursor: 'pointer' }}>
        <TableCell>{id}</TableCell>
        <TableCell>{formatLocaleDateTime(createdAt)}</TableCell>
        <TableCell>{bulkReserveSource.name}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Bulk Offer Batches</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(bulkOfferBatches, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={bulkOfferBatches.totalPages} page={page} onPageSelected={setPage}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Bulk Reserve Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bulkOfferBatches.list.map(renderBulkOfferBatchListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={bulkOfferBatches.totalPages} page={page} onPageSelected={setPage}/>
    </PageContainer>
  )
}

export default List