/** @jsxImportSource @emotion/react */
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AvailabilityUploadIcon  from '@mui/icons-material/CalendarMonth';
import { apiErrorStringsFor, humanize } from 'utils'
import { usePagination, useDependency, useResource } from 'hooks'
import { useHistory } from 'react-router-dom'
import { AvailabilityUpload } from "types"

const List = () => {
  const [availabilityUploads, actions] = useResource<AvailabilityUpload>("availabilityUploads")

  const history = useHistory()

  const [page, setPage] = usePagination("availabilityUploads")

  useDependency(() => (
    actions.index({
      page: page,
      fields: {availabilityUploads: 'filename,status'},
      sort: '-id',
    })
  ), [page])

  const showAvailabilityUpload = ({id}) => () => {
    history.push(`/availability_uploads/${id}`)
  }

  const renderAvailabilityUploadListItem = (availabilityUpload) => {
    const { id, filename, status } = availabilityUpload
    return (
      <ListItem button onClick={showAvailabilityUpload(availabilityUpload)} key={id}>
        <ListItemIcon>
          <AvailabilityUploadIcon />
        </ListItemIcon>
        <ListItemText primary={filename} secondary={<span css={{ color: statusColors[status] }}>{humanize(status)}</span>} />
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <Typography variant='h4' style={{ marginBottom: 20 }}>Availability Uploads</Typography>
      <ErrorBanner>
        {apiErrorStringsFor(availabilityUploads, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={availabilityUploads.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {availabilityUploads.list.map(renderAvailabilityUploadListItem)}
      </MuiList>
      <Pagination totalPages={availabilityUploads.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='secondary' onClick={() => history.push('/availability_uploads/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const statusColors = {
  pending: 'orange',
  error: 'red',
  completed: 'green',
}

export default List