/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDependency, useCommonRouteParams, useResource } from 'hooks'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { PageContainer, PageHeader } from 'components'
import { Price } from "types"
import { formatLocaleDate, formatLocaleDateTime } from "utils"

const Show = () => {
  const { id } = useCommonRouteParams()
  const [prices, actions] = useResource<Price>("prices")

  useDependency(async () => {
    if (id) {
      actions.show(id)
    }
  }, [id])

  const price: Partial<Price> = prices.selected ?? {}

  return (
    <PageContainer>
      <PageHeader title={`Price - ${price.schedule} ${formatLocaleDate(price.tradingDatetime)} TP ${price.tradingPeriod}`} />
      <Table>
        <TableBody>
            <TableRow>
              <TableCell css={{width: 180}} variant='head'>Schedule</TableCell>
              <TableCell>{String(price.schedule || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Run type</TableCell>
              <TableCell>{String(price.runType || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading datetime</TableCell>
              <TableCell>{String(formatLocaleDateTime(price.tradingDatetime) || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading date</TableCell>
              <TableCell>{String(formatLocaleDate(price.tradingDate) || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Trading period</TableCell>
              <TableCell>{String(price.tradingPeriod || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Market Type</TableCell>
              <TableCell>{String(price.marketType || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Island</TableCell>
              <TableCell>{String(price.island || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Node</TableCell>
              <TableCell>{String(price.node || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>FIR price</TableCell>
              <TableCell>{String(price.firPrice || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Sir price</TableCell>
              <TableCell>{String(price.sirPrice || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Last run time</TableCell>
              <TableCell>{String(formatLocaleDateTime(price.lastRunTime) || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

export default Show