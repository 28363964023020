/** @jsxImportSource @emotion/react */
import React from 'react'
import { Pagination, ErrorBanner, PageContainer, ExportButton, PageHeader, SearchCombo, AutocompleteSearch, DateTimePicker } from 'components'
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from '@mui/material'
import { apiErrorStringsFor } from 'utils'
import { usePagination, useDependency, useResource, useFilter } from 'hooks'
import { MeterTestResult } from "types"
import moment from 'moment'

const formatTimeMs = timestamp =>  moment(timestamp).format('DD/MM/YYYY HH:mm:ss.SSS')

const List = ({meterTestResultUploadId}) => {
  const [meterTestResults, actions] = useResource<MeterTestResult>("meterTestResults")

  const [page, setPage] = usePagination("meterTestResults")
  const filter =  {meterTestResultUploadId}
  const sort = '-resultAt,installedMeterId'

  useDependency(() => (
    actions.index({
      page: page,
      include: 'installedMeter',
      filter,
      sort,
    })
  ), [page])

  const renderMeterReadListItem = (meterRead) => {
    const { id, installedMeter, resultAt, frequency, power, relayStatus } = meterRead
    return (
      <TableRow key={id}>
        <TableCell>{installedMeter.meterSerial}</TableCell>
        <TableCell>{formatTimeMs(resultAt)}</TableCell>
        <TableCell>{frequency}</TableCell>
        <TableCell>{power}</TableCell>
        <TableCell>{relayStatus}</TableCell>
      </TableRow>
    )
  }

  return (
    <PageContainer>
      <PageHeader title={`Meter Test Results`} actions={
        <ExportButton resource="meter_test_results" filter={filter} sort={sort} disabled={Object.keys(filter).length === 0} />
      } />
      <ErrorBanner>
        {apiErrorStringsFor(meterTestResults, 'index', 'destroy')}
      </ErrorBanner>
      <Pagination totalPages={meterTestResults.totalPages} page={page} onPageSelected={setPage}/>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Meter Serial</TableCell>
            <TableCell>Result At</TableCell>
            <TableCell>Frequency (Hz)</TableCell>
            <TableCell>Power (MW)</TableCell>
            <TableCell>Relay Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meterTestResults.list.map(renderMeterReadListItem)}
        </TableBody>
      </Table>
      <Pagination totalPages={meterTestResults.totalPages} page={page} onPageSelected={setPage} />
    </PageContainer>
  )
}

export default List